import React, { useState, useEffect, useRef } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import {
  doc,
  getDoc,
  getDocs,
  addDoc,
  collection,
  query,
  orderBy,
  serverTimestamp,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { db, auth } from "./Firebase.js";
import { onAuthStateChanged } from "firebase/auth";
import Paymentform from "./Paymentform";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import "./PledgeSubscribe.css";

function PledgeSubscribe() {
  const { userid } = useParams();
  const location = useLocation();
  const Public_Key =
    "pk_test_51NMGCcBxT13QwRtaWZfi8ym0fzXzUxcQtWOYqcl0suDSVqm4LnvKiK6aw1eARISZ7zvUcokI2569kCB5BhDtG3Ew00UCu5WSMR";
  const stripePromise = loadStripe(Public_Key);
  const navigate = useNavigate();
  const finished = (answer) => {
    console.log(answer);
    setSuccess(answer);
  };

  const [paymethod, setPaymethod] = useState("");
  const [success, setSuccess] = useState(false);
  const [subscribeddata, setSubscribeddata] = useState("");
  const [qualify, setQualify] = useState(false);
  const [investigating, setInvestigating] = useState(false);
  const [waitingkonbini, setWaitingkonbini] = useState(false);
  const [konbiniinfo, setKonbiniinfo] = useState("");
  const [finalprice, setFinalprice] = useState("");

  useEffect(() => {
    console.log(userid);
    console.log(location.state[0]);
    console.log(location.state[1]);

    const userCollectionRef1 = collection(db, "users", userid, "PledgeHold");

    getDocs(userCollectionRef1).then((querySnapshot) => {
      let posts = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      let a = posts.filter(
        (post) =>
          post.PaidUser === location.state[1].id &&
          post.Pledgeid === location.state[0].id
      );

      if (a.length === 1) {
        console.log(a.length);
        console.log(...a);
        console.log(a[0].Price);
        setSubscribeddata(...a);
        if (a[0].investigation) {
          setInvestigating(true);
        }
      } else {
        const userCollectionRef2 = collection(
          db,
          "users",
          location.state[1].id,
          "OutstandingPay"
        );

        getDocs(userCollectionRef2).then((querySnapshot) => {
          let posts1 = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));

          console.log(posts1);
          if (posts1.length > 0) {
            console.log(posts1);
            let b = posts1.filter(
              (post) => post.Pledgeid === location.state[0].id
            );
            console.log(b);
            if (b.length === 1) {
              setWaitingkonbini(true);
              setKonbiniinfo(...b);
              console.log(b[0].Konbini_due);
              console.log(new Date(b[0].Konbini_due * 1000));
            }
          }
        });
      }
    });
  }, []);

  const refundquestions = () => {
    console.log(new Date().getTime());
    console.log(subscribeddata);
    console.log(subscribeddata.ExpireNumber);
    console.log(subscribeddata.FullExpireDate.seconds * 1000);

    if (new Date().getTime() > subscribeddata.ExpireNumber) {
      setQualify(true);
    } else {
      alert(
        "返金のリクエストが出来る期間は" +
          new Date(subscribeddata.ExpireNumber) +
          "から" +
          new Date(subscribeddata.ExpireNumber + 86400000 * 3) +
          "の間です"
      );
    }
  };

  const refundinvestigate = (e) => {
    updateDoc(doc(db, `users/${userid}/PledgeHold/${subscribeddata.id}`), {
      investigation: e.target.id,
    });

    setDoc(
      doc(
        db,
        "users",
        "6AYdwy6Yo5QqOZ0OraOAcSbRohH2",
        "investigation",
        subscribeddata.id
      ),
      {
        investigation: e.target.id,
        requester: location.state[1].id,
        investigated: userid,
        type: "PledgePlan/PledgeHold",
        PledgeHoldid: subscribeddata.id,
      }
    );
    setInvestigating(true);

    setTimeout(() => {
      navigate(`/Pledge/${userid}`, {
        state: location.state[1].id,
      });
    }, 2000);
  };

  const taxes = (e) => {
    console.log(e.target.id);
    setPaymethod(e.target.id);
    let price = parseInt(location.state[0].price);
    if (e.target.id === "card" || e.target.id === "konbini" || "PayPay") {
      setFinalprice(Math.round(price * 0.136 + price));
    } else if (e.target.id === "jp_bank-transfer") {
      setFinalprice(Math.round(price * 0.136 + price));
    }
  };

  return (
    <div style={{ background: "linear-gradient(#d946e4, #83d7ec)" }}>
      <img
        style={{ zIndex: 2, marginTop: 5, marginLeft: 4 }}
        src="/Arrow1.svg"
        onClick={(e) => navigate(`/Pledge/${userid}`)}
      />

      <div>
        {!subscribeddata ? (
          <></>
        ) : (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img src="/Subscribed.svg" style={{ width: 20 }} />
              加入済み
            </div>
          </>
        )}
        <div className="subscribetitle">{location.state[0].title}</div>
        <div className="subscribeprice">
          <span style={{ borderBottom: "1px solid black", paddingBottom: 2 }}>
            <span style={{ color: "CornflowerBlue" }}>金額 | </span>
            <span style={{ color: "purple", fontStyle: "italic" }}>
              {location.state[0].price}円
            </span>
          </span>
        </div>
        <div style={{ textAlign: "center" }}>
          <span style={{ color: "BlueViolet" }}> 期間 | </span>
          <span style={{ color: "blue" }}>{location.state[0].duration}</span>
        </div>

        <div
          style={{
            marginTop: 5,
            marginBottom: 10,
            fontSize: 12,
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div style={{ width: "80%", textAlign: "center" }}>
            {location.state[0].details}
          </div>
        </div>
      </div>
      {!subscribeddata ? (
        <>
          {!waitingkonbini ? (
            <>
              {!success ? (
                <>
                  <div
                    style={{
                      border: "1px solid black",
                      borderRadius: 20,
                      marginTop: 10,
                    }}
                  >
                    <div style={{ textAlign: "center" }}>お支払い方法：</div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        flexWrap: "wrap",
                        height: 80,
                        width: "100%",
                        flex: "0 0 33%",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          background:
                            paymethod === "card" &&
                            "linear-gradient(#c4d1d2, #42b1e0)",
                          borderRadius: 30,
                          width: paymethod === "card" && 115,
                        }}
                      >
                        <div
                          id={"card"}
                          onClick={taxes}
                          style={{ fontSize: 5 }}
                        >
                          カード決済
                        </div>
                        <img
                          id={"card"}
                          onClick={taxes}
                          style={{ width: 90, marginBottom: 5 }}
                          src="/credit_card.svg"
                        />
                      </div>
                      |
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",

                          background:
                            paymethod === "konbini" &&
                            "linear-gradient(#c4d1d2, #42b1e0)",
                          borderRadius: paymethod === "konbini" && 30,
                          width: paymethod === "konbini" && 95,
                        }}
                      >
                        <div
                          id={"konbini"}
                          onClick={taxes}
                          style={{ fontSize: 5 }}
                        >
                          コンビニ決済
                        </div>
                        <img
                          id={"konbini"}
                          onClick={taxes}
                          style={{ width: 70, marginBottom: 5 }}
                          src="/konbini.svg"
                        />
                      </div>
                      |
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          background:
                            paymethod === "jp_bank-transfer" &&
                            "linear-gradient(#c4d1d2, #42b1e0)",
                          borderRadius: paymethod === "jp_bank-transfer" && 30,
                          width: paymethod === "jp_bank-transfer" && 95,
                        }}
                      >
                        <div
                          id={"jp_bank-transfer"}
                          onClick={taxes}
                          style={{ fontSize: 5 }}
                        >
                          口座振り込み
                        </div>
                        <img
                          id={"jp_bank-transfer"}
                          onClick={taxes}
                          style={{ width: 70, marginBottom: 5 }}
                          src="/bank.svg"
                        />
                      </div>
                      |
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          background:
                            paymethod === "PayPay" &&
                            "linear-gradient(#c4d1d2, #42b1e0)",
                          borderRadius: paymethod === "PayPay" && 30,
                          width: paymethod === "PayPay" && 80,
                        }}
                      >
                        <div
                          id={"PayPay"}
                          onClick={taxes}
                          style={{
                            fontSize: 5,
                            marginBottom: 2,
                          }}
                        >
                          PayPay
                        </div>
                        <img
                          id={"PayPay"}
                          onClick={taxes}
                          style={{ width: 55, marginBottom: 5 }}
                          src="/PayPay.svg"
                        />
                      </div>
                    </div>
                  </div>

                  {paymethod && (
                    <>
                      <div
                        style={{
                          textAlign: "center",
                          marginTop: 5,
                          marginBottom: -4,
                          fontSize: 9,
                        }}
                      >
                        手数料 :￥
                        <span>
                          {paymethod === "card" ||
                          paymethod === "konbini" ||
                          paymethod === "PayPay"
                            ? Math.round(
                                parseInt(location.state[0].price) * 0.036
                              )
                            : paymethod === "jp_bank-transfer"
                            ? Math.round(
                                parseInt(location.state[0].price) * 0.015
                              )
                            : null}
                        </span>
                      </div>
                      <div style={{ textAlign: "center", fontSize: 10 }}>+</div>
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: 9,
                          marginBottom: -4,
                        }}
                      >
                        消費税 :￥
                        <span>
                          {Math.round(parseInt(location.state[0].price) * 0.1)}
                        </span>
                      </div>
                      <div style={{ textAlign: "center", fontSize: 10 }}>+</div>
                      <div
                        style={{
                          textAlign: "center",
                          marginBottom: 4,
                          fontSize: 9,
                        }}
                      >
                        プラン費 : ￥{location.state[0].price}
                      </div>
                      <div
                        style={{
                          textAlign: "center",
                          marginBottom: 4,
                        }}
                      >
                        <div style={{ color: "rebeccapurple" }}>
                          ご請求額 :￥{finalprice}
                        </div>
                      </div>
                      <Elements stripe={stripePromise}>
                        <Paymentform
                          Paymethod={paymethod}
                          FinalPrice={finalprice}
                          Price={location.state[0].price}
                          Finished={finished}
                          Userid={userid}
                          Pledgeid={location.state[0].id}
                          ExpireNumber={location.state[0].duenumber}
                          Symbol={location.state[0].duesymbol}
                          Userinfo={location.state[1]}
                        />
                      </Elements>
                    </>
                  )}
                  <Link to={`/CreditLaws`} style={{ textDecoration: "none" }}>
                    <div
                      style={{
                        color: "aqua",

                        fontSize: 10,
                        marginTop: paymethod ? 20 : 5,
                        textAlign: "center",
                      }}
                    >
                      .特定商取引法に基づく表記.
                    </div>
                  </Link>
                </>
              ) : (
                (paymethod === "card" && <div>お支払い完了</div>) ||
                (paymethod === "konbini" && <div>コンビニ支払い受付完了</div>)
              )}
            </>
          ) : (
            <>
              <div>コンビニ支払いのご案内 </div>
              <div>
                有効期限:
                {new Date(konbiniinfo.Konbini_due * 1000).getFullYear()}年/
                {new Date(konbiniinfo.Konbini_due * 1000).getMonth() + 1}
                月/
                {new Date(konbiniinfo.Konbini_due * 1000).getDate()}日 金額:
                {konbiniinfo.Price}円 第1番号:{konbiniinfo.Payment_code}
                第2番号:{konbiniinfo.Phone}
              </div>
            </>
          )}
        </>
      ) : (
        <>
          {!investigating ? (
            <>
              {!qualify ? (
                <div>
                  <button onClick={refundquestions}>返金のリクエスト？</button>
                </div>
              ) : (
                <>
                  <div>
                    <button
                      id={"受け取った商品が約束されたものと違う"}
                      onClick={refundinvestigate}
                    >
                      受け取った商品が約束されたものと違う
                    </button>
                    <button
                      id={"商品をまだ受け取っていない"}
                      onClick={refundinvestigate}
                    >
                      商品をまだ受け取っていない
                    </button>
                  </div>
                </>
              )}
            </>
          ) : (
            <div>返金リクエストを調査中です...</div>
          )}
        </>
      )}
    </div>
  );
}

export default PledgeSubscribe;
