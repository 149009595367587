import React, { useState, useEffect, useRef } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "./Firebase.js";
import {
  doc,
  updateDoc,
  getDoc,
  getDocs,
  collection,
  serverTimestamp,
  addDoc,
  where,
  query,
  collectionGroup,
  increment,
  orderBy,
  deleteDoc,
  writeBatch,
  deleteField,
  limit,
  Timestamp,
} from "firebase/firestore";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./Connect.css";
import LowControl from "./LowControl.js";
import FullScreenPromotion from "./FullScreenPromotion.js";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  getStorage,
  deleteObject,
} from "firebase/storage";
import useClickCounter from "./useClickCounter.js";
import Progressbar from "./Customhooks/Progressbar.js";
import Block from "./Customhooks/Block.js";

function Connect({ ImageUrl }) {
  const [id, setId] = useState("");
  const [dropped, setDropped] = useState(false);
  const [droptext, setDroptext] = useState("");
  const [posts, setPosts] = useState([]);
  const [showdrop, setShowdrop] = useState(true);
  const [send, setSend] = useState("");
  const [optionid, setOptionId] = useState("");
  const [redropid, setRedropid] = useState([]);
  const [unredropid, setUnredropid] = useState([]);
  const [deleteid, setDeleteid] = useState("");
  const [back, setBack] = useState(false);
  const [clickedcomment, setClickedcomment] = useState("");
  const [alldisplay, setAlldisplay] = useState([]);
  const [redroppoint, setRedroppoint] = useState(false);
  const [unredroppoint, setUnredroppoint] = useState(false);
  const [userinfo, setUserinfo] = useState("");
  const [simdisplays, setSimdisplays] = useState([]);
  const [tempfiles, setTempfiles] = useState([]);
  const [fullscreened, setFullscreened] = useState("");
  const [paused, setPaused] = useState("");
  const [controlon, setControlon] = useState(false);
  const [showtime, setShowtime] = useState(false);
  const [videolocation, setVideolocation] = useState(0);
  const [videotime, setVideotime] = useState(0);
  const [progresspercent, setProgresspercent] = useState(0);
  const [twominutevideo, setTwominutevideo] = useState(0);
  const [videorefload, setVideorefload] = useState(false);
  const [blockid, setBlockid] = useState("");
  const [commentbackid, setCommentbackid] = useState("");
  const [disablepress, setDisablepress] = useState(false);
  const [promotion, setPromotion] = useState(true);

  const navigate = useNavigate();
  const scrollref = useRef(null);
  const ref3 = useRef([]);
  //const dropref = useRef(true);
  const droppedvideoref = useRef([]);
  const currenturl = "Connect";
  const Clicker = useClickCounter();
  const location = useLocation();

  const Optionreset = () => {
    setOptionId("");
    setDeleteid("");
    setRedroppoint(false);
    setUnredroppoint(false);
    setBlockid("");
  };

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        console.log(location.state);
        setId(user.uid);
        const dropsRef = query(
          collectionGroup(db, "Drops"),
          where("Rank", "!=", false)
        );

        const querySnapshot = getDocs(
          query(dropsRef, orderBy("Rank"), orderBy("Timestamp", "desc"))
        ).then((querySnapshot) => {
          let tempposts = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            /* year: doc.data().Timestamp.toDate().getFullYear(),
            month: ("0" + (doc.data().Timestamp.toDate().getMonth() + 1)).slice(
              -2
            ),
            date: ("0" + doc.data().Timestamp.toDate().getDate()).slice(-2),
            hour: doc.data().Timestamp.toDate().getHours(),
            minutes: ("0" + doc.data().Timestamp.toDate().getMinutes()).slice(
              -2
            ),*/

            id: doc.id,
          }));

          let Redroppedposts = tempposts.filter(
            (post) => post.Redropper_id === user.uid
          );

          console.log(tempposts);
          Redroppedposts.forEach((redroppedpost) => {
            tempposts.forEach((post) => {
              if (redroppedpost.Redrop_id === post.id) {
                post.Redropped = "done";
              }
            });
          });

          let finaltempposts = tempposts.filter(
            (post) =>
              !post.Redropper_id ||
              (!post.Sender_id && post.Redropper_id === post.Directory)
          );

          let uniqueranks = [];
          finaltempposts.forEach((post) => {
            uniqueranks.push(post.Rank);
            if (!post.Redropper_id) {
              post.year = post.Timestamp.toDate().getFullYear();
              post.month = (
                "0" +
                (post.Timestamp.toDate().getMonth() + 1)
              ).slice(-2);
              post.date = ("0" + post.Timestamp.toDate().getDate()).slice(-2);
              post.hour = post.Timestamp.toDate().getHours();
              post.minutes = ("0" + post.Timestamp.toDate().getMinutes()).slice(
                -2
              );
            }
          });
          console.log([...new Set(uniqueranks)]);
          let finaluniqueranks = [...new Set(uniqueranks)];
          let rankindex = [];
          finaluniqueranks.forEach((finaluniquerank) => {
            rankindex.push(
              finaltempposts.findIndex(
                (element) => finaluniquerank === element.Rank
              )
            );
          });

          console.log(rankindex);
          let finalposts = [];
          let num = 1;
          rankindex.forEach((index) => {
            if (rankindex[num] - index >= 2) {
              finalposts.push(finaltempposts[index], finaltempposts[index + 1]);
            } else if (rankindex[num] - index < 2) {
              finalposts.push(finaltempposts[index]);
            } else {
              console.log(rankindex[num] - index);
              if (finaltempposts.length - index >= 2) {
                finalposts.push(
                  finaltempposts[index],
                  finaltempposts[index + 1]
                );
              } else {
                finalposts.push(finaltempposts[index]);
              }
            }

            num++;
          });
          console.log(finalposts);

          setTimeout(() => {
            setPosts(finalposts);
            droppedvideoref.current = new Array(finalposts.length).fill(null);
          });
        });

        const userDocumentRef = doc(db, "users", user.uid);
        await getDoc(userDocumentRef).then((documentSnapshot) => {
          setUserinfo(documentSnapshot.data());
        });

        setTimeout(() => {
          setPromotion(false);
          setTimeout(() => {
            setVideorefload(!videorefload);
          });
        }, 1500);
      }
    });
  }, []);

  useEffect(() => {
    if (back === true) {
      console.log(simdisplays);
      console.log(simdisplays.at(-1));
      const element = scrollref.current;
      console.log(element);
      element.scrollIntoView();
      if (clickedcomment) setClickedcomment("");
      else if (commentbackid) setCommentbackid("");
      else if (alldisplay.length === 1) setAlldisplay([]);
      else setAlldisplay(alldisplay.slice(0, -1));
      setBack(false);
    }
  }, [back]);

  useEffect(() => {
    if (showtime) {
      console.log("HEYY");
      /*only if its playing set it to videolocation 4 */
      if (dropped) {
        if (
          document.fullscreenElement?.children[0].children[0].children[3]
            ?.paused === undefined
        ) {
          setVideolocation(4);
        }
      }
      setShowtime(false); /*
      setTimeout(() => {
        setShowtime(false);
      }, 200);*/
    }
  }, [showtime]);

  useEffect(() => {
    console.log(droppedvideoref.current);
    let filteredref = droppedvideoref.current.filter(
      (element) => element !== null
    );
    console.log(filteredref);
    if (filteredref.length > 0) {
      let num = -1;
      let options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.95,
      };
      const observer = new IntersectionObserver((entries) => {
        console.log(entries);
        console.log(entries[0]);

        entries.forEach((entry) => {
          console.log(entry);

          if (entry.isIntersecting) {
            console.log(entry.target);

            entry.target.play();
            num++;
          } else {
            if (num > -1) {
              //might need to pause other 2 3 4 video
              console.log(num);
              //what if the video is already paused
              try {
                if (!entry.target.paused) {
                  entry.target.pause();
                } else if (!filteredref[num][1]?.paused) {
                  filteredref[num][1].pause();
                } else if (!filteredref[num][2]?.paused) {
                  filteredref[num][2].pause();
                } else {
                  filteredref[num][3].pause();
                }
              } catch (e) {
                console.log(e);
                //"Its already paused","move on to the rest of function"
              }

              num--;
            }
          }
        });
      }, options);

      console.log(observer);

      filteredref.forEach((element) => {
        let observertarget = Array.isArray(element) ? element[0] : element;

        observer.observe(observertarget);
      });

      return () => {
        observer.disconnect();
      };
    }
  }, [videorefload]);

  const displayback = async () => {
    if (alldisplay.length === 1) {
      setSimdisplays([]);
      setShowdrop(true);
      setTimeout(() => {
        droppedvideoref.current = new Array(posts.length).fill(null);
        setBack(true);
        setVideorefload(!videorefload);
      });
    } else {
      let Directory =
        alldisplay.length === 2
          ? alldisplay.at(-2).Directory
          : alldisplay.at(-2).Sender_id;
      let blocked;
      if (Directory !== id) {
        console.log(Directory);
        const userBlockRef = doc(db, "users", Directory, "Blocked", id);
        blocked = await getDoc(userBlockRef).then((documentSnapshot) => {
          return documentSnapshot;
        });
      }

      console.log(blocked);
      console.log(blocked?.exists());
      console.log(blocked?.data());

      const blockedaction = () => {
        alldisplay.pop();
        simdisplays.pop();
        displayback();
      };
      if (blocked?.exists()) return blockedaction();

      console.log("THIS BETTTER NOT RUN");

      setSimdisplays(simdisplays.slice(0, -1));
      setTimeout(() => {
        droppedvideoref.current = new Array(simdisplays.at(-2).length + 1).fill(
          null
        );
        console.log(droppedvideoref.current);
        setBack(true);
        setVideorefload(!videorefload);
      });
    }
    console.log("Does this run");
    Optionreset();
  };

  const commentcancel = () => {
    console.log(droppedvideoref.current);
    setDeleteid("");
    setOptionId("");
    setSend("");
    setTempfiles([]);
    setTwominutevideo(0);
    console.log(twominutevideo);
    let reffilllength;
    if (clickedcomment.id !== alldisplay[alldisplay.length - 1]?.id) {
      if (alldisplay.length > 0) {
        setCommentbackid(clickedcomment.id);
        //setClickedcomment("");
        // setBack(true);
        reffilllength = simdisplays.at(-1).length + 1;
      } else {
        setShowdrop(true);
        //setBack(true);
        reffilllength = posts.length;
      }
      setBack(true);
    } else {
      //setClickedcomment("");
      reffilllength = simdisplays.at(-1).length + 1;
    }
    setClickedcomment("");
    setTimeout(() => {
      droppedvideoref.current = new Array(reffilllength).fill(null);
      console.log(droppedvideoref.current);
      setVideorefload(!videorefload);
    });
    /* setTimeout(() => {
      setVideorefload(!videorefload);
    }, 500);*/
  };

  const turn = () => {
    setDisablepress(false);
    setDropped(!dropped);
    if (tempfiles.length > 0) setTempfiles([]);
    if (dropped)
      setTimeout(() => {
        setVideorefload(!videorefload);
      }, 500);
  };

  const fileload = async () => {
    let file = [
      { path: "", filetype: "", id: "" },
      { path: "", filetype: "", id: "" },
      { path: "", filetype: "", id: "" },
      { path: "", filetype: "", id: "" },
    ];
    let promises = [];
    if (tempfiles[0]) {
      const storage = getStorage();
      let num0 = 0;
      let num = 0;

      for (const tempfile of tempfiles) {
        file[num0].filetype = tempfile.realfile.type.includes("video")
          ? "videos"
          : "images";
        console.log(tempfile);
        file[num0].id = tempfile.id;

        const storageRef = ref(
          storage,
          `${file[num0].filetype}/${tempfile.id}`
        );

        num0++;
        promises.push(
          await new Promise((resolve, reject) => {
            uploadBytes(storageRef, tempfile.realfile).then((uploadtask) => {
              console.log(uploadtask);
              resolve(
                getDownloadURL(storageRef).then((url) => {
                  console.log(url);
                  let target = 1;
                  console.log(target);
                  //findindex of tempfiles and match it with file[target?]
                  file[num].path = url;
                  if (num + 1 === tempfiles.length) {
                    setProgresspercent(100);
                  } else {
                    setProgresspercent(
                      Math.round(100 / tempfiles.length) * (num + 1)
                    );
                  }
                  num++;
                })
              );
            });
          })
        );

        URL.revokeObjectURL(tempfile.file);
      }
      setTwominutevideo(0);
    }

    await promises[0];
    await promises[1];
    await promises[2];
    await promises[3];

    console.log(file);
    return file;
  };

  const droppy = async () => {
    //console.log(dropref.current)
    // if (!dropref.current) return
    // dropref.current = false;
    setDisablepress(true);

    let file = await fileload();

    let dropobject = {
      text: droptext,
      Timestamp: serverTimestamp(),
      file1: file[0],
      file2: file[1],
      file3: file[2],
      file4: file[3],
      Rank: userinfo.Rank,
      Profile_image: userinfo.Profile_image,
      Directory: id,
      Comment_Counter: 0,
      Redropped_Counter: 0,
      Pure: true,
    };

    try {
      let Time = new Date();
      await addDoc(collection(db, `users/${id}/Drops`), dropobject).then(
        (docRef) => {
          dropobject = {
            ...dropobject,
            id: docRef.id,
            year: Time.getFullYear(),
            month: ("0" + (Time.getMonth() + 1)).slice(-2),
            date: ("0" + Time.getDate()).slice(-2),
            hour: Time.getHours(),
            minutes: ("0" + Time.getMinutes()).slice(-2),
          };
        }
      );
    } catch (error) {
      console.log(error);
    }
    let matchtarget = posts.findIndex((element) => id === element.Directory);
    console.log(matchtarget);
    let ranktarget = -1;
    if (matchtarget === 0) {
      if (posts[matchtarget + 1]?.Directory === id) {
        setPosts([
          dropobject,
          posts[matchtarget],
          ...posts.slice(matchtarget + 2),
        ]);
      } else {
        setPosts([dropobject, ...posts]);
      }
    } else if (matchtarget > 0) {
      if (posts[matchtarget + 1]?.Directory === id) {
        setPosts([
          ...posts.slice(0, matchtarget),
          dropobject,
          posts[matchtarget],
          ...posts.slice(matchtarget + 2),
        ]);
      } else {
        setPosts([
          ...posts.slice(0, matchtarget),
          dropobject,
          ...posts.slice(matchtarget),
        ]);
      }
    } else {
      ranktarget = posts.findIndex((element) => userinfo.Rank < element.Rank);
      console.log(userinfo.Rank);
      if (ranktarget === 0) {
        setPosts([dropobject, ...posts]);
      } else if (ranktarget > 0) {
        setPosts([
          ...posts.slice(0, ranktarget),
          dropobject,
          ...posts.slice(ranktarget),
        ]);
      } else {
        setPosts([...posts, dropobject]);
      }
      //this is the persons first tweet so now you have to find the index of the rank of the person that is above the person in the posts or if youre in last place
      //run maybe three functions, if you are in first place, middel place, last place then what
    }

    setDropped(false);
    setDroptext("");
    setTempfiles([]);

    setTimeout(() => {
      //dropref.current = true;
      setProgresspercent(0);

      if (matchtarget >= 0) {
        droppedvideoref.current[matchtarget] = null;
        droppedvideoref.current[matchtarget + 1] = null;
      } else if (ranktarget >= 0) {
        droppedvideoref.current[ranktarget] = null;
        droppedvideoref.current[ranktarget + 1] = null;
      }

      setTimeout(() => {
        setVideorefload(!videorefload);
      }, 500);
    });
  };

  const understandblocked = () => {
    setBlockid("");
  };

  const display = async (post) => {
    console.log(post);
    let Directory = alldisplay.length === 0 ? post.Directory : post.Sender_id;
    console.log(Directory);
    const userBlockRef = doc(db, "users", Directory, "Blocked", id);

    let blocked = await getDoc(userBlockRef).then((documentSnapshot) => {
      console.log(documentSnapshot.exists());
      console.log(documentSnapshot.data());
      return documentSnapshot;
    });

    console.log(blocked);
    console.log(blocked.exists());
    console.log(blocked.data());
    if (blocked.exists()) return setBlockid(post.id);

    setAlldisplay([...alldisplay, post]);

    setShowdrop(false);

    const dropsRef = query(
      collectionGroup(db, "Comments"),
      where("Directory", "==", post.id)
    );

    getDocs(
      query(dropsRef, orderBy("Rank"), orderBy("Timestamp", "desc"))
    ).then((querySnapshot) => {
      let simposts = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      console.log(simposts);
      const postsRef = collection(db, "users", id, "Drops");
      getDocs(postsRef).then((querySnapshot) => {
        let posts = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        let Redroppedcomments = posts.filter(
          (post) => post.Sender_id && post.Redropper_id === id
        );

        console.log(Redroppedcomments);

        simposts.map((simpost) => {
          if (!simpost.Redropper_id) {
            simpost.year = simpost.Timestamp.toDate().getFullYear();
            simpost.month = (
              "0" +
              (simpost.Timestamp.toDate().getMonth() + 1)
            ).slice(-2);
            simpost.date = ("0" + simpost.Timestamp.toDate().getDate()).slice(
              -2
            );
            simpost.hour = simpost.Timestamp.toDate().getHours();
            simpost.minutes = (
              "0" + simpost.Timestamp.toDate().getMinutes()
            ).slice(-2);
          }
          Redroppedcomments.forEach((redroppedcomment) => {
            if (redroppedcomment.Redrop_id === simpost.id) {
              simpost.Redropped = "done";
            }
          });

          return simpost;
        });
        console.log(simposts);

        setSimdisplays([...simdisplays, simposts]);
        setTimeout(() => {
          droppedvideoref.current = new Array(simposts.length + 1).fill(null);
          console.log(droppedvideoref.current);
        });
      });
    });

    Optionreset();
    window.scroll({ top: 0 });
    setTimeout(() => {
      setVideorefload(!videorefload);
    }, 500);
  };

  const comment = async (event) => {
    setDisablepress(false);
    let tempid = event.target.id.split(",");
    console.log(tempid);
    console.log(tempid[0]);
    const userBlockRef = doc(db, "users", tempid[1], "Blocked", id);

    let blocked = await getDoc(userBlockRef).then((documentSnapshot) => {
      console.log(documentSnapshot.exists());
      console.log(documentSnapshot.data());
      return documentSnapshot;
    });
    if (blocked.exists()) return setBlockid(tempid[0]);
    if (alldisplay.length === 0)
      setClickedcomment(...posts.filter((post) => post.id === tempid[0]));
    else if (alldisplay.at(-1).id === tempid[0])
      setClickedcomment(alldisplay.at(-1));
    else
      setClickedcomment(
        ...simdisplays
          .at(-1)
          .filter((simdisplay) => simdisplay.id === tempid[0])
      );
    setShowdrop(false);
    Optionreset();
    setTimeout(() => {
      droppedvideoref.current = new Array(1).fill(null);
      console.log(droppedvideoref.current);
      setVideorefload(!videorefload);
    });
  };

  const SendComment = async () => {
    //we might need the This_directory put in the state?

    //dropref.current = false;
    setDisablepress(true);
    let DirectoryorSenderid =
      alldisplay.length === 0 || clickedcomment.id === alldisplay[0].id
        ? [clickedcomment.Directory, "Drops"]
        : [clickedcomment.Sender_id, "Comments"];

    let file = await fileload();
    let commentobject = {
      Directory: clickedcomment.id,
      Sender_id: id,
      Comment: send,
      Timestamp: serverTimestamp(),
      file1: file[0],
      file2: file[1],
      file3: file[2],
      file4: file[3],
      Rank: userinfo.Rank,
      Profile_image: userinfo.Profile_image,
      Comment_Counter: 0,
      Sent_id: DirectoryorSenderid[0],
      Redropped_Counter: 0,
      //This_Directory: "",
    };
    await addDoc(collection(db, `users/${id}/Comments/`), commentobject).then(
      (docRef) => {
        /* updateDoc(doc(db, `users/${id}/Comments/${docRef.id}`), {
          This_Directory: docRef.id,
        });*/
        //commentobject.This_Directory = docRef.id;

        //do we even need This_Directory ever?

        if (
          clickedcomment.Sender_id !== id &&
          clickedcomment.Directory !== id
        ) {
          let loadtype0 = clickedcomment.Sender_id ? "Comments" : "Drops";

          let notificationinfo = {
            Type: "Comment",
            Loadtype: loadtype0,
            Sender_name: userinfo.User_Name,
            Comment_Directory: docRef.id,
          };

          if (
            alldisplay.length === 0 ||
            clickedcomment.id === alldisplay[0].id
          ) {
            //Directory for the first notification block needs to be changed to
            //clickedcomment.id ?

            addDoc(
              collection(db, `users/${clickedcomment.Directory}/Notification/`),
              {
                ...commentobject,
                //prolly need Directory here its goin to be clickedcomment.id?
                ...notificationinfo,
              }
            );
          } else {
            addDoc(
              collection(db, `users/${clickedcomment.Sender_id}/Notification/`),
              {
                ...commentobject,
                ...notificationinfo,
                Directory: clickedcomment.id,
                rootid: alldisplay[0].id,
                root_user: alldisplay[0].Directory,
                notification_reciever: clickedcomment.Sender_id,
              }
            );
            if (
              clickedcomment.Sender_id !== id &&
              alldisplay[0].Directory !== clickedcomment.Sender_id
            ) {
              //need to make sure that the person recieves this knows that its to someone else
              //the comment is not to you
              //prolly notification reciver is === rootuser than its not to you
              //but you are the root user
              addDoc(
                collection(
                  db,
                  `users/${alldisplay[0].Directory}/Notification/`
                ),
                {
                  ...commentobject,
                  ...notificationinfo,
                  Directory: clickedcomment.id,
                  rootid: alldisplay[0].id,
                  root_user: alldisplay[0].Directory,
                  notification_reciever: alldisplay[0].Directory,
                }
              );
            }
          }
          updateDoc(doc(db, `users/${DirectoryorSenderid[0]}`), {
            Notification: increment(1),
          });
        }

        try {
          updateDoc(
            doc(
              db,
              `users/${DirectoryorSenderid[0]}/${DirectoryorSenderid[1]}/${clickedcomment.id}`
            ),
            {
              Comment_Counter: increment(1),
            }
          );
        } catch (error) {
          console.log(error);
        }
        //get rid of topsubtract state

        setSend("");
        setTempfiles([]);
        //put this in the if statement down below if(alldisplay.length === 0)setShowdrop(true)
        let CommentCounterUpdated = {
          ...clickedcomment,
          Comment_Counter: clickedcomment.Comment_Counter + 1,
        };
        console.log(CommentCounterUpdated);
        let Commentincrementtarget;
        const postincrement = () => {
          Commentincrementtarget = posts.findIndex(
            (post) => clickedcomment.id === post.id
          );
          posts[Commentincrementtarget] = {
            ...posts[Commentincrementtarget],
            Comment_Counter: posts[Commentincrementtarget].Comment_Counter + 1,
          };
        };
        const commentincrement = (index) => {
          Commentincrementtarget = simdisplays
            .at(index)
            .findIndex((simdisplay) => clickedcomment.id === simdisplay.id);
          simdisplays.at(index)[Commentincrementtarget] = {
            ...simdisplays.at(index)[Commentincrementtarget],
            Comment_Counter:
              simdisplays.at(index)[Commentincrementtarget].Comment_Counter + 1,
          };
        };
        let reffilllength;
        if (clickedcomment.id !== alldisplay.at(-1)?.id) {
          setAlldisplay([...alldisplay, CommentCounterUpdated]);

          const dropsRef = query(
            collectionGroup(db, "Comments"),
            where("Directory", "==", clickedcomment.id)
          );

          getDocs(
            query(dropsRef, orderBy("Rank"), orderBy("Timestamp", "desc"))
          ).then((querySnapshot) => {
            let simposts = querySnapshot.docs.map((doc) => ({
              ...doc.data(),
              id: doc.id,
            }));

            console.log(simposts);
            const postsRef = collection(db, "users", id, "Drops");
            getDocs(postsRef).then((querySnapshot) => {
              let posts = querySnapshot.docs.map((doc) => ({
                ...doc.data(),
                id: doc.id,
              }));

              let Redroppedcomments = posts.filter(
                (post) => post.Sender_id && post.Redropper_id === id
              );

              console.log(Redroppedcomments);

              simposts.map((simpost) => {
                if (!simpost.Redropper_id) {
                  simpost.year = simpost.Timestamp.toDate().getFullYear();
                  simpost.month = (
                    "0" +
                    (simpost.Timestamp.toDate().getMonth() + 1)
                  ).slice(-2);
                  simpost.date = (
                    "0" + simpost.Timestamp.toDate().getDate()
                  ).slice(-2);
                  simpost.hour = simpost.Timestamp.toDate().getHours();
                  simpost.minutes = (
                    "0" + simpost.Timestamp.toDate().getMinutes()
                  ).slice(-2);
                }
                Redroppedcomments.forEach((redroppedcomment) => {
                  if (redroppedcomment.Redrop_id === simpost.id) {
                    simpost.Redropped = "done";
                  }
                });
              });
              console.log(simposts);
            });

            //maybe promise is better instead of timeout?
            setTimeout(() => {
              reffilllength = simposts.length + 1;
              setSimdisplays([...simdisplays, simposts]);
            }, 500);
          });

          if (alldisplay.length === 0) {
            postincrement();
          } else {
            commentincrement(-1);
          }
        } else {
          setAlldisplay([
            ...alldisplay.slice(0, -1),
            {
              ...alldisplay.at(-1),
              Comment_Counter: alldisplay.at(-1).Comment_Counter + 1,
            },
          ]);
          let ranktarget = simdisplays
            .at(-1)
            .findIndex((simdisplay) => userinfo.Rank <= simdisplay.Rank);
          console.log(userinfo.Rank);
          console.log(ranktarget);

          let Time = new Date();
          commentobject = {
            ...commentobject,
            id: docRef.id,
            year: Time.getFullYear(),
            month: ("0" + (Time.getMonth() + 1)).slice(-2),
            date: ("0" + Time.getDate()).slice(-2),
            hour: Time.getHours(),
            minutes: ("0" + Time.getMinutes()).slice(-2),
          };

          if (ranktarget === 0)
            setSimdisplays([
              ...simdisplays.slice(0, -1),
              [commentobject, ...simdisplays.at(-1)],
            ]);
          else if (ranktarget > 0)
            setSimdisplays([
              ...simdisplays.slice(0, -1),
              [
                ...simdisplays.at(-1).slice(0, ranktarget),
                commentobject,
                ...simdisplays.at(-1).slice(ranktarget),
              ],
            ]);
          else
            setSimdisplays([
              ...simdisplays.slice(0, -1),
              [...simdisplays.at(-1), commentobject],
            ]);

          if (alldisplay.length === 1) {
            postincrement();
          } else {
            commentincrement(-2);
          }
          reffilllength = simdisplays.at(-1).length + 2;
        }

        setTimeout(() => {
          //check if the length is correct since realid is going to be +2 and simposts is going to be +1
          console.log(reffilllength);
          droppedvideoref.current = new Array(reffilllength).fill(null);
          console.log(droppedvideoref.current);
          setVideorefload(!videorefload);
          //700 may not be enough who knows, if theres errors, change it
        }, 800);
      }
    );

    setTimeout(() => {
      //dropref.current = true;
      setProgresspercent(0);
    });

    const dropsRef1 = query(
      collectionGroup(db, "Drops"),
      where("Redrop_id", "==", clickedcomment.id)
    );

    getDocs(dropsRef1).then((querySnapshot) => {
      let posts1 = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      let new_posts1 = posts1.filter((post) => post.id !== clickedcomment.id);

      let batchArray = [];
      batchArray.push(writeBatch(db));
      let batchCount = 0;
      let batchIndex = 0;
      new_posts1.forEach((post) => {
        const userDocumentRef = doc(
          db,
          "users",
          post.Redropper_id,
          "Drops",
          post.id
        );
        batchArray[batchIndex].update(userDocumentRef, {
          Comment_Counter: increment(1),
        });
        batchCount++;
        if (batchCount === 499) {
          batchArray.push(writeBatch(db));
          batchIndex++;
          batchCount = 0;
        }
      });

      batchArray.forEach(async (batch) => await batch.commit());
    });

    setClickedcomment("");
  };

  const redrop = async (event) => {
    console.log(event.target.id);
    let redrop = event.target.id.split(",");
    console.log(redrop);

    const userBlockRef = doc(db, "users", redrop[1], "Blocked", id);

    let blocked = await getDoc(userBlockRef).then((documentSnapshot) => {
      console.log(documentSnapshot.exists());
      console.log(documentSnapshot.data());
      return documentSnapshot;
    });

    console.log(blocked);
    console.log(blocked.exists());

    const resetforredrop = () => {
      setOptionId("");
      setDeleteid("");
      setUnredroppoint(false);
    };
    const blockedreset = () => {
      resetforredrop();
      setBlockid(redrop[0]);
      setRedroppoint(false);
      console.log("YEAH");
    };
    if (blocked.exists()) return blockedreset();

    console.log("dont run fool");
    resetforredrop();
    setRedroppoint(true);
    setRedropid(redrop);
    setBlockid("");
  };

  /*
  const redropraise = async (matchtarget,post) => {
    console.log(matchtarget)//get proped over here 
   console.log(post)//the post we switch position with
    

    if (matchtarget === 0) {
    
     
      if (posts[matchtarget - 1]?.Directory === id){
  setPosts([
           {
        ...posts[incrementtarget],
        Redropped_Counter: posts[incrementtarget].Redropped_Counter + 1,
        Redropped: "done",
      },
          posts[matchtarget - 1],
          ...posts.slice(matchtarget + 2),
        ]);
      }
    } else if (matchtarget > 0) {
       if (posts[matchtarget - 1]?.Directory === id){
  setPosts([
          ...posts.slice(0, matchtarget - 1),
          {
        ...posts[incrementtarget],
        Redropped_Counter: posts[incrementtarget].Redropped_Counter + 1,
        Redropped: "done",
      },
          posts[matchtarget - 1],
          ...posts.slice(matchtarget + 2),
        ]);
      }
     
    } 
    
   
  }
*/

  const redropraise = (incrementtarget, frontendupdater) => {
    let postupdater = {
      ...posts[incrementtarget],
      Redropped_Counter: posts[incrementtarget].Redropped_Counter + 1,
      Redropped: "done",
      //...redroppedfrontendupdater(posts[incrementtarget]),
      Timestamp: Timestamp.fromDate(new Date()),
      OriginalTimestamp: posts[incrementtarget].Timestamp,
    };
    console.log(postupdater);
    if (incrementtarget === 0) {
      //if (posts[incrementtarget - 1]?.Directory === id){

      setPosts([
        /* {
          
          ...posts[incrementtarget],
          Redropped_Counter: posts[incrementtarget].Redropped_Counter + 1,
          Redropped: "done",
          //...redroppedfrontendupdater(posts[incrementtarget]),
          Timestamp: Timestamp.fromDate(new Date()),
          OriginalTimestamp: posts[incrementtarget].Timestamp,

        },*/
        postupdater,
        posts[incrementtarget - 1],
        ...posts.slice(incrementtarget + 1 /*2*/),
      ]);
      //  }
    } else {
      setPosts([
        ...posts.slice(0, incrementtarget - 1),
        /*{
          ...posts[incrementtarget],
          Redropped_Counter: posts[incrementtarget].Redropped_Counter + 1,
          Redropped: "done",
              //...redroppedfrontendupdater(posts[incrementtarget]),
           Timestamp: Timestamp.fromDate(new Date()),
          OriginalTimestamp: posts[incrementtarget].Timestamp,
          
        },*/
        postupdater,
        posts[incrementtarget - 1],
        ...posts.slice(incrementtarget + 1 /*2*/),
      ]);
    }
  };

  const unredroplower = async (incrementtarget, frontendupdater) => {
    let postupdater = {
      ...posts[incrementtarget],
      Redropped_Counter: posts[incrementtarget].Redropped_Counter - 1,
      Redropped: "",
      //...redroppedfrontendupdater(posts[incrementtarget]),
      Timestamp: posts[incrementtarget].OriginalTimestamp,
    };
    console.log(posts[incrementtarget]);
    if (
      posts[incrementtarget + 1]?.Directory === id &&
      posts[incrementtarget].OriginalTimestamp.seconds >=
        posts[incrementtarget + 1].Timestamp.seconds
    ) {
      posts[incrementtarget] = postupdater;
      /*setTimeout(()=>{delete posts[incrementtarget].OriginalTimestamp})
return*/
      return setTimeout(() => {
        delete posts[incrementtarget].OriginalTimestamp;
      });
    }
    console.log("This better not run");
    //need to await this?
    //let posts
    let addingposts;
    //let addingpostsid;
    /*else { */
    const postsRef = collection(db, "users", id, "Drops");
    await getDocs(
      query(
        postsRef,
        where("Directory", "==", id),
        //where("Pure", "==", true),
        orderBy("Timestamp", "desc"),
        limit(3)
      )
    ).then((querySnapshot) => {
      //posts = querySnapshot.docs.slice(0,1)
      //console.log(posts)
      //maybe do it without the map fnction?
      /*posts = /*querySnapshot.docsposts.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        */
      // console.log(querySnapshot.docs.data())
      console.log(querySnapshot.docs.length);
      //if (querySnapshot.docs.length < 3) addingposts = posts[incrementtarget]
      //if we only have 2 posts or lower

      console.log(querySnapshot.docs.at(-1).id);

      console.log(querySnapshot.docs.at(-1).data());
      addingposts =
        querySnapshot.docs.length < 3
          ? [
              {
                Timestamp: {
                  seconds: 0 /* i guess it cold be posts[incrementtarget]?.OriginalTimestamp.seconds*/,
                },
              },
            ]
          : [querySnapshot.docs.at(-1).data(), querySnapshot.docs.at(-1).id];
      //addingpostsid = querySnapshot.docs.at(-1).id;
    });

    //}

    console.log(addingposts);
    console.log(posts[incrementtarget]);

    if (
      posts[incrementtarget + 1]?.Directory !== id &&
      posts[incrementtarget]?.OriginalTimestamp.seconds >=
        addingposts[0].Timestamp.seconds
    ) {
      posts[incrementtarget] = postupdater;
      return setTimeout(() => {
        delete posts[incrementtarget].OriginalTimestamp;
      });
    } else if (
      posts[incrementtarget]?.OriginalTimestamp.seconds >=
      addingposts[0].Timestamp.seconds
    ) {
      //////////////
      if (incrementtarget === 0) {
        //if (posts[incrementtarget - 1]?.Directory === id){

        setPosts([
          /* {
          
          ...posts[incrementtarget],
          Redropped_Counter: posts[incrementtarget].Redropped_Counter + 1,
          Redropped: "done",
          //...redroppedfrontendupdater(posts[incrementtarget]),
          Timestamp: Timestamp.fromDate(new Date()),
          OriginalTimestamp: posts[incrementtarget].Timestamp,

        },*/
          posts[incrementtarget + 1],
          postupdater,
          ...posts.slice(incrementtarget + 2 /*2*/),
        ]);
        //  }
      } else {
        //
        console.log("Here??");
        setPosts([
          ...posts.slice(0, incrementtarget /*- 1*/),
          /*{
          ...posts[incrementtarget],
          Redropped_Counter: posts[incrementtarget].Redropped_Counter + 1,
          Redropped: "done",
              //...redroppedfrontendupdater(posts[incrementtarget]),
           Timestamp: Timestamp.fromDate(new Date()),
          OriginalTimestamp: posts[incrementtarget].Timestamp,
          
        },*/
          posts[incrementtarget + 1],
          postupdater,

          ...posts.slice(incrementtarget + 2 /*2*/),
        ]);
      }
      setTimeout(() => {
        delete posts[incrementtarget].OriginalTimestamp;
      });
    } else {
      //maybe posts instead of posts[0]
      let redroptimestampedit = {};
      if (!addingposts[0].Redropper_id)
        redroptimestampedit = {
          year: addingposts[0].Timestamp.toDate().getFullYear(),
          month: (
            "0" +
            (addingposts[0].Timestamp.toDate().getMonth() + 1)
          ).slice(-2),
          date: ("0" + addingposts[0].Timestamp.toDate().getDate()).slice(-2),
          hour: addingposts[0].Timestamp.toDate().getHours(),
          minutes: ("0" + addingposts[0].Timestamp.toDate().getMinutes()).slice(
            -2
          ),
        };
      let Redroppedornot = {};
      if (addingposts[0].Redropper_id === id)
        Redroppedornot = { Redropped: "done" };
      let addingdoc = {
        ...addingposts[0],
        ...redroptimestampedit,
        ...Redroppedornot,
        //id: addingpostsid /*addingposts.id,*/,
        id: addingposts[1],
      };
      //////////////////actually need to add redropped done if its redropped by thee
      if (posts[incrementtarget + 1]?.Directory !== id) {
        /////////////////////////////////
        /*//if (incrementtarget === 0) {
        //if (posts[incrementtarget - 1]?.Directory === id){

      //  setPosts([
           {
          
          ...posts[incrementtarget],
          Redropped_Counter: posts[incrementtarget].Redropped_Counter + 1,
          Redropped: "done",
          //...redroppedfrontendupdater(posts[incrementtarget]),
          Timestamp: Timestamp.fromDate(new Date()),
          OriginalTimestamp: posts[incrementtarget].Timestamp,

        },
        //  posts[incrementtarget - 1],
        //  addingdoc,
        ///  ...posts.slice(incrementtarget + 2 /*2),
        //]);
        //  }
     // } 
     */
        // else {
        //
        setPosts([
          ...posts.slice(0, incrementtarget /*- 1*/),
          addingdoc,
          //Timestamp: posts[incrementtarget].OriginalTimestamp,

          ...posts.slice(incrementtarget + 1 /*2*/),
        ]);
        // }
      } else {
        if (incrementtarget === 0) {
          //if (posts[incrementtarget - 1]?.Directory === id){

          setPosts([
            /* {
          
          ...posts[incrementtarget],
          Redropped_Counter: posts[incrementtarget].Redropped_Counter + 1,
          Redropped: "done",
          //...redroppedfrontendupdater(posts[incrementtarget]),
          Timestamp: Timestamp.fromDate(new Date()),
          OriginalTimestamp: posts[incrementtarget].Timestamp,

        },*/
            posts[incrementtarget + 1],
            addingdoc,
            ...posts.slice(incrementtarget + 2 /*2*/),
          ]);
          //  }
        } else {
          //
          setPosts([
            ...posts.slice(0, incrementtarget /*- 1*/),
            /*{
          ...posts[incrementtarget],
          Redropped_Counter: posts[incrementtarget].Redropped_Counter + 1,
          Redropped: "done",
              //...redroppedfrontendupdater(posts[incrementtarget]),
           Timestamp: Timestamp.fromDate(new Date()),
          OriginalTimestamp: posts[incrementtarget].Timestamp,
          
        },*/
            posts[incrementtarget + 1],

            addingdoc,
            //Timestamp: posts[incrementtarget].OriginalTimestamp,

            ...posts.slice(incrementtarget + 2 /*2*/),
          ]);
        }
      }
    }
  };

  const commentraise = (incrementtarget, commentindex, timestampswitch) => {
    console.log("Activating");
    console.log(commentindex);
    let replacecommentindex = incrementtarget - 1;
    //2
    for (let el of simdisplays.at(commentindex)) {
      if (simdisplays.at(commentindex)[replacecommentindex]?.Sender_id === id) {
        replacecommentindex -= 1;
        console.log(replacecommentindex);
      } else {
        replacecommentindex += 1;
        break;
      }
    }

    console.log(incrementtarget);
    console.log(replacecommentindex);

    let endcommentindex =
      commentindex === -1 ? [] : simdisplays.slice(commentindex + 1);

    let commentupdater = {
      ...simdisplays.at(commentindex)[incrementtarget],
      Redropped_Counter:
        simdisplays.at(commentindex)[incrementtarget].Redropped_Counter + 1,
      Redropped: "done",
      ...timestampswitch,
    };

    if (replacecommentindex === 0) {
      //if (posts[incrementtarget - 1]?.Directory === id){

      //still needs to redropped "done" and increment
      console.log(simdisplays.slice(commentindex + 1));

      setSimdisplays([
        ...simdisplays.slice(0, commentindex), //can be -2
        [
          /*
          {
            ...simdisplays.at(commentindex)[incrementtarget],
            Redropped_Counter:
              simdisplays.at(commentindex)[incrementtarget].Redropped_Counter +
              1,
            Redropped: "done",
            ...timestampswitch
            
          },*/
          commentupdater,
          ...simdisplays.at(commentindex).slice(0, incrementtarget),
          ...simdisplays.at(commentindex).slice(incrementtarget + 1),
        ],
        ...endcommentindex,
        //...simdisplays.slice(commentindex + 1),
        //when the commentindex is -1 there could be a problem, since
        //it will be empty and thus could be undefined
      ]);
    } else {
      console.log(...simdisplays.slice(commentindex + 1));
      //else if (ranktarget > 0)
      console.log(
        simdisplays.at(commentindex).slice(replacecommentindex, incrementtarget)
      );
      setSimdisplays([
        ...simdisplays.slice(0, commentindex), //can be -2
        [
          ...simdisplays.at(commentindex).slice(0, replacecommentindex),
          /* {
            ...simdisplays.at(commentindex)[incrementtarget],
            Redropped_Counter:
              simdisplays.at(commentindex)[incrementtarget].Redropped_Counter +
              1,
            Redropped: "done",
            ...timestampswitch,
          },*/
          commentupdater,
          ...simdisplays
            .at(commentindex)
            .slice(replacecommentindex, incrementtarget),
          ...simdisplays.at(commentindex).slice(incrementtarget + 1),

          //could be a problem if this is the last index
        ],
        ...endcommentindex,
        // ...simdisplays.slice(commentindex + 1),
        //when the commentindex is -1 there could be a problem, since
        //it will be empty and thus could be undefined
      ]);
    }
    /*
          else
            setSimdisplays([
              ...simdisplays.slice(0, -1),
              [...simdisplays.at(-1), commentobject],
            ]);
**/
  };

  const commentlower = (incrementtarget, commentindex, timestampswitch) => {
    console.log("Activating");
    console.log(commentindex);
    //we dont needt to querry for comments be it all exist
    ///
    let replacecommentindex = incrementtarget + 1;
    //2
    for (let el of simdisplays.at(commentindex)) {
      if (
        simdisplays.at(commentindex)[replacecommentindex]?.Sender_id === id &&
        simdisplays.at(commentindex)[incrementtarget]?.OriginalTimestamp
          .seconds <
          simdisplays.at(commentindex)[replacecommentindex]?.Timestamp.seconds
      ) {
        replacecommentindex += 1;
        console.log(replacecommentindex);
      } else {
        replacecommentindex -= 1;
        break;
      }
    }

    console.log(incrementtarget);
    console.log(replacecommentindex);

    /////////////////
    //Redrop_id and Redropper emptied as well?
    let commentupdater = {
      ...simdisplays.at(commentindex)[incrementtarget],
      Redropped_Counter:
        simdisplays.at(commentindex)[incrementtarget].Redropped_Counter - 1,
      Redropped: "",
      ...timestampswitch,
    };

    if (replacecommentindex === incrementtarget) {
      /*  return (() =>{
        console.log("LISTENIN?");
        simdisplays.at(commentindex)[incrementtarget] = commentupdater;
       //return 
       setTimeout(() => {
          delete simdisplays.at(commentindex)[incrementtarget]
            .OriginalTimestamp;
        });
      })()
  */
      console.log("LISTENIN?");
      simdisplays.at(commentindex)[incrementtarget] = commentupdater;
      //return
      return setTimeout(() => {
        delete simdisplays.at(commentindex)[incrementtarget].OriginalTimestamp;
      });
    }

    console.log("Better not be");

    let endcommentindex =
      commentindex === -1 ? [] : simdisplays.slice(commentindex + 1);

    ////////////
    // if (replacecommentindex === 0) {
    //if (posts[incrementtarget - 1]?.Directory === id){

    // setSimdisplays([
    //   ...simdisplays.slice(0, commentindex), //can be -2
    //  [
    /*
          {
            ...simdisplays.at(commentindex)[incrementtarget],
            Redropped_Counter:
              simdisplays.at(commentindex)[incrementtarget].Redropped_Counter +
              1,
            Redropped: "done",
            ...timestampswitch
            
          },*/
    //  commentupdater,
    //  ...simdisplays.at(commentindex).slice(0, incrementtarget),
    //  ...simdisplays.at(commentindex).slice(incrementtarget + 1),
    // ],
    //  ...endcommentindex,
    //...simdisplays.slice(commentindex + 1),
    //when the commentindex is -1 there could be a problem, since
    //it will be empty and thus could be undefined
    //  ]);
    //  }

    //else {
    console.log(...simdisplays.slice(commentindex + 1));
    //else if (ranktarget > 0)
    console.log(
      simdisplays.at(commentindex).slice(replacecommentindex, incrementtarget)
    );
    setSimdisplays([
      ...simdisplays.slice(0, commentindex), //can be -2
      [
        ...simdisplays
          .at(commentindex)
          .slice(0, incrementtarget /* replacecommentindex*/),
        /* {
            ...simdisplays.at(commentindex)[incrementtarget],
            Redropped_Counter:
              simdisplays.at(commentindex)[incrementtarget].Redropped_Counter +
              1,
            Redropped: "done",
            ...timestampswitch,
          },*/
        ...simdisplays
          .at(commentindex)
          .slice(incrementtarget + 1, replacecommentindex + 1),
        commentupdater,
        ...simdisplays.at(commentindex).slice(replacecommentindex + 1),
        /*
          ...simdisplays
            .at(commentindex)
            .slice(replacecommentindex, incrementtarget),
            
          ...simdisplays.at(commentindex).slice(incrementtarget + 1),*/
      ],
      ...endcommentindex,
    ]);
    //}
    /*
          else
            setSimdisplays([
              ...simdisplays.slice(0, -1),
              [...simdisplays.at(-1), commentobject],
            ]);
**/
  };

  const confirmredrop = async (redroppeddoc) => {
    /*const redroppedfrontendupdater = (droporcommenttarget) => {
        return {
          //...posts[incrementtarget],
          ...droporcommenttarget,
          Redropped_Counter: droporcommenttarget.Redropped_Counter + 1,
          Redropped: "done",
        };
      };*/
    if (alldisplay.length === 0) {
      let incrementtarget = posts.findIndex((post) => redropid[0] === post.id);
      if (redropid[1] !== id) {
        posts[incrementtarget] = {
          ...posts[incrementtarget],
          Redropped_Counter: posts[incrementtarget].Redropped_Counter + 1,
          Redropped: "done",
        };
        //redroppedfrontendupdater(posts[incrementtarget]);
        console.log(posts[incrementtarget]);
      } else if (posts[incrementtarget - 1]?.Directory !== id) {
        posts[incrementtarget] = {
          ...posts[incrementtarget],
          Redropped_Counter: posts[incrementtarget].Redropped_Counter + 1,
          Redropped: "done",

          //...redroppedfrontendupdater(posts[incrementtarget]),
          Timestamp: Timestamp.fromDate(new Date()),
          OriginalTimestamp: posts[incrementtarget].Timestamp,
        };
        console.log(posts[incrementtarget]);
      } else {
        redropraise(incrementtarget /*redroppedfrontendupdater*/);
      }
    } else if (redropid[0] === alldisplay.at(-1).id) {
      let timestampswitch =
        redropid[1] === id
          ? {
              Timestamp: Timestamp.fromDate(new Date()),
              OriginalTimestamp: alldisplay.at(-1).Timestamp,
            }
          : {};
      setAlldisplay([
        ...alldisplay.slice(0, -1),
        {
          ...alldisplay.at(-1),
          Redropped_Counter: alldisplay.at(-1).Redropped_Counter + 1,
          Redropped: "done",
          // ...redroppedfrontendupdater(alldisplay.at(-1)),
          ...timestampswitch,
        },
      ]);
      if (alldisplay.length === 1) {
        let incrementtarget = posts.findIndex(
          (post) => redropid[0] === post.id
        );

        if (
          posts[incrementtarget - 1]?.Directory !== id ||
          redropid[1] !== id
        ) {
          posts[incrementtarget] = {
            ...posts[incrementtarget],
            Redropped_Counter: posts[incrementtarget].Redropped_Counter + 1,
            Redropped: "done",
            ...timestampswitch,
          };
        } else {
          redropraise(incrementtarget /*redroppedfrontendupdater*/);
        }

        /*
        posts[incrementtarget] = {
          ...posts[incrementtarget],
          Redropped_Counter: posts[incrementtarget].Redropped_Counter + 1,
          Redropped: "done",
        };*/
      } else {
        //videoref observer needs to be reset? if there are videos
        let incrementtarget = simdisplays
          .at(-2)
          .findIndex((simpost) => redropid[0] === simpost.id);

        if (
          simdisplays.at(-2)[incrementtarget - 1]?.Sender_id !== id ||
          redropid[1] !== id
        ) {
          /*let timestampswitch = redropid[1] === id ? {Timestamp: Timestamp.fromDate(new Date()),
          OriginalTimestamp: alldisplay.at(-1).Timestamp,} : {}*/
          simdisplays.at(-2)[incrementtarget] = {
            ...simdisplays.at(-2)[incrementtarget],
            Redropped_Counter:
              simdisplays.at(-2)[incrementtarget].Redropped_Counter + 1,
            Redropped: "done",
            ...timestampswitch,
          };
        } else {
          //console.log(commentraise(incrementtarget, -2, timestampswitch));
          commentraise(incrementtarget, -2, timestampswitch);
          console.log(incrementtarget);
        }
      }
    } else {
      let incrementtarget = simdisplays
        .at(-1)
        .findIndex((simpost) => redropid[0] === simpost.id);
      let timestampswitch =
        redropid[1] === id
          ? {
              Timestamp: Timestamp.fromDate(new Date()),
              OriginalTimestamp: simdisplays.at(-1)[incrementtarget].Timestamp,
            }
          : {};
      if (
        simdisplays.at(-1)[incrementtarget - 1]?.Sender_id !== id ||
        redropid[1] !== id
      ) {
        simdisplays.at(-1)[incrementtarget] = {
          ...simdisplays.at(-1)[incrementtarget],
          Redropped_Counter:
            simdisplays.at(-1)[incrementtarget].Redropped_Counter + 1,
          Redropped: "done",
          ...timestampswitch,
        };
      } else {
        //console.log(commentraise(incrementtarget, -1, timestampswitch));
        commentraise(incrementtarget, -1, timestampswitch);
        console.log(incrementtarget);
      }
    }
    setRedroppoint(false);

    let redroppedupdater = {
      Redrop_id: redropid[0],
      Redropped: true,
      Redropper_id: id,
    };

    if (
      (alldisplay.length >= 1 && alldisplay[0].id !== redropid[0]) ||
      redropid[1] !== id
      /* redropid[1] !== id  &&
        (alldisplay.length === 0 || alldisplay[0]?.id === redropid[0])) ||
      (alldisplay.length > 0 && alldisplay[0]?.id !== redropid[0])*/
    ) {
      /////////////
      //we need this for all redropped shiz including your own
      //fix the filer new_posts at the bottom
      /* redroppedupdater = {
        Redrop_id: redropid[0],
        Redropped: true,
        Redropper_id: id,
      };*/

      console.log(redroppeddoc);
      //we dont add this if this is my own drop
      await addDoc(collection(db, `users/${id}/Drops/`), {
        ...redroppeddoc,
        ...redroppedupdater,
        Timestamp: serverTimestamp(),
        //we may not even need the Real_Timestamp
        Real_Timestamp: [
          redroppeddoc.year,
          redroppeddoc.month,
          redroppeddoc.date,
          redroppeddoc.hour,
          redroppeddoc.minutes,
        ],
        /*
          Real_Timestamp1:{
            year:redroppeddoc.year,month:redroppeddoc.month,date:redroppeddoc.date,hour:redroppeddoc.hour,minutes:redroppeddoc.minutes,
          }*/
      });
    }
    let selfredropinfo = {};
    if (redropid[1] === id) {
      selfredropinfo = {
        year: redroppeddoc.year,
        month: redroppeddoc.month,
        date: redroppeddoc.date,
        hour: redroppeddoc.hour,
        minutes: redroppeddoc.minutes,
        Timestamp: serverTimestamp(),
        OriginalTimestamp: redroppeddoc.Timestamp,
        ...redroppedupdater,
      };
    } else {
      let a =
        alldisplay.length === 0 || redropid[0] === alldisplay[0]?.id
          ? "Drops"
          : alldisplay.length === 1 || redropid[0] === alldisplay[1]?.id
          ? "Comments" //this will make it two to load
          : "NestedComments"; //this will make 3 to load

      let redropnotification = {
        Type: "Redrop",
        Loadtype: a,
        Timestamp: serverTimestamp(),
        Profile_image: userinfo.Profile_image,
        Name: userinfo.User_Name,
        Rank: userinfo.Rank,
        Directory: redropid[1],
        rootid: redropid[0],
        Redropper_id: id,
        text: redroppeddoc.text,
        originalpost: redroppeddoc,
      };

      if (a === "Comments") {
        redropnotification = {
          ...redropnotification,
          Comment_Directory: redropid[0],
          rootid: alldisplay[0]?.id,
          root_user: alldisplay[0]?.Directory,
          text: redroppeddoc.Comment,
        };
      } else if (a === "NestedComments") {
        let Directories =
          redropid[0] === alldisplay[alldisplay.length - 1].id
            ? [
                alldisplay[alldisplay.length - 2].id,
                alldisplay[alldisplay.length - 2].Sender_id,
              ]
            : [
                alldisplay[alldisplay.length - 1].id,
                alldisplay[alldisplay.length - 1].Sender_id,
              ];

        redropnotification = {
          ...redropnotification,
          Comment_Directory: Directories[0],
          Directory: Directories[1],
          Nested_Directory: redropid[0],
          Nested_user: redropid[1],
          rootid: alldisplay[0]?.id,
          root_user: alldisplay[0]?.Directory,
          text: redroppeddoc.Comment,
        };
      }
      console.log(redropnotification);
      addDoc(
        collection(db, `users/${redropid[1]}/Notification/`),
        redropnotification
      );

      updateDoc(doc(db, `users/${redropid[1]}`), {
        Notification: increment(1),
      });
    }

    try {
      //await
      //////////////////////
      //we need the redropper id and shiz even if its your own drop or you wont know if its redropped or nah
      //if its your own post your redropping you need dates added from redroppeddoc
      //you might not need redropped you may kknow its redropped by seeing if the dates yeart etc are there already
      // let selfredropinfo = {};
      //let dropsorcomments;
      let dropsorcomments =
        alldisplay.length === 0 || alldisplay[0]?.id === redropid[0]
          ? "Drops"
          : "Comments";

      updateDoc(
        doc(
          db,
          `users/${redropid[1]}/${
            /*alldisplay.length === 0 || alldisplay[0]?.id === redropid[0]
              ? "Drops"
              : "Comments"*/ dropsorcomments
          }/${redropid[0]}`
        ),

        {
          Redropped_Counter: increment(1),
          ...selfredropinfo,
        }
      );
    } catch (error) {
      console.log(error);
    }

    //does this mess up the query batch array at the bottom Redrop_id ===

    //if(redropid[1] !== id)

    const dropsRef = query(
      collectionGroup(db, "Drops"),
      where("Redrop_id", "==", redropid[0])
    );
    await getDocs(dropsRef).then((querySnapshot) => {
      let posts = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      ////////////////////////////////////////////////
      //we`re trying to filter out the comments that are redropped by the same person,
      //since we update the comments at the top already
      //WROOOONG Comments are not updated at the top only the pure comment is updated
      //not the reropped addedoc comment
      let new_posts =
        alldisplay.length === 0 || alldisplay[0]?.id === redropid[0]
          ? posts.filter((post) => post.Directory !== post.Redropper_id)
          : /*alldisplay.length > 0 && alldisplay[0]?.id !== redropid[0]
          && */ posts;
      /*let new_posts =
        alldisplay.length > 0 && alldisplay[0]?.id !== redropid[0]
          ? posts.filter((post) => post.Sender_id !== post.Redropper_id)
          : posts;*/

      //by the time the top selfredropinfo is updated we are not using
      //await so when the batch array collectiongroup is called redrop_id is not
      //there yet, thus we dont need to filter anything since the drop wont be included since redrop_id is not updated in the database yet
      //if it increments twice then we might need to put await at the updatedoc and filter out
      //the doc that will be included in the collectiongroup with your own drop that has been redropped by you
      //let new_posts = posts;

      console.log(new_posts.length);

      let batchArray = [];
      batchArray.push(writeBatch(db));
      let batchCount = 0;
      let batchIndex = 0;
      new_posts.forEach((post) => {
        const userDocumentRef = doc(
          db,
          "users",
          post.Redropper_id,
          "Drops",
          post.id
        );
        batchArray[batchIndex].update(userDocumentRef, {
          Redropped_Counter: increment(1),
        });
        batchCount++;
        if (batchCount === 499) {
          batchArray.push(writeBatch(db));
          batchIndex++;
          batchCount = 0;
        }
      });

      batchArray.forEach(async (batch) => await batch.commit());
    });
  };

  const AskUnredrop = (e) => {
    console.log(e.target.id.split(","));
    setUnredropid(e.target.id.split(","));

    setOptionId("");
    setDeleteid("");
    setRedroppoint(false);
    setUnredroppoint(true);
  };

  const ConfirmUnredrop = async (OriginalTimestamp) => {
    if (alldisplay.length === 0) {
      let incrementtarget = posts.findIndex(
        (post) => unredropid[0] === post.id
      );
      if (unredropid[1] !== id) {
        posts[incrementtarget] = {
          ...posts[incrementtarget],
          Redropped_Counter: posts[incrementtarget].Redropped_Counter - 1,
          Redropped: "",
        };
      } else if (
        posts[incrementtarget + 1]?.Directory !== id &&
        posts[incrementtarget - 1]?.Directory !== id
      ) {
        posts[incrementtarget] = {
          ...posts[incrementtarget],
          Redropped_Counter: posts[incrementtarget].Redropped_Counter - 1,
          Redropped: "",

          //...redroppedfrontendupdater(posts[incrementtarget]),
          Timestamp: posts[incrementtarget].OriginalTimestamp,
        };
        setTimeout(() => {
          delete posts[incrementtarget].OriginalTimestamp;
        });
      } else {
        await unredroplower(incrementtarget /*redroppedfrontendupdater*/);
      }
    } else if (unredropid[0] === alldisplay.at(-1).id) {
      let timestampswitch = {};
      let originaltimestampdelete = (target) => {};
      if (unredropid[1] === id) {
        timestampswitch = { Timestamp: alldisplay.at(-1).OriginalTimestamp };
        originaltimestampdelete = (target) => {
          setTimeout(() => {
            delete target.OriginalTimestamp;
            /*alldisplay.at(-1).OriginalTimestamp*/
          });
        };
      }

      //let tempdelete = { ...alldisplay.at(-1) };
      // originaltimestampdelete(tempdelete);
      let { OriginalTimestamp, ...tempdelete } = alldisplay.at(-1);
      console.log(tempdelete);
      setAlldisplay([
        ...alldisplay.slice(0, -1),
        {
          //...alldisplay.at(-1),
          ...tempdelete,
          Redropped_Counter: alldisplay.at(-1).Redropped_Counter - 1,
          Redropped: "",
          ...timestampswitch,
        },
      ]);

      if (alldisplay.length === 1) {
        let incrementtarget = posts.findIndex(
          (post) => unredropid[0] === post.id
        );
        if (
          (posts[incrementtarget - 1]?.Directory !== id &&
            posts[incrementtarget + 1]?.Directory !== id) ||
          unredropid[1] !== id
        ) {
          posts[incrementtarget] = {
            ...posts[incrementtarget],
            Redropped_Counter: posts[incrementtarget].Redropped_Counter - 1,
            Redropped: "",
            ...timestampswitch,
          };
          console.log(incrementtarget);
          originaltimestampdelete(alldisplay.at(-1));
        } else {
          await unredroplower(incrementtarget /*redroppedfrontendupdater*/);
        }
      } else {
        let incrementtarget = simdisplays
          .at(-2)
          .findIndex((simpost) => unredropid[0] === simpost.id);

        if (
          simdisplays.at(-2)[incrementtarget + 1]?.Sender_id !== id ||
          unredropid[1] !== id
        ) {
          let incrementtarget = simdisplays
            .at(-2)
            .findIndex((simpost) => unredropid[0] === simpost.id);
          simdisplays.at(-2)[incrementtarget] = {
            ...simdisplays.at(-2)[incrementtarget],
            Redropped_Counter:
              simdisplays.at(-2)[incrementtarget].Redropped_Counter - 1,
            Redropped: "",
            ...timestampswitch,
          };
          originaltimestampdelete(simdisplays.at(-2)[incrementtarget]);
        } else {
          commentlower(incrementtarget, -2, timestampswitch);
          console.log(incrementtarget);
        }
      }
    } else {
      let incrementtarget = simdisplays
        .at(-1)
        .findIndex((simpost) => unredropid[0] === simpost.id);

      let timestampswitch = {};
      let originaltimestampdelete = (/*target*/) => {};
      if (unredropid[1] === id) {
        timestampswitch = {
          Timestamp: simdisplays.at(-1)[incrementtarget].OriginalTimestamp,
        };
        originaltimestampdelete = (/*target*/) => {
          setTimeout(() => {
            delete simdisplays.at(-1)[incrementtarget].OriginalTimestamp;
            /*alldisplay.at(-1).OriginalTimestamp*/
          });
        };
      }

      if (
        simdisplays.at(-1)[incrementtarget + 1]?.Sender_id !== id ||
        unredropid[1] !== id
      ) {
        simdisplays.at(-1)[incrementtarget] = {
          ...simdisplays.at(-1)[incrementtarget],
          Redropped_Counter:
            simdisplays.at(-1)[incrementtarget].Redropped_Counter - 1,
          Redropped: "",
          ...timestampswitch,
        };
        originaltimestampdelete();
      } else {
        commentlower(incrementtarget, -1, timestampswitch);
        console.log(incrementtarget);
      }
    }
    setUnredroppoint(false);

    //let DeleteRef
    let deletedocfunction = () => {
      return new Promise((resolve) => {
        resolve(console.log("Hey"));
      });
    };

    if (
      (alldisplay.length >= 1 && alldisplay[0].id !== unredropid[0]) ||
      unredropid[1] !== id
    ) {
      /*(unredropid[1] !== id && alldisplay.length === 0) ||
      (alldisplay.length === 1 &&
        alldisplay[0].id === unredropid[0] &&
        alldisplay[0].Directory !== id) ||
      (alldisplay.length >= 1 && alldisplay[0].id !== unredropid[0]) //is this comment
     */ deletedocfunction = () => {
        return new Promise((resolve) => {
          let unredroptarget;
          const UnredropRef = collection(db, "users", id, "Drops");
          getDocs(UnredropRef).then((querySnapshot) => {
            let posts = querySnapshot.docs.map((doc) => ({
              ...doc.data(),
              id: doc.id,
            }));
            console.log(posts);

            for (let i = 0; i < posts.length; i++) {
              console.log(i);
              if (posts[i].Redrop_id === unredropid[0])
                unredroptarget = posts[i].id;
            }
            /*if the forloop doesnt await then do this method
    const discoverunredrop = () => {  
      for (let i = 0; i < posts.length; i++) {
        //num++;
        //make sure that i is 0 first, not 1
        console.log(i)
        if (posts[i].Redrop_id === unredropid[0]) unredroptarget = posts[i].id
      }
    }
    new Promise((resolve, reject) => {
      resolve(discoverunredrop())
    }).then(()=>{
      console.log("hey")
      //this is where delete goes
    })
      */
            //or Promise.all? since its a forloop

            //make sure this is finished redropanswer
            console.log(unredroptarget);

            let DeleteRef = doc(db, "users", id, "Drops", unredroptarget);
            //deletedocfunction = deleteDoc(userDocumentRef)
            resolve(deleteDoc(DeleteRef));
          });
        });
      };
    }
    //console.log(DeleteRef)
    //await deleteDoc(DeleteRef)
    await deletedocfunction();

    let decrementanddeletefield = {
      Redropped_Counter: increment(-1),
    };

    //videoref observer needs to be reset? if there are videos when we unredrop
    //and test redrop and unredrop immeditaely

    //(prolly if the video locations changes then its bad )
    //after redropping and unredropping and LOCATION changes of the VIDEO
    ////videoref observer needs to be reset? if there are videos
    //test with making the video location array change

    //observer TO THE ACTUAL VIDEO POST: delete realid 0, deltecommetn, delete realid 1, redropped comment, unredrop comment,sendcomment?
    //observer CHANGE THE LOCATION OF THE VIDEO POST: deletedrop, delete realid 0, deltecommetn, delete realid 1, redropped comment, unredrop comment,sendcomment?

    //maybe delete redrop notification
    //when unfollow delete follow notification
    //when deleteing comment delete comment notification? to root as well

    //Eplise girl in home page should have a bigger Eplise letter

    //test iphone 0 missions
    //test notification slash slash

    ///////////////////////////////////////////////////
    //able paypay pay in pledge subscribe
    //step by step on how to search by id pictures

    //injectionpromo
    //maybe instead of custom hook, regular function export may be good enough
    //maybe we need "use"forthe name to make it a custom hook
    //when calling this custom hook you must send the posts.length to it
    //bring in injectionpromos in the useffect of the connect page
    //randomly place 広告 within the posts
    //fix and style the DOM of injection 広告,
    //maybe users can have different interaction based on whether this person has an account or not
    //if they do maybe users gets to comment to the post, now the 広告 users gets to pick if comments will be allowed or not
    //these comments need to be saved in the database and brought in if this adds run again just like a regular post
    //maybe injectionpromo.css not needed?

    //make sure google ads run in connect (post , comments), user, userview, messages, and notification

    //display
    //display4
    //display5
    //finish all the ONLY the display of notification functions in notification component
    //make sure if you are recieving notification but its not a comment to you, but rather a comment to one of your commenter on your post,
    //you know that it is the case and display that in notificaion
    //all other display in notification fixed like display2 and shiz?

    //finish connect video shiz with ref being wrong
    //test it

    //return to iphone order 0.
    //Do we really need This_Directory?
    //if paypay went through incorporate it to the auto payment
    //Pledge Notification
    //all notification page redo
    //google adsense connect,user,userview,
    //paypay payment
    //connect page run ready sponsor ads, and 注意事項, and randomize or algorithmic the percentage of time it all comes out
    //other payments for pledgeplan
    //fix connect tempfiles videos
    //when deleting video in the tempfile drop prep state it errors
    //start working on fullscreen
    //chage the user and userview page layout
    //work and able business account user and userview
    //work on business drop
    //options for other work
    //notification slash slash\
    //options for other payment methods in pledgesubscribe
    //how to limit the reads in firebase by loading only if they scroll
    // 0 iphone notes
    //Redrop_id and Redropper emptied or deleted as well? when the actual video location
    //droppedvideoref.current = when is the time do we need to recall this?
    //seems like we only need to recall this when we delete or change the actual location of video
    //although even if we flip by redropping or unredropping a post with videos theres still no errors
    //without droppedvideoref.current = be redclared 調査each droppedvideoref.current = and see which one we dont need
    //by slash slash
    //all other payments in pledge
    //change user and userview page layouts and perfect them
    //homepage more slick animation needed
    //for 有料プランページit actually allows you to upload images without setting up a plan first, make sure it gives a warning and disallow
    //upload of anything without pledge plan set maybe even make it beep with different colors to where to press
    //make sure image onload thing is done in the homepage as well so the bottom shiz dont load first just
    //like in the fullscreenpromotion saga
    //for the fullscreenpromotional ad have a loading timer like Doki Doki live to let people know its an ad so they dont panick
    //remeber since now we are not going to load all posts without scrolling down each time, the injectionpromotion needs to be added perscroll and how many we are going to reload
    //perfect scroll load save money shiz
    //be able to REGULAR post if the save money scroll is implemented
    //if the scroll load money save is implemented and you make a post and you rank is below the number of rank that are loaded then your post shouldnt even be in the setstate
    //if it is equal or above the loaded rank then add it to the setstate
    //how to make a 広告 post from business account
    //if google 審査　fails figure ot next step
    //give warning for buyers of pledgeplan as well saying you better be over 18
    //give additional warning to seller if pledgeplan then you need to censor certain 部分 of your body

    console.log(OriginalTimestamp);
    if (unredropid[1] === id)
      decrementanddeletefield = {
        Redropped_Counter: increment(-1),
        Redrop_id: deleteField(),
        Redropped: deleteField(),
        Redropper_id: deleteField(),
        year: deleteField(),
        month: deleteField(),
        date: deleteField(),
        hour: deleteField(),
        minutes: deleteField(),
        Timestamp: OriginalTimestamp,
        OriginalTimestamp: deleteField(),
      };

    updateDoc(
      doc(
        db,
        `users/${unredropid[1]}/${
          alldisplay.length > 0 && alldisplay[0]?.id !== unredropid[0]
            ? "Comments"
            : "Drops"
        }/${unredropid[0]}`
      ) /*
        {
          Redropped_Counter: increment(-1),
        }*/,
      decrementanddeletefield
    );

    const dropsRef = query(
      collectionGroup(db, "Drops"),
      where("Redrop_id", "==", unredropid[0])
    );
    getDocs(dropsRef).then((querySnapshot) => {
      let posts = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      let new_posts =
        alldisplay.length === 0 || alldisplay[0]?.id === unredropid[0]
          ? posts.filter((post) => post.Directory !== post.Redropper_id)
          : alldisplay.length > 0 &&
            alldisplay[0]?.id !== unredropid[0] &&
            posts;

      console.log(new_posts);
      let batchArray = [];
      batchArray.push(writeBatch(db));
      let batchCount = 0;
      let batchIndex = 0;
      new_posts.forEach((post) => {
        const userDocumentRef = doc(
          db,
          "users",
          post.Redropper_id,
          "Drops",
          post.id
        );
        batchArray[batchIndex].update(userDocumentRef, {
          Redropped_Counter: increment(-1),
        });
        batchCount++;
        if (batchCount === 499) {
          batchArray.push(writeBatch(db));
          batchIndex++;
          batchCount = 0;
        }
      });

      batchArray.forEach(async (batch) => await batch.commit());
    });

    console.log("FINISHED");
  };

  const CancelUnredrop = () => {
    setUnredroppoint(false);
  };

  const OptionTerminal = (e) => {
    setOptionId(e.target.id.split(","));
    setRedroppoint(false);
    setUnredroppoint(false);
    setDeleteid("");
  };

  const deletecomment = async (index) => {
    const userDocumentRef = doc(
      db,
      "users",
      optionid[1],
      "Comments",
      optionid[0]
    );
    deleteDoc(userDocumentRef);

    let DirectoryorSenderid =
      alldisplay.length === index
        ? [alldisplay[0].Directory, "Drops"]
        : [alldisplay.at(-index).Sender_id, "Comments"];

    updateDoc(
      doc(
        db,
        `users/${DirectoryorSenderid[0]}/${DirectoryorSenderid[1]}/${
          alldisplay.at(-index).id
        }`
      ),
      {
        Comment_Counter: increment(-1),
      }
    );

    /*
 updateDoc(
      doc(
        db,
        `users/${DirectoryorSenderid[0]}/${DirectoryorSenderid[1]}/${
          alldisplay[alldisplay.length - index].id
        }`
      ),
      {
        Comment_Counter: increment(-1),
      }
    );
*/

    setAlldisplay((prevvalue) => [
      ...prevvalue.slice(0, -index),
      {
        ...prevvalue.at(-index),
        Comment_Counter: prevvalue.at(-index).Comment_Counter - 1,
      },
    ]);

    let commentdelete = simdisplays[simdisplays.length - index].findIndex(
      (element) => optionid[0] === element.id
    );
    console.log(commentdelete);
    console.log(simdisplays);

    if (commentdelete === 0) {
      setSimdisplays([
        ...simdisplays.slice(0, -index),

        [...simdisplays.at(-index).slice(1)],
      ]);
    } else {
      setSimdisplays([
        ...simdisplays.slice(0, -index),

        [
          ...simdisplays.at(-index).slice(0, commentdelete),
          ...simdisplays.at(-index).slice(commentdelete + 1),
        ],
      ]);
    }

    if (alldisplay.length === index) {
      let decrementtarget = posts.findIndex(
        (post) => alldisplay.at(-index).id === post.id
      );
      posts[decrementtarget] = {
        ...posts[decrementtarget],
        Comment_Counter: posts[decrementtarget].Comment_Counter - 1,
      };
    } else {
      let doublebackindex = index + 1;
      let decrementtarget = simdisplays
        .at(-doublebackindex)
        .findIndex((simpost) => alldisplay.at(-index).id === simpost.id);
      simdisplays.at(-doublebackindex)[decrementtarget] = {
        ...simdisplays.at(-doublebackindex)[decrementtarget],
        Comment_Counter:
          simdisplays.at(-doublebackindex)[decrementtarget].Comment_Counter - 1,
      };
    }

    if (clickedcomment) {
      setClickedcomment("");
    }
    setTimeout(() => {
      //check if the length is correct since realid is going to be +2 and simposts is going to be +1
      droppedvideoref.current = new Array(simdisplays.at(-index).length).fill(
        null
      );
      console.log(droppedvideoref.current);
      setVideorefload(!videorefload);
      //700 may not be enough who knows, if theres errors, change it
    }, 800);

    //is optionid okay? shouldnt we get it from deleteid?

    //////this is where you delete the images and videos
    if (simdisplays.at(-index)[commentdelete]?.file1?.path) {
      let deletefilelist = [
        ...(simdisplays.at(-index)[commentdelete].file1.path
          ? [simdisplays.at(-index)[commentdelete].file1.path]
          : []),
        ...(simdisplays.at(-index)[commentdelete].file2.path
          ? [simdisplays.at(-index)[commentdelete].file2.path]
          : []),
        ...(simdisplays.at(-index)[commentdelete].file3.path
          ? [simdisplays.at(-index)[commentdelete].file3.path]
          : []),
        ...(simdisplays.at(-index)[commentdelete].file4.path
          ? [simdisplays.at(-index)[commentdelete].file4.path]
          : []),
      ];

      console.log(deletefilelist);

      const storage = getStorage();
      deletefilelist.forEach((file) => {
        let oldRef = ref(storage, file);
        console.log("How many times");
        deleteObject(oldRef);
      });
    }

    const dropsRef1 = query(
      collectionGroup(db, "Drops"),
      where("Redrop_id", "==", optionid[0])
    );
    const querySnapshot1 = getDocs(dropsRef1).then((querySnapshot) => {
      let posts = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      let batchArray = [];
      batchArray.push(writeBatch(db));
      let batchCount = 0;
      let batchIndex = 0;
      posts.forEach((item) => {
        const userDocumentRef = doc(
          db,
          "users",
          item.Redropper_id,
          "Drops",
          item.id
        );
        batchArray[batchIndex].delete(userDocumentRef);
        batchCount++;
        if (batchCount === 499) {
          batchArray.push(writeBatch(db));
          batchIndex++;
          batchCount = 0;
        }
      });

      batchArray.forEach(async (batch) => await batch.commit());
    });

    const dropsRef2 = query(
      collectionGroup(db, "Drops"),
      where("Redrop_id", "==", alldisplay[alldisplay.length - index].id)
    );

    getDocs(dropsRef2).then((querySnapshot) => {
      let posts2 = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      let new_posts1 = posts2.filter(
        (post) => post.id !== alldisplay[alldisplay.length - index].id
      );

      let batchArray = [];
      batchArray.push(writeBatch(db));
      let batchCount = 0;
      let batchIndex = 0;
      new_posts1.forEach((item) => {
        const userDocumentRef = doc(
          db,
          "users",
          item.Redropper_id,
          "Drops",
          item.id
        );
        batchArray[batchIndex].update(userDocumentRef, {
          Comment_Counter: increment(-1),
        });
        batchCount++;
        if (batchCount === 499) {
          batchArray.push(writeBatch(db));
          batchIndex++;
          batchCount = 0;
        }
      });

      batchArray.forEach(async (batch) => await batch.commit());
    });
  };

  const deletedrop = async (e) => {
    console.log(e.target.id);

    const getreplacementdrop = async () => {
      let addingdoc = "";
      const dropsRef1 = collection(db, "users", id, "Drops");
      await getDocs(
        query(
          dropsRef1,
          //where("Pure", "==", true),
          where("Directory", "==", id),
          orderBy("Timestamp", "desc"),
          limit(3)
        )
      ).then((querySnapshot) => {
        console.log();
        if (querySnapshot.docs.length === 3) {
          let tempaddingdoc = querySnapshot.docs.at(-1).data();
          let addingpostsid = querySnapshot.docs.at(-1).id;
          console.log(tempaddingdoc);
          let redroptimestampedit = {};
          let Redroppedornot = {};
          if (tempaddingdoc.Redropper_id === id) {
            Redroppedornot = { Redropped: "done" };
          } else if (!tempaddingdoc.Redropper_id)
            redroptimestampedit = {
              year: tempaddingdoc.Timestamp.toDate().getFullYear(),
              month: (
                "0" +
                (tempaddingdoc.Timestamp.toDate().getMonth() + 1)
              ).slice(-2),
              date: ("0" + tempaddingdoc.Timestamp.toDate().getDate()).slice(
                -2
              ),
              hour: tempaddingdoc.Timestamp.toDate().getHours(),
              minutes: (
                "0" + tempaddingdoc.Timestamp.toDate().getMinutes()
              ).slice(-2),
            };
          addingdoc = {
            ...tempaddingdoc,
            /* year: tempaddingdoc.data().Timestamp.toDate().getFullYear(),
            month: (
              "0" +
              (tempaddingdoc.data().Timestamp.toDate().getMonth() + 1)
            ).slice(-2),
            date: (
              "0" + tempaddingdoc.data().Timestamp.toDate().getDate()
            ).slice(-2),
            hour: tempaddingdoc.data().Timestamp.toDate().getHours(),
            minutes: (
              "0" + tempaddingdoc.data().Timestamp.toDate().getMinutes()
            ).slice(-2),*/
            ...redroptimestampedit,
            ...Redroppedornot,
            id: addingpostsid, //tempaddingdoc.id,
          };
        }
      });

      return addingdoc;
    };

    let deletetarget = posts.findIndex((element) => e.target.id === element.id);

    console.log(posts[deletetarget]);

    let rank = posts[deletetarget].Rank;

    if (rank === 1) {
      if (posts[deletetarget + 1]?.Directory === id) {
        let finaladdingdoc = await getreplacementdrop();
        let postinfo = finaladdingdoc
          ? [[finaladdingdoc], posts.length]
          : [[], posts.length - 1];
        console.log(postinfo);
        setPosts([
          posts[deletetarget + 1],
          ...postinfo[0],
          ...posts.slice(deletetarget + 2),
        ]);

        setTimeout(() => {
          droppedvideoref.current = new Array(postinfo[1]).fill(null);
        });
      } else if (posts[deletetarget - 1]?.Directory === id) {
        let finaladdingdoc = await getreplacementdrop();
        let postinfo = finaladdingdoc
          ? [[finaladdingdoc], posts.length]
          : [[], posts.length - 1];
        console.log(postinfo);
        setPosts([
          posts[deletetarget - 1],
          ...postinfo[0],
          ...posts.slice(deletetarget + 2),
        ]);

        setTimeout(() => {
          droppedvideoref.current = new Array(postinfo[1]).fill(null);
        });
      } else {
        setPosts([...posts.slice(deletetarget + 1)]);
        setTimeout(() => {
          droppedvideoref.current = new Array(posts.length - 1).fill(null);
        });
      }
    } else {
      if (posts[deletetarget + 1]?.Directory === id) {
        let finaladdingdoc = await getreplacementdrop();
        console.log(finaladdingdoc);
        let postinfo = finaladdingdoc
          ? [[finaladdingdoc], posts.length]
          : [[], posts.length - 1];
        console.log(postinfo);
        console.log(posts.length);
        console.log(droppedvideoref.current);
        setPosts([
          ...posts.slice(0, deletetarget),
          posts[deletetarget + 1],
          ...postinfo[0],
          ...posts.slice(deletetarget + 2),
        ]);

        setTimeout(() => {
          droppedvideoref.current = new Array(postinfo[1]).fill(null);
        });
        console.log(droppedvideoref.current);
      } else if (posts[deletetarget - 1]?.Directory === id) {
        let finaladdingdoc = await getreplacementdrop();
        let postinfo = finaladdingdoc
          ? [[finaladdingdoc], posts.length]
          : [[], posts.length - 1];
        console.log(postinfo);
        setPosts([
          ...posts.slice(0, deletetarget),
          ...postinfo[0],
          ...posts.slice(deletetarget + 1),
        ]);
        setTimeout(() => {
          droppedvideoref.current = new Array(postinfo[1]).fill(null);
        });
      } else {
        setPosts([
          ...posts.slice(0, deletetarget),
          // ...[],
          ...posts.slice(deletetarget + 1),
        ]);
        setTimeout(() => {
          droppedvideoref.current = new Array(posts.length - 1).fill(null);
        });
      }
    }

    if (alldisplay.length === 1) {
      setAlldisplay([]);
      setSimdisplays([]);
      setShowdrop(true);
    }
    if (clickedcomment) {
      setClickedcomment("");
      setShowdrop(true);
    }

    console.log(posts[deletetarget]);
    if (posts[deletetarget].file1.path) {
      let deletefilelist = [
        ...(posts[deletetarget].file1.path
          ? [posts[deletetarget].file1.path]
          : []),
        ...(posts[deletetarget].file2.path
          ? [posts[deletetarget].file2.path]
          : []),
        ...(posts[deletetarget].file3.path
          ? [posts[deletetarget].file3.path]
          : []),
        ...(posts[deletetarget].file4.path
          ? [posts[deletetarget].file4.path]
          : []),
      ];

      console.log(deletefilelist);

      const storage = getStorage();
      deletefilelist.forEach((file) => {
        let oldRef = ref(storage, file);
        console.log("How many times");
        deleteObject(oldRef);
      });
    }

    const userDocumentRef = doc(db, "users", id, "Drops", optionid[0]);
    await deleteDoc(userDocumentRef);
    const dropsRef = query(
      collectionGroup(db, "Drops"),
      where("Redrop_id", "==", optionid[0])
    );
    getDocs(dropsRef).then((querySnapshot) => {
      let posts = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      let batchArray = [];
      batchArray.push(writeBatch(db));
      let batchCount = 0;
      let batchIndex = 0;
      posts.forEach((item) => {
        const userDocumentRef = doc(
          db,
          "users",
          item.Redropper_id,
          "Drops",
          item.id
        );
        batchArray[batchIndex].delete(userDocumentRef);
        batchCount++;
        if (batchCount === 499) {
          batchArray.push(writeBatch(db));
          batchIndex++;
          batchCount = 0;
        }
      });

      batchArray.forEach(async (batch) => await batch.commit());
    });

    setTimeout(() => {
      setVideorefload(!videorefload);
    }, 500);
  };

  const fileedit = async (e) => {
    for (let step = 0; step < e.target.files.length; step++) {
      if (
        !e.target.files[step]?.type.includes("video") &&
        !e.target.files[step]?.type.includes("image")
      ) {
        console.log(e.target.files[step]);
        alert("ファイルは動画か写真にしましょう");
        return (e.target.value = "");
      }
    }
    console.log("This better not run");
    //.type.includes("video")
    if (tempfiles.length + e.target.files.length < 5) {
      let filenumber = 0;
      let finaltempfiles = [];
      let promises = [];

      for (let step = 0; step < e.target.files.length; step++) {
        promises.push(
          await new Promise((resolve, reject) => {
            let fileurl = window.URL.createObjectURL(
              e.target.files[filenumber]
            );
            console.log(fileurl);
            finaltempfiles.push({
              file: fileurl,
              id: e.target.files[filenumber].name + new Date().getTime(),
              realfile: e.target.files[filenumber],
            });

            if (e.target.files[filenumber].type.includes("video")) {
              let videotime = document.createElement("video");
              videotime.muted = true;

              console.log(videotime);

              let videotimesource = document.createElement("source");
              videotimesource.src = fileurl;
              videotime.preload = "metadata";
              videotime.appendChild(videotimesource);

              videotime.onloadedmetadata = () => {
                console.log(Math.floor(videotime.duration));
                console.log(twominutevideo);
                if (
                  videotime.duration > 120 ||
                  twominutevideo + Math.floor(videotime.duration) > 120
                ) {
                  URL.revokeObjectURL(fileurl);
                  alert(
                    "動画は２分以内でなければいけません、２分以上の動画はE-Stationに投稿しましょう"
                  );
                  e.target.value = "";
                  reject("over");
                } else {
                  console.log(finaltempfiles);
                  filenumber++;
                  setTwominutevideo(
                    (prevvalue) => prevvalue + Math.floor(videotime.duration)
                  );

                  resolve();
                }
              };
            } else {
              console.log(finaltempfiles);
              filenumber++;
              resolve();
            }
          })
        );
      }
      try {
        await promises[0];
        await promises[1];
        await promises[2];
        await promises[3];

        setTempfiles([...tempfiles, ...finaltempfiles]);

        setTimeout(() => {
          e.target.value = "";
          if (tempfiles.length >= 1) {
            setShowtime(true);
          }
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      alert("ファイルは４つまでです");
      e.target.value = "";
    }
  };

  const filedelete = (e) => {
    console.log(twominutevideo);
    if (e.target.id === tempfiles[0].id) {
      if (tempfiles.length === 1) {
        setTempfiles([]);
      } else {
        setTempfiles([...tempfiles.slice(1)]);
      }
      URL.revokeObjectURL(tempfiles[0].file);
      if (tempfiles[0].realfile.type.includes("video")) {
        setTwominutevideo(
          (prevvalue) =>
            prevvalue -
            Math.floor(
              ref3.current[0].children[0].children[0].children[0].duration
            )
        );
        setShowtime(true);
      }
    } else {
      let deletetarget = tempfiles.findIndex(
        (element) => e.target.id === element.id
      );
      setTimeout(() => {
        setTempfiles([
          ...tempfiles.slice(0, deletetarget),
          ...tempfiles.slice(deletetarget + 1),
        ]);
      });
      URL.revokeObjectURL(tempfiles[deletetarget].file);
      if (tempfiles[deletetarget].realfile.type.includes("video")) {
        setTwominutevideo(
          (prevvalue) =>
            prevvalue -
            Math.floor(
              ref3.current[deletetarget].children[0].children[0].children[0]
                .duration
            )
        );
        setShowtime(true);
      }
    }
  };

  const fullscreen = (e) => {
    if (!fullscreened) {
      console.log(e.target.id);
      console.log(e.detail);

      let matchtarget = tempfiles.findIndex(
        (element) => e.target.id === element.id
      );
      console.log(matchtarget);
      setFullscreened(true);
      ref3.current[matchtarget].requestFullscreen();

      if (
        ref3.current[matchtarget].children[0].children[0].children[0].paused
      ) {
        setPaused(true);
      } else {
        setPaused(false);
      }
    } else if (!controlon) {
      setControlon(true);

      setVideotime(
        Math.floor(
          document.fullscreenElement.children[0].children[0].children[1]
            .currentTime
        )
      );

      setVideolocation(3);

      setShowtime(true);
    } else {
      setControlon(false);
    }
  };

  const Fullscreencancel = () => {
    setFullscreened(false);
    setControlon(false);
    document.exitFullscreen();

    setShowtime(true);
  };

  const fullscreenside = (e) => {
    let clickcount = Clicker();
    if (clickcount === "Once") {
      console.log("YOONE");

      if (!controlon) {
        setControlon(true);
        setVideolocation(3);

        setVideotime(
          Math.floor(
            document.fullscreenElement.children[0].children[0].children[1]
              .currentTime
          )
        );

        setTimeout(() => {
          setVideolocation(4);
        }, 100);
      } else {
        setControlon(false);
      }
    }

    if (clickcount === "Twice") {
      console.log(e.target.id.split(","));
      let tempid = e.target.id.split(",");
      let matchtarget = tempfiles.findIndex(
        (element) => tempid[0] === element.id
      );
      console.log(matchtarget);
      console.log(tempid[1]);
      console.log("YOTWO");
      console.log("durationaction");

      let timeadjustnumber;
      if (tempid[1] === "left") {
        timeadjustnumber = 5;
      } else {
        timeadjustnumber = -5;
      }

      if (!controlon) {
        ref3.current[
          matchtarget
        ].children[0].children[0].children[1].currentTime =
          Math.floor(
            ref3.current[matchtarget].children[0].children[0].children[1]
              .currentTime
          ) - timeadjustnumber;
        console.log("first");
      } else {
        ref3.current[
          matchtarget
        ].children[0].children[0].children[4].currentTime =
          Math.floor(
            ref3.current[matchtarget].children[0].children[0].children[4]
              .currentTime
          ) - timeadjustnumber;
        console.log("two");
      }
    }
  };

  const Videotimechange = (e) => {
    console.log(e.target.value);
    let matchtarget = tempfiles.findIndex(
      (element) => e.target.id === element.id
    );

    let timeadjust = parseInt(e.target.value) - videotime;

    setVideotime(parseInt(e.target.value));
    setTimeout(() => {
      ref3.current[
        matchtarget
      ].children[0].children[0].children[4].currentTime =
        Math.floor(
          ref3.current[matchtarget].children[0].children[0].children[4]
            .currentTime
        ) + timeadjust;
    }, 100);
    setVideolocation(4);
    setShowtime(true);
  };

  const Videotimeupdate = (e) => {
    console.log(e.target.id);

    if (controlon || !fullscreened) setShowtime(true); //setTimeout(()=>{setShowtime(true)},100/*200*/);

    let fullscreenedid;

    if (controlon) {
      fullscreenedid = document.fullscreenElement?.children[0].children[0]
        .children[4]?.id
        ? document.fullscreenElement.children[0].children[0].children[4].id
        : "";

      if (e.target.id === fullscreenedid) {
        setVideotime(
          Math.floor(
            document.fullscreenElement.children[0].children[0].children[4]
              .currentTime
          )
        );
      }
    }
  };

  const Droppedvideotimeupdate = (e, index) => {
    console.log(e.target.id);
    console.log(index);
    console.log(droppedvideoref.current[index]);
    console.log(droppedvideoref.current[index].length);
    console.log(droppedvideoref.current[index][0].id);

    if (Array.isArray(droppedvideoref.current[index])) {
      //this finds which video is being played right now
      let playtarget = droppedvideoref.current[index].findIndex(
        (element) => element.id === e.target.id
      );
      console.log(playtarget);
      console.log(playtarget !== droppedvideoref.current[index].length - 1);
      if (playtarget !== droppedvideoref.current[index].length - 1) {
        droppedvideoref.current[index][playtarget + 1].play();
      } else {
        droppedvideoref.current[index][0].play();
      }
    } else {
      droppedvideoref.current[index].play();
    }

    //put the video to currentime 0?
  };

  const imagehtml = (post, post1, post2) => {
    const images = {
      width: 0,
      height: 0,
      marginLeft: 0,
      marginTop: 0,
    };
    //if (post1.id === ){}

    images.width = post2?.path
      ? 130
      : post2?.path !== "" && post2?.path !== undefined
      ? 110
      : ((post1?.path !== "" && post1?.path !== undefined) ||
          post?.path !== "") &&
        150;
    images.height =
      post2?.path !== "" && post2?.path !== undefined
        ? 95
        : post1?.path !== "" && post2?.path === ""
        ? 195
        : post?.path !== "" && 150;

    images.marginLeft =
      post1?.path !== "" && post2?.path === "" ? 20 : post?.path !== "" && 5;
    images.marginTop = post?.path !== "" && 20;

    return images;
  };

  const videohtml = (post, post1, post2) => {
    /*
      controls: tempfiles.length < 2 && true,
      width: fullscreened ? "100%" : tempfiles.length === 1 ? 200 : 175,
      height: fullscreened ? "40%" : tempfiles.length === 1 ? "200" : 175,
      playsInline,
      autoPlay,
      muted,
      loop,
      */

    const videos = {
      objectFit: "cover",
      borderRadius: 20,
      marginLeft:
        !fullscreened && post1?.path !== "" && post2?.path === ""
          ? 20
          : post?.path && !fullscreened && 5,
      marginTop: post?.path !== "" && 20,
      position: fullscreened && "absolute",
      top: fullscreened && "30%",
    };
    return videos;
  };

  const determinevideoref = (post, post1, post2, index) => {
    let finalvideos = [];
    let num = 0;

    let videoornot = [];

    post === "videos" && videoornot.push("videos");
    post1 === "videos" && videoornot.push("videos");
    post2 === "videos" && videoornot.push("videos");

    if (videoornot.length === 1) {
      finalvideos[0] = droppedvideoref.current[index];
    } else {
      for (const video of videoornot) {
        if (video === "videos") {
          finalvideos.push(droppedvideoref.current[index][num]);
          num++;
        }
      }
    }
    return finalvideos;
  };

  const determinevideoindex = (file1, file2, file4, index) => {
    if (file1 === "videos" && file2 === "videos")
      return droppedvideoref.current[index][2];
    else if (file1 === "videos" || file2 === "videos")
      return droppedvideoref.current[index][1];
    else if (file4 === "videos") return droppedvideoref.current[index][0];
    else return droppedvideoref.current[index];
  };

  const determinefourthvideoindex = (file1, file2, file3, index) => {
    let allfiles = [file1, file2, file3];
    let num = 0;
    for (const file of allfiles) {
      if (file === "videos") num++;
    }
    if (num > 0) return droppedvideoref.current[index][num];
    else return droppedvideoref.current[index];
  };

  return (
    <div>
      {promotion && location.state !== "SignedUp" ? (
        <div>
          <FullScreenPromotion />
        </div>
      ) : (
        <>
          <div
            style={{
              width: "100%",
              height: 56,
              display: "block",
              background: "#005280",
            }}
          ></div>

          <div className="topline">
            {(alldisplay.length > 0 || clickedcomment) && (
              <img
                style={{ zIndex: 200, marginLeft: 5 }}
                src="/Arrow1.svg"
                onClick={clickedcomment ? commentcancel : displayback}
              />
            )}

            {alldisplay.length === 0 && !clickedcomment && (
              <>
                <Link to={`/User/${id}`}>
                  <img
                    style={{
                      marginLeft: 5,
                      marginTop: 2,
                      background: "linear-gradient(#121112, #42b1e0)",
                    }}
                    className="current"
                    src={userinfo.Profile_image}
                  />
                </Link>

                <img
                  src="./Planet1.svg"
                  className="Planet"
                  style={{ width: 30 }}
                />
              </>
            )}

            <img src="/Connect_icon.svg" style={{ height: 50 }} />

            {alldisplay.length === 0 && !clickedcomment && (
              <img
                src="./DropView.svg"
                className="dropb"
                style={{
                  width: 28,
                }}
                onClick={turn}
              />
            )}

            <img
              style={{ width: 40, height: 50, marginRight: 5 }}
              src="./option.svg"
            />
          </div>
          {dropped ? (
            <>
              <textarea
                className="drop"
                placeholder="ドロップを投稿しましょう..."
                value={droptext}
                onChange={(e) => setDroptext(e.target.value)}
              />
              <div
                style={{ justifyContent: tempfiles.length > 2 && "normal" }}
                className="imagecontainer"
              >
                {tempfiles.map((tempfile, index) => (
                  <div
                    ref={(element) => (ref3.current[index] = element)}
                    key={tempfile.id}
                  >
                    <div>
                      {tempfile.realfile.type.includes("image") && (
                        <img
                          className="dropfile"
                          style={{
                            marginLeft: tempfiles.length > 1 && 10,
                            marginRight:
                              (tempfiles.length === 2 &&
                                tempfile.id ===
                                  tempfiles[tempfiles.length - 1].id &&
                                13) ||
                              (tempfiles.length > 2 &&
                                tempfile.id ===
                                  tempfiles[tempfiles.length - 1].id &&
                                10),
                            width: tempfiles.length > 1 && 175,
                            height: tempfiles.length > 1 && 175,
                          }}
                          src={tempfile.file}
                        />
                      )}
                      {tempfile.realfile.type.includes("video") && (
                        <>
                          <span>
                            {controlon && (
                              <>
                                <img
                                  style={{
                                    zIndex: 200,
                                    marginLeft: 5,
                                    position: "absolute",
                                    top: 5,
                                  }}
                                  src="/Arrow1.svg"
                                  onClick={Fullscreencancel}
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {!ref3.current[index]?.children[0]
                                    ?.children[0]?.children[0]?.paused &&
                                  !paused ? (
                                    <img
                                      style={{
                                        zIndex: 200,

                                        position: "absolute",
                                        top: "46%",

                                        width: 15,
                                      }}
                                      src="/ConnectPause.svg"
                                      onClick={(e) => {
                                        setPaused(true);
                                        ref3.current[
                                          index
                                        ]?.children[0]?.children[0].children[4]?.pause();
                                      }}
                                    />
                                  ) : (
                                    <img
                                      style={{
                                        zIndex: 200,

                                        position: "absolute",
                                        top: "46%",

                                        width: 50,
                                      }}
                                      src="/ConnectPlay.svg"
                                      onClick={(e) => {
                                        ref3.current[
                                          index
                                        ].children[0].children[0].children[4].play();
                                        setPaused(false);
                                        setVideolocation(4);
                                      }}
                                    />
                                  )}
                                </div>
                                {(!isNaN(
                                  ref3.current[index]?.children[0].children[0]
                                    .children[3]?.duration
                                ) ||
                                  !isNaN(
                                    ref3.current[index]?.children[0].children[0]
                                      .children[4]?.duration
                                  )) && (
                                  <div>
                                    <span
                                      style={{ bottom: "-430px", zIndex: 1 }}
                                      className="videoduration"
                                    >
                                      {0 +
                                        Math.floor(
                                          ref3.current[index]?.children[0]
                                            .children[0].children[videolocation]
                                            ?.currentTime / 60
                                        )}
                                      :
                                      {ref3.current[index]?.children[0]
                                        .children[0].children[videolocation]
                                        ?.currentTime < 10 && 0}
                                      {ref3.current[index]?.children[0]
                                        .children[0].children[videolocation]
                                        ?.currentTime >= 60
                                        ? Math.floor(
                                            ref3.current[index]?.children[0]
                                              .children[0].children[
                                              videolocation
                                            ]?.currentTime % 60
                                          )
                                        : Math.floor(
                                            ref3.current[index]?.children[0]
                                              .children[0].children[
                                              videolocation
                                            ]?.currentTime
                                          )}
                                      <span
                                        style={{
                                          marginLeft: 5,
                                          marginRight: 5,
                                        }}
                                      >
                                        /
                                      </span>
                                      {0 +
                                        Math.floor(
                                          ref3.current[index]?.children[0]
                                            .children[0].children[videolocation]
                                            ?.duration / 60
                                        )}
                                      :
                                      {ref3.current[index]?.children[0]
                                        .children[0].children[videolocation]
                                        ?.duration < 10 && 0}
                                      {ref3.current[index]?.children[0]
                                        .children[0].children[videolocation]
                                        ?.duration >= 60
                                        ? Math.floor(
                                            ref3.current[index]?.children[0]
                                              .children[0].children[
                                              videolocation
                                            ]?.duration % 60
                                          )
                                        : Math.floor(
                                            ref3.current[index]?.children[0]
                                              .children[0].children[
                                              videolocation
                                            ]?.duration
                                          )}
                                    </span>
                                    <input
                                      type="range"
                                      style={{
                                        width: "100%",
                                        position: "relative",
                                        bottom: "-440px",
                                        zIndex: 1,
                                      }}
                                      min="0"
                                      max={Math.floor(
                                        ref3.current[index]?.children[0]
                                          .children[0].children[videolocation]
                                          ?.duration
                                      )}
                                      value={videotime}
                                      onChange={Videotimechange}
                                      id={tempfile.id}
                                    ></input>
                                  </div>
                                )}
                              </>
                            )}
                            {fullscreened && (
                              <div
                                style={{
                                  display: "flex",
                                }}
                                id={tempfile.id}
                              >
                                <div
                                  className="fullscreencontrol"
                                  id={[tempfile.id, "left"]}
                                  onClick={fullscreenside}
                                ></div>
                                <div
                                  style={{ right: 0 }}
                                  className="fullscreencontrol"
                                  id={[tempfile.id, "right"]}
                                  onClick={fullscreenside}
                                ></div>
                              </div>
                            )}
                            <video
                              controls={tempfiles.length < 2 && true}
                              width={
                                fullscreened
                                  ? "100%"
                                  : tempfiles.length === 1
                                  ? 200
                                  : 175
                              }
                              height={
                                fullscreened
                                  ? "40%"
                                  : tempfiles.length === 1
                                  ? 200
                                  : 175
                              }
                              playsInline
                              autoPlay
                              muted
                              loop
                              id={tempfile.id}
                              onClick={
                                tempfiles.length > 1 ? fullscreen : undefined
                              }
                              onTimeUpdate={
                                tempfiles.length > 1
                                  ? Videotimeupdate
                                  : undefined
                              }
                              style={{
                                objectFit: "cover",
                                borderRadius: 20,
                                marginLeft:
                                  tempfiles.length > 1 && !fullscreened && 10,
                                marginRight:
                                  (tempfiles.length === 2 &&
                                    tempfile.id ===
                                      tempfiles[tempfiles.length - 1].id &&
                                    !fullscreened &&
                                    13) ||
                                  (tempfiles.length > 2 &&
                                    tempfile.id ===
                                      tempfiles[tempfiles.length - 1].id &&
                                    !fullscreened &&
                                    10),
                                position: fullscreened && "absolute",
                                top: fullscreened && "30%",
                              }}
                            >
                              <source src={tempfile.file} type="video/mp4" />
                            </video>
                            {tempfiles.length > 1 &&
                              tempfile.realfile.type.includes("video") &&
                              !fullscreened &&
                              (!isNaN(
                                ref3.current[tempfiles.length - 1]?.children[0]
                                  .children[0].children[0]?.duration
                              ) ||
                                tempfiles[
                                  tempfiles.length - 1
                                ].realfile.type.includes("image")) && (
                                <span className="videoduration">
                                  {0 +
                                    Math.floor(
                                      ref3.current[index]?.children[0]
                                        .children[0].children[0].currentTime /
                                        60
                                    )}
                                  :
                                  {ref3.current[index]?.children[0].children[0]
                                    .children[0].currentTime < 10 && 0}
                                  {ref3.current[index]?.children[0].children[0]
                                    .children[0].currentTime >= 60
                                    ? Math.floor(
                                        ref3.current[index]?.children[0]
                                          .children[0].children[0].currentTime %
                                          60
                                      )
                                    : Math.floor(
                                        ref3.current[index]?.children[0]
                                          .children[0].children[0].currentTime
                                      )}
                                  <span
                                    style={{ marginLeft: 5, marginRight: 5 }}
                                  >
                                    /
                                  </span>
                                  {0 +
                                    Math.floor(
                                      ref3.current[index]?.children[0]
                                        .children[0].children[0].duration / 60
                                    )}
                                  :
                                  {ref3.current[index]?.children[0].children[0]
                                    .children[0].duration < 10 && 0}
                                  {ref3.current[index]?.children[0].children[0]
                                    .children[0].duration >= 60
                                    ? Math.floor(
                                        ref3.current[index]?.children[0]
                                          .children[0].children[0].duration % 60
                                      )
                                    : Math.floor(
                                        ref3.current[index]?.children[0]
                                          .children[0].children[0].duration
                                      )}
                                </span>
                              )}
                          </span>
                        </>
                      )}

                      {(tempfiles[tempfiles.length - 1].realfile.type.includes(
                        "image"
                      ) ||
                        !isNaN(
                          ref3.current[tempfiles.length - 1]?.children[0]
                            .children[0].children[0].duration
                        ) ||
                        tempfiles.length === 1) && (
                        <img
                          style={{
                            width: 25,
                            position: "relative",
                            left:
                              (tempfiles.length === 1 && -35) ||
                              (tempfiles.length > 1 &&
                                tempfile.realfile.type.includes("video") &&
                                95) ||
                              (tempfiles.length > 1 && 150),

                            bottom:
                              (tempfiles.length === 1 && 170) ||
                              (tempfiles.length > 1 && 173),
                          }}
                          src="/Xout.svg"
                          onClick={filedelete}
                          id={tempfile.id}
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <div className="ProgressbarPosition">
                <Progressbar percent={progresspercent} />
              </div>
              <div className="dropselect">
                <img className="toggle" src="./Tog1.svg" onClick={turn} />

                <label>
                  <img className="camera" src="./Photo.svg" />
                  <input
                    className="upload"
                    type="file"
                    multiple="multiple"
                    onChange={fileedit}
                  />
                </label>

                <img
                  src="./DropView.svg"
                  className="dropb1"
                  onClick={
                    (droptext.length > 0 || tempfiles[0]) && !disablepress //dropref.current
                      ? droppy
                      : undefined
                  }
                />
              </div>
            </>
          ) : (
            <div>
              {showdrop ? (
                <>
                  {posts.map((post, index) => (
                    <div
                      className="drops"
                      key={post.id}
                      ref={
                        (clickedcomment.id === post.id && scrollref) ||
                        alldisplay[0]?.id === post.id
                          ? scrollref
                          : null
                      }
                      style={{
                        height:
                          (post.image2 && 310) ||
                          ((post.image1 ||
                            (post.file1?.path !== "" &&
                              post.file1?.path !== undefined)) &&
                            330),
                      }}
                    >
                      <div className="postlinetop">
                        <Link to={`/UserView/${post.Directory}`}>
                          <img
                            className={
                              post.Profile_image === "/Profile3.svg"
                                ? "current3"
                                : "current1"
                            }
                            src={post.Profile_image}
                          />
                        </Link>

                        {(post.image1 || post.file1?.path) && (
                          <div
                            style={{
                              fontSize: post.text.length >= 142 && 10.5,
                              //width: post.text.length >= 142 && "80%",
                              //marginTop: post.text.length >= 142 && 8,
                              position: optionid[0] === post.id && "absolute",
                              left:
                                optionid[0] === post.id &&
                                post.text.length >= 23
                                  ? "20%"
                                  : `${49.5 - post.text.length}%`,
                            }}
                            className={
                              post.text.length >= 23 ? "text" : "text1"
                            }
                          >
                            {post.text}
                          </div>
                        )}
                        {optionid[0] !== post.id && (
                          <img
                            className="postoption"
                            src="/DropOption.svg"
                            id={[post.id, post.Directory]}
                            onClick={OptionTerminal}
                          />
                        )}

                        {!deleteid && optionid[0] === post.id && (
                          <span className="Delete">
                            {id === optionid[1] && (
                              <button
                                id={post.id}
                                onClick={(e) => setDeleteid(post.id)}
                              >
                                削除?
                              </button>
                            )}
                            <button
                              onClick={(e) => {
                                setOptionId("");
                                setDeleteid("");
                              }}
                            >
                              キャンセル
                            </button>
                          </span>
                        )}

                        {deleteid === post.id && (
                          <span className="Delete">
                            <button id={post.id} onClick={deletedrop}>
                              〇
                            </button>
                            <button onClick={(e) => setDeleteid("")}>X</button>
                          </span>
                        )}
                      </div>

                      <span className="textline">
                        <span
                          className="stylerank"
                          style={{
                            marginLeft:
                              post.Rank <= 9
                                ? 19.4
                                : post.Rank === 11
                                ? 16
                                : post.Rank <= 99
                                ? 14.5
                                : post.Rank > 99 && 10,
                            flex:
                              post.text.length >= 25
                                ? 0.18
                                : post.text.length === 20
                                ? 0.14
                                : 0.5,
                            marginTop:
                              post.text.length > 65 &&
                              (post.image1 || post.file1?.path)
                                ? -25
                                : null,
                          }}
                        >
                          {post.Rank}
                        </span>
                        {!post.image1 && !post.file1?.path && (
                          <div
                            className={
                              post.text.length >= 23 ? "text" : "text1"
                            }
                          >
                            {post.text}
                          </div>
                        )}
                      </span>

                      <div
                        className="alldrops"
                        //id={[post.id, post.Directory, post.Comment_Counter]}
                        onClick={
                          blockid !== post.id ? (e) => display(post) : undefined
                        }
                        style={{
                          height:
                            (post.image2 && 275) ||
                            ((post.image1 || post.file1?.path) && 295),
                        }}
                      ></div>

                      <div
                        style={{
                          marginLeft:
                            !post.file3?.path && post.file2?.path
                              ? 40
                              : post.file3?.path && 60,
                          marginTop: post.file3?.path && -10,
                          justifyContent:
                            post.file3?.path &&
                            !post.file4?.path &&
                            "flex-start",
                        }}
                        className="postphotoline"
                      >
                        {post.file1?.filetype === "videos" ? (
                          <>
                            <video
                              controls={!post.file2.path && true}
                              width={
                                fullscreened
                                  ? "100%"
                                  : post.file2.path === ""
                                  ? 200
                                  : post.file4.path !== ""
                                  ? 130
                                  : 150
                              }
                              height={
                                fullscreened
                                  ? "40%"
                                  : post.file2.path === ""
                                  ? 200
                                  : post.file4?.path !== ""
                                  ? 95
                                  : post.file3.path !== ""
                                  ? 195
                                  : 150
                              }
                              ref={(element) =>
                                (droppedvideoref.current[index] = element)
                              }
                              onTimeUpdate={Videotimeupdate}
                              onEnded={
                                post.file2.path
                                  ? (e) => Droppedvideotimeupdate(e, index)
                                  : undefined
                              }
                              playsInline
                              muted
                              loop={!post.file2.path && true}
                              // loop={post.file2.filetype !== "videos" &&  post.file3.filetype !== "videos" && post.file4.filetype !== "videos" && true}

                              id={post.file1.id}
                              style={videohtml(
                                post?.file2,
                                post.file3,
                                post.file4
                              )}
                            >
                              <source type="video/mp4" src={post.file1?.path} />
                            </video>
                            {console.log(droppedvideoref.current)}
                            {!isNaN(
                              Array.isArray(droppedvideoref.current[index])
                                ? droppedvideoref.current[index][0]?.duration
                                : droppedvideoref.current[index]?.duration
                            ) && (
                              <span
                                //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                style={{
                                  bottom: !post.file2.filetype
                                    ? "18%"
                                    : !post.file3.filetype
                                    ? "27%"
                                    : post.file4.filetype
                                    ? "46%"
                                    : "16%",
                                  left:
                                    !post.file2.filetype || post.file4.filetype
                                      ? "25%"
                                      : !post.file3.filetype
                                      ? "17%"
                                      : "23%",
                                  zIndex: 1,
                                  position: "absolute",
                                }}
                                className="videoduration"
                              >
                                {0 +
                                  Math.floor(
                                    (Array.isArray(
                                      droppedvideoref.current[index]
                                    )
                                      ? droppedvideoref.current[index][0]
                                      : droppedvideoref.current[index]
                                    )?.currentTime / 60
                                  )}
                                :
                                {(Array.isArray(droppedvideoref.current[index])
                                  ? droppedvideoref.current[index][0]
                                  : droppedvideoref.current[index]
                                )?.currentTime < 10 && 0}
                                {(Array.isArray(droppedvideoref.current[index])
                                  ? droppedvideoref.current[index][0]
                                  : droppedvideoref.current[index]
                                )?.currentTime >= 60
                                  ? Math.floor(
                                      (Array.isArray(
                                        droppedvideoref.current[index]
                                      )
                                        ? droppedvideoref.current[index][0]
                                        : droppedvideoref.current[index]
                                      )?.currentTime % 60
                                    )
                                  : Math.floor(
                                      (Array.isArray(
                                        droppedvideoref.current[index]
                                      )
                                        ? droppedvideoref.current[index][0]
                                        : droppedvideoref.current[index]
                                      )?.currentTime
                                    )}
                                <span style={{ marginLeft: 5, marginRight: 5 }}>
                                  /
                                </span>
                                {0 +
                                  Math.floor(
                                    (Array.isArray(
                                      droppedvideoref.current[index]
                                    )
                                      ? droppedvideoref.current[index][0]
                                      : droppedvideoref.current[index]
                                    )?.duration / 60
                                  )}
                                :
                                {(Array.isArray(droppedvideoref.current[index])
                                  ? droppedvideoref.current[index][0]
                                  : droppedvideoref.current[index]
                                )?.duration < 10 && 0}
                                {(Array.isArray(droppedvideoref.current[index])
                                  ? droppedvideoref.current[index][0]
                                  : droppedvideoref.current[index]
                                )?.duration >= 60
                                  ? Math.floor(
                                      (Array.isArray(
                                        droppedvideoref.current[index]
                                      )
                                        ? droppedvideoref.current[index][0]
                                        : droppedvideoref.current[index]
                                      )?.duration % 60
                                    )
                                  : Math.floor(
                                      (Array.isArray(
                                        droppedvideoref.current[index]
                                      )
                                        ? droppedvideoref.current[index][0]
                                        : droppedvideoref.current[index]
                                      )?.duration
                                    )}
                              </span>
                            )}
                          </>
                        ) : (
                          post.file1?.filetype === "images" && (
                            <img
                              className="postphoto"
                              src={post.file1?.path}
                              id={post.file1?.id}
                              style={imagehtml(
                                post.file2,
                                post.file3,
                                post.file4
                              )}
                            />
                          )
                        )}

                        {!post.file4?.path ? (
                          <div
                            style={{
                              display:
                                post.file3?.path && !post.file4?.path && "flex",
                              flexDirection:
                                post.file3?.path &&
                                !post.file4?.path &&
                                "column",
                            }}
                          >
                            {post.file2?.filetype === "videos" ? (
                              <>
                                {console.log(droppedvideoref.current)}
                                <video
                                  width={
                                    fullscreened
                                      ? "100%"
                                      : post.file3?.path
                                      ? 110
                                      : 150
                                  }
                                  height={
                                    fullscreened
                                      ? "40%"
                                      : post.file3?.path
                                      ? 95
                                      : 150
                                  }
                                  ref={(element) =>
                                    (droppedvideoref.current[index] =
                                      post.file1.filetype === "videos"
                                        ? [
                                            droppedvideoref.current[index],
                                            element,
                                          ]
                                        : element)
                                  }
                                  playsInline
                                  muted
                                  id={post.file2.id}
                                  style={videohtml(post.file1)}
                                  onTimeUpdate={Videotimeupdate}
                                  onEnded={(e) =>
                                    Droppedvideotimeupdate(e, index)
                                  }
                                >
                                  <source
                                    type="video/mp4"
                                    src={post.file2?.path}
                                  />
                                </video>

                                {droppedvideoref?.current[index] !== null &&
                                  !isNaN(
                                    Array.isArray(
                                      droppedvideoref.current[index]
                                    )
                                      ? droppedvideoref.current[index][0]
                                          ?.duration
                                      : droppedvideoref.current[index]?.duration
                                  ) && (
                                    <span
                                      //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                      style={{
                                        bottom: !post.file3.filetype
                                          ? "27%"
                                          : "46%",
                                        left: !post.file3.filetype
                                          ? "59%"
                                          : "65%",
                                        zIndex: 1,
                                        position: "absolute",
                                      }}
                                      className="videoduration"
                                    >
                                      {0 +
                                        Math.floor(
                                          (post.file1.filetype === "videos"
                                            ? droppedvideoref?.current[index][1]
                                            : Array.isArray(
                                                droppedvideoref.current[index]
                                              )
                                            ? droppedvideoref?.current[index][0]
                                            : droppedvideoref?.current[index]
                                          )?.currentTime / 60
                                        )}
                                      :
                                      {(post.file1.filetype === "videos"
                                        ? droppedvideoref?.current[index][1]
                                        : Array.isArray(
                                            droppedvideoref.current[index]
                                          )
                                        ? droppedvideoref?.current[index][0]
                                        : droppedvideoref?.current[index]
                                      )?.currentTime < 10 && 0}
                                      {(post.file1.filetype === "videos"
                                        ? droppedvideoref?.current[index][1]
                                        : Array.isArray(
                                            droppedvideoref.current[index]
                                          )
                                        ? droppedvideoref?.current[index][0]
                                        : droppedvideoref?.current[index]
                                      )?.currentTime >= 60
                                        ? Math.floor(
                                            (post.file1.filetype === "videos"
                                              ? droppedvideoref?.current[
                                                  index
                                                ][1]
                                              : Array.isArray(
                                                  droppedvideoref.current[index]
                                                )
                                              ? droppedvideoref?.current[
                                                  index
                                                ][0]
                                              : droppedvideoref?.current[index]
                                            )?.currentTime % 60
                                          )
                                        : Math.floor(
                                            (post.file1.filetype === "videos"
                                              ? droppedvideoref?.current[
                                                  index
                                                ][1]
                                              : Array.isArray(
                                                  droppedvideoref.current[index]
                                                )
                                              ? droppedvideoref?.current[
                                                  index
                                                ][0]
                                              : droppedvideoref?.current[index]
                                            )?.currentTime
                                          )}
                                      <span
                                        style={{
                                          marginLeft: 5,
                                          marginRight: 5,
                                        }}
                                      >
                                        /
                                      </span>
                                      {0 +
                                        Math.floor(
                                          (post.file1.filetype === "videos"
                                            ? droppedvideoref?.current[index][1]
                                            : Array.isArray(
                                                droppedvideoref.current[index]
                                              )
                                            ? droppedvideoref?.current[index][0]
                                            : droppedvideoref?.current[index]
                                          )?.duration / 60
                                        )}
                                      :
                                      {(post.file1.filetype === "videos"
                                        ? droppedvideoref?.current[index][1]
                                        : Array.isArray(
                                            droppedvideoref.current[index]
                                          )
                                        ? droppedvideoref?.current[index][0]
                                        : droppedvideoref?.current[index]
                                      )?.duration < 10 && 0}
                                      {(post.file1.filetype === "videos"
                                        ? droppedvideoref?.current[index][1]
                                        : Array.isArray(
                                            droppedvideoref.current[index]
                                          )
                                        ? droppedvideoref?.current[index][0]
                                        : droppedvideoref?.current[index]
                                      )?.duration >= 60
                                        ? Math.floor(
                                            (post.file1.filetype === "videos"
                                              ? droppedvideoref?.current[
                                                  index
                                                ][1]
                                              : Array.isArray(
                                                  droppedvideoref.current[index]
                                                )
                                              ? droppedvideoref?.current[
                                                  index
                                                ][0]
                                              : droppedvideoref?.current[index]
                                            )?.duration % 60
                                          )
                                        : Math.floor(
                                            (post.file1.filetype === "videos"
                                              ? droppedvideoref?.current[
                                                  index
                                                ][1]
                                              : Array.isArray(
                                                  droppedvideoref.current[index]
                                                )
                                              ? droppedvideoref?.current[
                                                  index
                                                ][0]
                                              : droppedvideoref?.current[index]
                                            )?.duration
                                          )}
                                    </span>
                                  )}
                              </>
                            ) : (
                              post.file2?.filetype === "images" && (
                                <img
                                  className="postphoto"
                                  src={post.file2?.path}
                                  id={post.file2?.id}
                                  style={
                                    post.file3?.path
                                      ? {
                                          ...imagehtml(post.file1),
                                          width: 110,
                                          height: post.file3.path && 95,
                                        }
                                      : imagehtml(post.file1)
                                  }
                                />
                              )
                            )}

                            {post.file3?.filetype === "videos" ? (
                              <>
                                <video
                                  width={fullscreened ? "100%" : 110}
                                  height={fullscreened ? "40%" : 95}
                                  ref={(element) =>
                                    (droppedvideoref.current[index] =
                                      post.file1.filetype === "videos" &&
                                      post.file2.filetype === "videos"
                                        ? [
                                            droppedvideoref.current[index][0],
                                            droppedvideoref.current[index][1],
                                            element,
                                          ]
                                        : post.file1.filetype !== "videos" &&
                                          post.file2.filetype !== "videos"
                                        ? element
                                        : post.file1.filetype !== "videos" ||
                                          post.file2.filetype !== "videos"
                                        ? [
                                            droppedvideoref?.current[index],
                                            element,
                                          ]
                                        : [])
                                  }
                                  playsInline
                                  muted
                                  id={post.file3.id}
                                  style={{
                                    ...videohtml(post.file1),
                                    marginTop: 4,
                                  }}
                                  onTimeUpdate={Videotimeupdate}
                                  onEnded={(e) =>
                                    Droppedvideotimeupdate(e, index)
                                  }
                                >
                                  <source
                                    type="video/mp4"
                                    src={post.file3?.path}
                                  />
                                </video>

                                {droppedvideoref?.current[index] !== null &&
                                  !isNaN(
                                    Array.isArray(
                                      droppedvideoref.current[index]
                                    )
                                      ? droppedvideoref.current[index][0]
                                          ?.duration
                                      : droppedvideoref.current[index]?.duration
                                  ) && (
                                    <span
                                      //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                      style={{
                                        bottom: "16%",
                                        left: "65%",
                                        zIndex: 1,
                                        position: "absolute",
                                      }}
                                      className="videoduration"
                                    >
                                      {0 +
                                        Math.floor(
                                          determinevideoindex(
                                            post.file1.filetype,
                                            post.file2.filetype,
                                            post.file4.filetype,
                                            index
                                          )?.currentTime / 60
                                        )}
                                      :
                                      {determinevideoindex(
                                        post.file1.filetype,
                                        post.file2.filetype,
                                        post.file4.filetype,
                                        index
                                      )?.currentTime < 10 && 0}
                                      {determinevideoindex(
                                        post.file1.filetype,
                                        post.file2.filetype,
                                        post.file4.filetype,
                                        index
                                      )?.currentTime >= 60
                                        ? Math.floor(
                                            determinevideoindex(
                                              post.file1.filetype,
                                              post.file2.filetype,
                                              post.file4.filetype,
                                              index
                                            )?.currentTime % 60
                                          )
                                        : Math.floor(
                                            determinevideoindex(
                                              post.file1.filetype,
                                              post.file2.filetype,
                                              post.file4.filetype,
                                              index
                                            )?.currentTime
                                          )}
                                      <span
                                        style={{
                                          marginLeft: 5,
                                          marginRight: 5,
                                        }}
                                      >
                                        /
                                      </span>
                                      {0 +
                                        Math.floor(
                                          determinevideoindex(
                                            post.file1.filetype,
                                            post.file2.filetype,
                                            post.file4.filetype,
                                            index
                                          )?.duration / 60
                                        )}
                                      :
                                      {determinevideoindex(
                                        post.file1.filetype,
                                        post.file2.filetype,
                                        post.file4.filetype,
                                        index
                                      )?.duration < 10 && 0}
                                      {determinevideoindex(
                                        post.file1.filetype,
                                        post.file2.filetype,
                                        post.file4.filetype,
                                        index
                                      )?.duration >= 60
                                        ? Math.floor(
                                            determinevideoindex(
                                              post.file1.filetype,
                                              post.file2.filetype,
                                              post.file4.filetype,
                                              index
                                            )?.duration % 60
                                          )
                                        : Math.floor(
                                            determinevideoindex(
                                              post.file1.filetype,
                                              post.file2.filetype,
                                              post.file4.filetype,
                                              index
                                            )?.duration
                                          )}
                                    </span>
                                  )}
                              </>
                            ) : (
                              post.file3?.filetype === "images" && (
                                <img
                                  className="postphoto"
                                  src={post.file3?.path}
                                  id={post.file3?.id}
                                  style={{
                                    ...imagehtml(post.file1),
                                    width: !post.file4?.path ? 110 : 130,
                                    height: 95,
                                    marginTop: 4,
                                  }}
                                />
                              )
                            )}
                          </div>
                        ) : (
                          <>
                            {post.file2?.filetype === "videos" ? (
                              <>
                                <video
                                  width={
                                    fullscreened
                                      ? "100%"
                                      : post.file4.path !== ""
                                      ? 130
                                      : post.file3.path
                                      ? 110
                                      : 150
                                  }
                                  height={
                                    fullscreened
                                      ? "40%"
                                      : post.file3.path
                                      ? 95
                                      : 150
                                  }
                                  ref={(element) =>
                                    (droppedvideoref.current[index] =
                                      post.file1.filetype === "videos"
                                        ? [
                                            droppedvideoref.current[index],
                                            element,
                                          ]
                                        : element)
                                  }
                                  playsInline
                                  muted
                                  id={post.file2.id}
                                  style={videohtml(post.file1)}
                                  onTimeUpdate={Videotimeupdate}
                                  onEnded={(e) =>
                                    Droppedvideotimeupdate(e, index)
                                  }
                                >
                                  <source
                                    type="video/mp4"
                                    src={post.file2?.path}
                                  />
                                </video>

                                {droppedvideoref?.current[index] !== null &&
                                  !isNaN(
                                    Array.isArray(
                                      droppedvideoref.current[index]
                                    )
                                      ? droppedvideoref.current[index][0]
                                          ?.duration
                                      : droppedvideoref.current[index]?.duration
                                  ) && (
                                    <span
                                      //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                      style={{
                                        bottom: "46%",
                                        left: "62%",

                                        zIndex: 1,
                                        position: "absolute",
                                      }}
                                      className="videoduration"
                                    >
                                      {0 +
                                        Math.floor(
                                          (post.file1.filetype === "videos"
                                            ? droppedvideoref?.current[index][1]
                                            : Array.isArray(
                                                droppedvideoref.current[index]
                                              )
                                            ? droppedvideoref?.current[index][0]
                                            : droppedvideoref?.current[index]
                                          )?.currentTime / 60
                                        )}
                                      :
                                      {(post.file1.filetype === "videos"
                                        ? droppedvideoref?.current[index][1]
                                        : Array.isArray(
                                            droppedvideoref.current[index]
                                          )
                                        ? droppedvideoref?.current[index][0]
                                        : droppedvideoref?.current[index]
                                      )?.currentTime < 10 && 0}
                                      {(post.file1.filetype === "videos"
                                        ? droppedvideoref?.current[index][1]
                                        : Array.isArray(
                                            droppedvideoref.current[index]
                                          )
                                        ? droppedvideoref?.current[index][0]
                                        : droppedvideoref?.current[index]
                                      )?.currentTime >= 60
                                        ? Math.floor(
                                            (post.file1.filetype === "videos"
                                              ? droppedvideoref?.current[
                                                  index
                                                ][1]
                                              : Array.isArray(
                                                  droppedvideoref.current[index]
                                                )
                                              ? droppedvideoref?.current[
                                                  index
                                                ][0]
                                              : droppedvideoref?.current[index]
                                            )?.currentTime % 60
                                          )
                                        : Math.floor(
                                            (post.file1.filetype === "videos"
                                              ? droppedvideoref?.current[
                                                  index
                                                ][1]
                                              : Array.isArray(
                                                  droppedvideoref.current[index]
                                                )
                                              ? droppedvideoref?.current[
                                                  index
                                                ][0]
                                              : droppedvideoref?.current[index]
                                            )?.currentTime
                                          )}
                                      <span
                                        style={{
                                          marginLeft: 5,
                                          marginRight: 5,
                                        }}
                                      >
                                        /
                                      </span>
                                      {0 +
                                        Math.floor(
                                          (post.file1.filetype === "videos"
                                            ? droppedvideoref?.current[index][1]
                                            : Array.isArray(
                                                droppedvideoref.current[index]
                                              )
                                            ? droppedvideoref?.current[index][0]
                                            : droppedvideoref?.current[index]
                                          )?.duration / 60
                                        )}
                                      :
                                      {(post.file1.filetype === "videos"
                                        ? droppedvideoref?.current[index][1]
                                        : Array.isArray(
                                            droppedvideoref.current[index]
                                          )
                                        ? droppedvideoref?.current[index][0]
                                        : droppedvideoref?.current[index]
                                      )?.duration < 10 && 0}
                                      {(post.file1.filetype === "videos"
                                        ? droppedvideoref?.current[index][1]
                                        : Array.isArray(
                                            droppedvideoref.current[index]
                                          )
                                        ? droppedvideoref?.current[index][0]
                                        : droppedvideoref?.current[index]
                                      )?.duration >= 60
                                        ? Math.floor(
                                            (post.file1.filetype === "videos"
                                              ? droppedvideoref?.current[
                                                  index
                                                ][1]
                                              : Array.isArray(
                                                  droppedvideoref.current[index]
                                                )
                                              ? droppedvideoref?.current[
                                                  index
                                                ][0]
                                              : droppedvideoref?.current[index]
                                            )?.duration % 60
                                          )
                                        : Math.floor(
                                            (post.file1.filetype === "videos"
                                              ? droppedvideoref?.current[
                                                  index
                                                ][1]
                                              : Array.isArray(
                                                  droppedvideoref.current[index]
                                                )
                                              ? droppedvideoref?.current[
                                                  index
                                                ][0]
                                              : droppedvideoref?.current[index]
                                            )?.duration
                                          )}
                                    </span>
                                  )}
                              </>
                            ) : (
                              post.file2?.filetype === "images" && (
                                <img
                                  className="postphoto"
                                  src={post.file2?.path}
                                  id={post.file2?.id}
                                  style={
                                    post.file3?.path
                                      ? {
                                          ...imagehtml(post.file1),
                                          width:
                                            (post.file4?.path && 130) ||
                                            (post.file3.path && 110),
                                          height: post.file3.path && 95,
                                        }
                                      : imagehtml(post.file1)
                                  }
                                />
                              )
                            )}
                            {post.file3?.filetype === "videos" ? (
                              <>
                                <video
                                  width={
                                    fullscreened
                                      ? "100%"
                                      : post.file4.path !== ""
                                      ? 130
                                      : 110
                                  }
                                  height={fullscreened ? "40%" : 95}
                                  ref={(element) =>
                                    (droppedvideoref.current[index] =
                                      post.file1.filetype === "videos" &&
                                      post.file2.filetype === "videos"
                                        ? [
                                            droppedvideoref.current[index][0],
                                            droppedvideoref.current[index][1],
                                            element,
                                          ]
                                        : post.file1.filetype !== "videos" &&
                                          post.file2.filetype !== "videos"
                                        ? element
                                        : post.file1.filetype !== "videos" ||
                                          post.file2.filetype !== "videos"
                                        ? [
                                            droppedvideoref?.current[index],
                                            element,
                                          ]
                                        : [])
                                  }
                                  playsInline
                                  muted
                                  id={post.file3.id}
                                  style={{
                                    ...videohtml(post.file1),

                                    marginTop: 4,
                                  }}
                                  onTimeUpdate={Videotimeupdate}
                                  onEnded={(e) =>
                                    Droppedvideotimeupdate(e, index)
                                  }
                                >
                                  <source
                                    type="video/mp4"
                                    src={post.file3?.path}
                                  />
                                </video>

                                {droppedvideoref?.current[index] !== null &&
                                  !isNaN(
                                    Array.isArray(
                                      droppedvideoref.current[index]
                                    )
                                      ? droppedvideoref.current[index][0]
                                          ?.duration
                                      : droppedvideoref.current[index]?.duration
                                  ) && (
                                    <span
                                      //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                      style={{
                                        bottom: "16%",
                                        left: "26%",
                                        zIndex: 1,
                                        position: "absolute",
                                      }}
                                      className="videoduration"
                                    >
                                      {0 +
                                        Math.floor(
                                          determinevideoindex(
                                            post.file1.filetype,
                                            post.file2.filetype,
                                            post.file4.filetype,
                                            index
                                          )?.currentTime / 60
                                        )}
                                      :
                                      {determinevideoindex(
                                        post.file1.filetype,
                                        post.file2.filetype,
                                        post.file4.filetype,
                                        index
                                      )?.currentTime < 10 && 0}
                                      {determinevideoindex(
                                        post.file1.filetype,
                                        post.file2.filetype,
                                        post.file4.filetype,
                                        index
                                      )?.currentTime >= 60
                                        ? Math.floor(
                                            determinevideoindex(
                                              post.file1.filetype,
                                              post.file2.filetype,
                                              post.file4.filetype,
                                              index
                                            )?.currentTime % 60
                                          )
                                        : Math.floor(
                                            determinevideoindex(
                                              post.file1.filetype,
                                              post.file2.filetype,
                                              post.file4.filetype,
                                              index
                                            )?.currentTime
                                          )}
                                      <span
                                        style={{
                                          marginLeft: 5,
                                          marginRight: 5,
                                        }}
                                      >
                                        /
                                      </span>
                                      {0 +
                                        Math.floor(
                                          determinevideoindex(
                                            post.file1.filetype,
                                            post.file2.filetype,
                                            post.file4.filetype,
                                            index
                                          )?.duration / 60
                                        )}
                                      :
                                      {determinevideoindex(
                                        post.file1.filetype,
                                        post.file2.filetype,
                                        post.file4.filetype,
                                        index
                                      )?.duration < 10 && 0}
                                      {determinevideoindex(
                                        post.file1.filetype,
                                        post.file2.filetype,
                                        post.file4.filetype,
                                        index
                                      )?.duration >= 60
                                        ? Math.floor(
                                            determinevideoindex(
                                              post.file1.filetype,
                                              post.file2.filetype,
                                              post.file4.filetype,
                                              index
                                            )?.duration % 60
                                          )
                                        : Math.floor(
                                            determinevideoindex(
                                              post.file1.filetype,
                                              post.file2.filetype,
                                              post.file4.filetype,
                                              index
                                            )?.duration
                                          )}
                                    </span>
                                  )}
                              </>
                            ) : (
                              post.file3?.filetype === "images" && (
                                <img
                                  className="postphoto"
                                  src={post.file3?.path}
                                  id={post.file3?.id}
                                  style={{
                                    ...imagehtml(post.file1),
                                    width: !post.file4?.path ? 110 : 130,
                                    height: 95,
                                    marginTop: 4,
                                  }}
                                />
                              )
                            )}
                          </>
                        )}

                        {post.file4?.filetype === "videos" ? (
                          <>
                            <video
                              width={
                                fullscreened
                                  ? "100%"
                                  : post.file4.path !== ""
                                  ? 130
                                  : 110
                              }
                              height={fullscreened ? "40%" : 95}
                              ref={(element) =>
                                (droppedvideoref.current[index] =
                                  post.file1.filetype === "videos" &&
                                  post.file2.filetype === "videos" &&
                                  post.file3.filetype === "videos"
                                    ? [
                                        droppedvideoref.current[index][0],
                                        droppedvideoref.current[index][1],
                                        droppedvideoref.current[index][2],
                                        element,
                                      ]
                                    : post.file1.filetype !== "videos" &&
                                      post.file2.filetype !== "videos" &&
                                      post.file3.filetype !== "videos"
                                    ? element
                                    : post.file1.filetype !== "videos" ||
                                      post.file2.filetype !== "videos" ||
                                      post.file3.filetype !== "videos"
                                    ? [
                                        ...determinevideoref(
                                          post.file1.filetype,
                                          post.file2.filetype,
                                          post.file3.filetype,
                                          index
                                        ),
                                        element,
                                      ]
                                    : [])
                              }
                              playsInline
                              muted
                              id={post.file4.id}
                              style={{
                                ...videohtml(post.file1),

                                marginTop: 4,
                              }}
                              onTimeUpdate={Videotimeupdate}
                              onEnded={(e) => Droppedvideotimeupdate(e, index)}
                            >
                              <source type="video/mp4" src={post.file4?.path} />
                            </video>

                            {droppedvideoref?.current[index] !== null &&
                              !isNaN(
                                Array.isArray(droppedvideoref.current[index])
                                  ? droppedvideoref.current[index][0]?.duration
                                  : droppedvideoref.current[index]?.duration
                              ) && (
                                <span
                                  //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                  style={{
                                    bottom: "16%",
                                    left: "61.5%",
                                    zIndex: 1,
                                    position: "absolute",
                                  }}
                                  className="videoduration"
                                >
                                  {0 +
                                    Math.floor(
                                      determinefourthvideoindex(
                                        post.file1.filetype,
                                        post.file2.filetype,
                                        post.file3.filetype,
                                        index
                                      )?.currentTime / 60
                                    )}
                                  :
                                  {determinefourthvideoindex(
                                    post.file1.filetype,
                                    post.file2.filetype,
                                    post.file3.filetype,
                                    index
                                  )?.currentTime < 10 && 0}
                                  {determinefourthvideoindex(
                                    post.file1.filetype,
                                    post.file2.filetype,
                                    post.file3.filetype,
                                    index
                                  )?.currentTime >= 60
                                    ? Math.floor(
                                        determinefourthvideoindex(
                                          post.file1.filetype,
                                          post.file2.filetype,
                                          post.file3.filetype,
                                          index
                                        )?.currentTime % 60
                                      )
                                    : Math.floor(
                                        determinefourthvideoindex(
                                          post.file1.filetype,
                                          post.file2.filetype,
                                          post.file3.filetype,
                                          index
                                        )?.currentTime
                                      )}
                                  <span
                                    style={{ marginLeft: 5, marginRight: 5 }}
                                  >
                                    /
                                  </span>
                                  {0 +
                                    Math.floor(
                                      determinefourthvideoindex(
                                        post.file1.filetype,
                                        post.file2.filetype,
                                        post.file3.filetype,
                                        index
                                      )?.duration / 60
                                    )}
                                  :
                                  {determinefourthvideoindex(
                                    post.file1.filetype,
                                    post.file2.filetype,
                                    post.file3.filetype,
                                    index
                                  )?.duration < 10 && 0}
                                  {determinefourthvideoindex(
                                    post.file1.filetype,
                                    post.file2.filetype,
                                    post.file3.filetype,
                                    index
                                  )?.duration >= 60
                                    ? Math.floor(
                                        determinefourthvideoindex(
                                          post.file1.filetype,
                                          post.file2.filetype,
                                          post.file3.filetype,
                                          index
                                        )?.duration % 60
                                      )
                                    : Math.floor(
                                        determinefourthvideoindex(
                                          post.file1.filetype,
                                          post.file2.filetype,
                                          post.file3.filetype,
                                          index
                                        )?.duration
                                      )}
                                </span>
                              )}
                          </>
                        ) : (
                          post.file4?.filetype === "images" && (
                            <img
                              src={post.file4?.path}
                              className="postphoto"
                              id={post.file4?.id}
                              style={{
                                ...imagehtml(post.file1),
                                width: 130,
                                height: 95,
                                marginTop: 4,
                              }}
                            />
                          )
                        )}

                        {post.image1 && (
                          <img
                            className="postphoto"
                            src={post.image1}
                            style={{
                              width: post.image2 && 150,
                              height: post.image2 && 150,
                              marginLeft: post.image2 && 50,
                              marginTop: post.image2 && 20,
                            }}
                          />
                        )}
                      </div>
                      <div className="date">
                        {post.year}/{post.month}/{post.date}
                        <span className="Timesplit">|</span>
                        <span className="Time">
                          {post.hour}:{post.minutes}
                        </span>
                      </div>
                      <img
                        className="Rankmarked"
                        src="/RankMark.svg"
                        style={{ top: (post.image1 || post.file1?.path) && 51 }}
                      />

                      <span className="dropoption">
                        <span className="commentgroup">
                          <img
                            className="commenttap"
                            src="./Comment1.svg"
                            id={[post.id, post.Directory]}
                            onClick={comment}
                          />
                          <span className="commentcount">
                            {post.Comment_Counter == 0
                              ? null
                              : post.Comment_Counter}
                          </span>
                        </span>
                        <span className="redropline">
                          {post.Redropped == "done" ? (
                            <img
                              src="./Redrop.svg"
                              className="Redrop"
                              //couldnt we combine the id for redrop and askunredrop
                              onClick={AskUnredrop}
                              id={[post.id, post.Directory]}
                            />
                          ) : (
                            <img
                              className="Redrop"
                              src="./PlaneRedrop4.svg"
                              id={[post.id, post.Directory]}
                              onClick={redrop}
                            />
                          )}
                          <span className="commentcount">
                            {post.Redropped_Counter == 0
                              ? null
                              : post.Redropped_Counter}
                          </span>
                        </span>
                      </span>

                      {redroppoint && post.id === redropid[0] && (
                        <span className="Pselect">
                          {
                            <button
                              style={{
                                left: "30%",
                                fontSize: 9,
                                color: "white",
                                bottom: post.file1?.path ? "3%" : "5%",
                              }}
                              onClick={(e) => confirmredrop(post)}
                            >
                              リドロップ
                            </button>
                          }
                          <button
                            style={{
                              left: "30%",
                              fontSize: 9,
                              marginLeft: 85,
                              bottom: post.file1?.path ? "3%" : "5%",
                            }}
                            onClick={(e) => {
                              setRedroppoint(false);
                            }}
                          >
                            キャンセル
                          </button>
                        </span>
                      )}
                      {unredroppoint && post.id === unredropid[0] && (
                        <span style={{ bottom: "5%" }} className="Pselect">
                          <button
                            style={{
                              left: "30%",
                              fontSize: 9,
                              color: "white",
                              bottom: post.file1?.path ? "3%" : "5%",
                            }}
                            onClick={(e) =>
                              ConfirmUnredrop(post.OriginalTimestamp)
                            }
                          >
                            リドロップ解除
                          </button>
                          <button
                            style={{
                              left: "30%",
                              fontSize: 9,
                              marginLeft: 85,
                              bottom: post.file1?.path ? "3%" : "5%",
                            }}
                            onClick={CancelUnredrop}
                          >
                            キャンセル
                          </button>
                        </span>
                      )}
                      {blockid === post.id && (
                        <div className="Blockposition">
                          <Block
                            style={{ width: "80%" }}
                            // style={{ zIndex: 8 }}
                            understandblocked={understandblocked}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </>
              ) : (
                <>
                  {!clickedcomment ? (
                    <>
                      <div
                        className="drops"
                        id={alldisplay.at(-1).id}
                        style={{
                          height:
                            (alldisplay.at(-1).image2 && 310) ||
                            ((alldisplay.at(-1).image1 ||
                              (alldisplay.at(-1).file1?.path !== "" &&
                                alldisplay.at(-1).file1?.path !== undefined)) &&
                              330),
                        }}
                      >
                        <div className="postlinetop">
                          <Link
                            to={`/UserView/${
                              alldisplay.at(-1).Sender_id
                                ? alldisplay.at(-1).Sender_id
                                : alldisplay.at(-1).Directory
                            }`}
                          >
                            <img
                              className={
                                alldisplay.at(-1).Profile_image ===
                                "/Profile3.svg"
                                  ? "current3"
                                  : "current1"
                              }
                              src={alldisplay.at(-1).Profile_image}
                            />
                          </Link>

                          {(alldisplay.at(-1).image1 ||
                            alldisplay.at(-1).file1?.path) && (
                            <div
                              style={{
                                fontSize:
                                  (
                                    alldisplay.at(-1).text ||
                                    alldisplay.at(-1).Comment
                                  )?.length >= 142 && 10.5,
                                position:
                                  optionid[0] === alldisplay.at(-1).id &&
                                  "absolute",
                                left:
                                  optionid[0] === alldisplay.at(-1).id &&
                                  (
                                    alldisplay.at(-1).text ||
                                    alldisplay.at(-1).Comment
                                  )?.length >= 23
                                    ? "20%"
                                    : `${
                                        49.5 -
                                        (
                                          alldisplay.at(-1).text ||
                                          alldisplay.at(-1).Comment
                                        )?.length
                                      }%`,
                              }}
                              className={
                                (
                                  alldisplay.at(-1).text ||
                                  alldisplay.at(-1).Comment
                                )?.length >= 23
                                  ? "text"
                                  : "text1"
                              }
                            >
                              {alldisplay.at(-1).text ||
                                alldisplay.at(-1).Comment}
                            </div>
                          )}

                          {optionid[0] !== alldisplay.at(-1).id && (
                            <img
                              src="/DropOption.svg"
                              className="postoption"
                              id={[
                                alldisplay.at(-1).id,
                                alldisplay.at(-1).Sender_id
                                  ? alldisplay.at(-1).Sender_id
                                  : alldisplay.at(-1).Directory,
                              ]}
                              onClick={OptionTerminal}
                            />
                          )}

                          {!deleteid &&
                            optionid[0] === alldisplay.at(-1).id && (
                              <span className="Delete">
                                {(id === optionid[1] ||
                                  id === alldisplay[0].Directory) && (
                                  <button
                                    id={alldisplay.at(-1).id}
                                    onClick={(e) =>
                                      setDeleteid(alldisplay.at(-1).id)
                                    }
                                  >
                                    Delete
                                  </button>
                                )}

                                <button
                                  onClick={(e) => {
                                    setOptionId("");
                                    setDeleteid("");
                                  }}
                                >
                                  Cancel
                                </button>
                              </span>
                            )}

                          {deleteid === alldisplay.at(-1).id && (
                            <span className="Delete">
                              <button
                                id={alldisplay.at(-1).id}
                                onClick={
                                  alldisplay.length === 1
                                    ? deletedrop
                                    : (e) => deletecomment(2)
                                }
                              >
                                〇
                              </button>
                              <button onClick={(e) => setDeleteid("")}>
                                X
                              </button>
                            </span>
                          )}
                        </div>
                        <span className="textline">
                          <span
                            className="stylerank"
                            style={{
                              marginLeft:
                                alldisplay.at(-1).Rank <= 9
                                  ? 19.4
                                  : alldisplay.at(-1).Rank === 11
                                  ? 16
                                  : alldisplay.at(-1).Rank <= 99
                                  ? 14.5
                                  : alldisplay.at(-1).Rank > 99 && 10,
                              flex:
                                (
                                  alldisplay.at(-1).text ||
                                  alldisplay.at(-1).Comment
                                )?.length >= 25
                                  ? 0.18
                                  : (
                                      alldisplay.at(-1).text ||
                                      alldisplay.at(-1).Comment
                                    )?.length === 20
                                  ? 0.14
                                  : 0.5,
                              marginTop:
                                (
                                  alldisplay.at(-1).text ||
                                  alldisplay.at(-1).Comment
                                )?.length > 65 &&
                                (alldisplay.at(-1).image1 ||
                                  alldisplay.at(-1).file1?.path)
                                  ? -25
                                  : null,
                            }}
                          >
                            {alldisplay.at(-1).Rank}
                          </span>
                          {!alldisplay.at(-1).image1 &&
                            !alldisplay.at(-1).file1?.path && (
                              <div
                                className={
                                  alldisplay.at(-1).text?.length >= 23 ||
                                  alldisplay.at(-1).Comment?.length >= 23
                                    ? "text"
                                    : "text1"
                                }
                              >
                                {alldisplay.at(-1)?.text ||
                                  alldisplay.at(-1)?.Comment}
                              </div>
                            )}
                        </span>
                        <div
                          className="alldrops"
                          /*id={[
                        alldisplay.at(-1).id,
                        alldisplay.at(-1).Directory,
                        alldisplay.at(-1).Comment_Counter,
                      ]}*/
                          style={{
                            height:
                              (alldisplay.at(-1).image2 && 275) ||
                              ((alldisplay.at(-1).image1 ||
                                alldisplay.at(-1).file1?.path) &&
                                295),
                          }}
                        ></div>

                        <div
                          style={{
                            marginLeft:
                              !alldisplay.at(-1).file3?.path &&
                              alldisplay.at(-1).file2?.path
                                ? 40
                                : alldisplay.at(-1).file3?.path && 60,
                            marginTop: alldisplay.at(-1).file3?.path && -10,
                            justifyContent:
                              alldisplay.at(-1).file3?.path &&
                              !alldisplay.at(-1).file4?.path &&
                              "flex-start",
                          }}
                          className="postphotoline"
                        >
                          {alldisplay.at(-1).file1?.filetype === "videos" ? (
                            <>
                              <video
                                controls={!alldisplay.at(-1).file2.path && true}
                                width={
                                  fullscreened
                                    ? "100%"
                                    : alldisplay.at(-1).file2.path === ""
                                    ? 200
                                    : alldisplay.at(-1).file4.path !== ""
                                    ? 130
                                    : 150
                                }
                                height={
                                  fullscreened
                                    ? "40%"
                                    : alldisplay.at(-1).file2.path === ""
                                    ? 200
                                    : alldisplay.at(-1).file4?.path !== ""
                                    ? 95
                                    : alldisplay.at(-1).file3.path !== ""
                                    ? 195
                                    : 150
                                }
                                ref={(element) =>
                                  (droppedvideoref.current[0] = element)
                                }
                                onTimeUpdate={Videotimeupdate}
                                onEnded={
                                  alldisplay.at(-1).file2.path
                                    ? (e) => Droppedvideotimeupdate(e, 0)
                                    : undefined
                                }
                                playsInline
                                muted
                                loop={!alldisplay.at(-1).file2.path && true}
                                // loop={post.file2.filetype !== "videos" &&  post.file3.filetype !== "videos" && post.file4.filetype !== "videos" && true}

                                id={alldisplay.at(-1).file1.id}
                                style={videohtml(
                                  alldisplay.at(-1)?.file2,
                                  alldisplay.at(-1).file3,
                                  alldisplay.at(-1).file4
                                )}
                              >
                                <source
                                  type="video/mp4"
                                  src={alldisplay.at(-1).file1?.path}
                                />
                              </video>
                              {!isNaN(
                                Array.isArray(droppedvideoref.current[0])
                                  ? droppedvideoref.current[0][0]?.duration
                                  : droppedvideoref.current[0]?.duration
                              ) && (
                                <span
                                  //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                  style={{
                                    bottom: !alldisplay.at(-1).file2.filetype
                                      ? "18%"
                                      : !alldisplay.at(-1).file3.filetype
                                      ? "27%"
                                      : alldisplay.at(-1).file4.filetype
                                      ? "46%"
                                      : "16%",
                                    left:
                                      !alldisplay.at(-1).file2.filetype ||
                                      alldisplay.at(-1).file4.filetype
                                        ? "25%"
                                        : !alldisplay.at(-1).file3.filetype
                                        ? "17%"
                                        : "23%",
                                    zIndex: 1,
                                    position: "absolute",
                                  }}
                                  className="videoduration"
                                >
                                  {0 +
                                    Math.floor(
                                      (Array.isArray(droppedvideoref.current[0])
                                        ? droppedvideoref.current[0][0]
                                        : droppedvideoref.current[0]
                                      )?.currentTime / 60
                                    )}
                                  :
                                  {(Array.isArray(droppedvideoref.current[0])
                                    ? droppedvideoref.current[0][0]
                                    : droppedvideoref.current[0]
                                  )?.currentTime < 10 && 0}
                                  {(Array.isArray(droppedvideoref.current[0])
                                    ? droppedvideoref.current[0][0]
                                    : droppedvideoref.current[0]
                                  )?.currentTime >= 60
                                    ? Math.floor(
                                        (Array.isArray(
                                          droppedvideoref.current[0]
                                        )
                                          ? droppedvideoref.current[0][0]
                                          : droppedvideoref.current[0]
                                        )?.currentTime % 60
                                      )
                                    : Math.floor(
                                        (Array.isArray(
                                          droppedvideoref.current[0]
                                        )
                                          ? droppedvideoref.current[0][0]
                                          : droppedvideoref.current[0]
                                        )?.currentTime
                                      )}
                                  <span
                                    style={{ marginLeft: 5, marginRight: 5 }}
                                  >
                                    /
                                  </span>
                                  {0 +
                                    Math.floor(
                                      (Array.isArray(droppedvideoref.current[0])
                                        ? droppedvideoref.current[0][0]
                                        : droppedvideoref.current[0]
                                      )?.duration / 60
                                    )}
                                  :
                                  {(Array.isArray(droppedvideoref.current[0])
                                    ? droppedvideoref.current[0][0]
                                    : droppedvideoref.current[0]
                                  )?.duration < 10 && 0}
                                  {(Array.isArray(droppedvideoref.current[0])
                                    ? droppedvideoref.current[0][0]
                                    : droppedvideoref.current[0]
                                  )?.duration >= 60
                                    ? Math.floor(
                                        (Array.isArray(
                                          droppedvideoref.current[0]
                                        )
                                          ? droppedvideoref.current[0][0]
                                          : droppedvideoref.current[0]
                                        )?.duration % 60
                                      )
                                    : Math.floor(
                                        (Array.isArray(
                                          droppedvideoref.current[0]
                                        )
                                          ? droppedvideoref.current[0][0]
                                          : droppedvideoref.current[0]
                                        )?.duration
                                      )}
                                </span>
                              )}
                            </>
                          ) : (
                            alldisplay.at(-1).file1?.filetype === "images" && (
                              <img
                                className="postphoto"
                                src={alldisplay.at(-1).file1?.path}
                                id={alldisplay.at(-1).file1?.id}
                                style={imagehtml(
                                  alldisplay.at(-1).file2,
                                  alldisplay.at(-1).file3,
                                  alldisplay.at(-1).file4
                                )}
                              />
                            )
                          )}

                          {!alldisplay.at(-1).file4?.path ? (
                            <div
                              style={{
                                display:
                                  alldisplay.at(-1).file3?.path &&
                                  !alldisplay.at(-1).file4?.path &&
                                  "flex",
                                flexDirection:
                                  alldisplay.at(-1).file3?.path &&
                                  !alldisplay.at(-1).file4?.path &&
                                  "column",
                              }}
                            >
                              {alldisplay.at(-1).file2?.filetype ===
                              "videos" ? (
                                <>
                                  {console.log(droppedvideoref.current)}
                                  <video
                                    width={
                                      fullscreened
                                        ? "100%"
                                        : alldisplay.at(-1).file3?.path
                                        ? 110
                                        : 150
                                    }
                                    height={
                                      fullscreened
                                        ? "40%"
                                        : alldisplay.at(-1).file3?.path
                                        ? 95
                                        : 150
                                    }
                                    ref={(element) =>
                                      (droppedvideoref.current[0] =
                                        alldisplay.at(-1).file1.filetype ===
                                        "videos"
                                          ? [
                                              droppedvideoref.current[0],
                                              element,
                                            ]
                                          : element)
                                    }
                                    playsInline
                                    muted
                                    id={alldisplay.at(-1).file2.id}
                                    style={videohtml(alldisplay.at(-1).file1)}
                                    onTimeUpdate={Videotimeupdate}
                                    onEnded={(e) =>
                                      Droppedvideotimeupdate(e, 0)
                                    }
                                  >
                                    <source
                                      type="video/mp4"
                                      src={alldisplay.at(-1).file2?.path}
                                    />
                                  </video>

                                  {droppedvideoref?.current[0] !== null &&
                                    !isNaN(
                                      Array.isArray(droppedvideoref.current[0])
                                        ? droppedvideoref.current[0][0]
                                            ?.duration
                                        : droppedvideoref.current[0]?.duration
                                    ) && (
                                      <span
                                        //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                        style={{
                                          bottom: !alldisplay.at(-1).file3
                                            .filetype
                                            ? "27%"
                                            : "46%",
                                          left: !alldisplay.at(-1).file3
                                            .filetype
                                            ? "59%"
                                            : "65%",
                                          zIndex: 1,
                                          position: "absolute",
                                        }}
                                        className="videoduration"
                                      >
                                        {0 +
                                          Math.floor(
                                            (alldisplay.at(-1).file1
                                              .filetype === "videos"
                                              ? droppedvideoref?.current[0][1]
                                              : Array.isArray(
                                                  droppedvideoref.current[0]
                                                )
                                              ? droppedvideoref?.current[0][0]
                                              : droppedvideoref?.current[0]
                                            )?.currentTime / 60
                                          )}
                                        :
                                        {(alldisplay.at(-1).file1.filetype ===
                                        "videos"
                                          ? droppedvideoref?.current[0][1]
                                          : Array.isArray(
                                              droppedvideoref.current[0]
                                            )
                                          ? droppedvideoref?.current[0][0]
                                          : droppedvideoref?.current[0]
                                        )?.currentTime < 10 && 0}
                                        {(alldisplay.at(-1).file1.filetype ===
                                        "videos"
                                          ? droppedvideoref?.current[0][1]
                                          : Array.isArray(
                                              droppedvideoref.current[0]
                                            )
                                          ? droppedvideoref?.current[0][0]
                                          : droppedvideoref?.current[0]
                                        )?.currentTime >= 60
                                          ? Math.floor(
                                              (alldisplay.at(-1).file1
                                                .filetype === "videos"
                                                ? droppedvideoref?.current[0][1]
                                                : Array.isArray(
                                                    droppedvideoref.current[0]
                                                  )
                                                ? droppedvideoref?.current[0][0]
                                                : droppedvideoref?.current[0]
                                              )?.currentTime % 60
                                            )
                                          : Math.floor(
                                              (alldisplay.at(-1).file1
                                                .filetype === "videos"
                                                ? droppedvideoref?.current[0][1]
                                                : Array.isArray(
                                                    droppedvideoref.current[0]
                                                  )
                                                ? droppedvideoref?.current[0][0]
                                                : droppedvideoref?.current[0]
                                              )?.currentTime
                                            )}
                                        <span
                                          style={{
                                            marginLeft: 5,
                                            marginRight: 5,
                                          }}
                                        >
                                          /
                                        </span>
                                        {0 +
                                          Math.floor(
                                            (alldisplay.at(-1).file1
                                              .filetype === "videos"
                                              ? droppedvideoref?.current[0][1]
                                              : Array.isArray(
                                                  droppedvideoref.current[0]
                                                )
                                              ? droppedvideoref?.current[0][0]
                                              : droppedvideoref?.current[0]
                                            )?.duration / 60
                                          )}
                                        :
                                        {(alldisplay.at(-1).file1.filetype ===
                                        "videos"
                                          ? droppedvideoref?.current[0][1]
                                          : Array.isArray(
                                              droppedvideoref.current[0]
                                            )
                                          ? droppedvideoref?.current[0][0]
                                          : droppedvideoref?.current[0]
                                        )?.duration < 10 && 0}
                                        {(alldisplay.at(-1).file1.filetype ===
                                        "videos"
                                          ? droppedvideoref?.current[0][1]
                                          : Array.isArray(
                                              droppedvideoref.current[0]
                                            )
                                          ? droppedvideoref?.current[0][0]
                                          : droppedvideoref?.current[0]
                                        )?.duration >= 60
                                          ? Math.floor(
                                              (alldisplay.at(-1).file1
                                                .filetype === "videos"
                                                ? droppedvideoref?.current[0][1]
                                                : Array.isArray(
                                                    droppedvideoref.current[0]
                                                  )
                                                ? droppedvideoref?.current[0][0]
                                                : droppedvideoref?.current[0]
                                              )?.duration % 60
                                            )
                                          : Math.floor(
                                              (alldisplay.at(-1).file1
                                                .filetype === "videos"
                                                ? droppedvideoref?.current[0][1]
                                                : Array.isArray(
                                                    droppedvideoref.current[0]
                                                  )
                                                ? droppedvideoref?.current[0][0]
                                                : droppedvideoref?.current[0]
                                              )?.duration
                                            )}
                                      </span>
                                    )}
                                </>
                              ) : (
                                alldisplay.at(-1).file2?.filetype ===
                                  "images" && (
                                  <img
                                    className="postphoto"
                                    src={alldisplay.at(-1).file2?.path}
                                    id={alldisplay.at(-1).file2?.id}
                                    style={
                                      alldisplay.at(-1).file3?.path
                                        ? {
                                            ...imagehtml(
                                              alldisplay.at(-1).file1
                                            ),
                                            width: 110,
                                            height:
                                              alldisplay.at(-1).file3.path &&
                                              95,
                                          }
                                        : imagehtml(alldisplay.at(-1).file1)
                                    }
                                  />
                                )
                              )}

                              {alldisplay.at(-1).file3?.filetype ===
                              "videos" ? (
                                <>
                                  <video
                                    width={fullscreened ? "100%" : 110}
                                    height={fullscreened ? "40%" : 95}
                                    ref={(element) =>
                                      (droppedvideoref.current[0] =
                                        alldisplay.at(-1).file1.filetype ===
                                          "videos" &&
                                        alldisplay.at(-1).file2.filetype ===
                                          "videos"
                                          ? [
                                              droppedvideoref.current[0][0],
                                              droppedvideoref.current[0][1],
                                              element,
                                            ]
                                          : alldisplay.at(-1).file1.filetype !==
                                              "videos" &&
                                            alldisplay.at(-1).file2.filetype !==
                                              "videos"
                                          ? element
                                          : alldisplay.at(-1).file1.filetype !==
                                              "videos" ||
                                            alldisplay.at(-1).file2.filetype !==
                                              "videos"
                                          ? [
                                              droppedvideoref?.current[0],
                                              element,
                                            ]
                                          : [])
                                    }
                                    playsInline
                                    muted
                                    id={alldisplay.at(-1).file3.id}
                                    style={{
                                      ...videohtml(alldisplay.at(-1).file1),
                                      marginTop: 4,
                                    }}
                                    onTimeUpdate={Videotimeupdate}
                                    onEnded={(e) =>
                                      Droppedvideotimeupdate(e, 0)
                                    }
                                  >
                                    <source
                                      type="video/mp4"
                                      src={alldisplay.at(-1).file3?.path}
                                    />
                                  </video>

                                  {droppedvideoref?.current[0] !== null &&
                                    !isNaN(
                                      Array.isArray(droppedvideoref.current[0])
                                        ? droppedvideoref.current[0][0]
                                            ?.duration
                                        : droppedvideoref.current[0]?.duration
                                    ) && (
                                      <span
                                        //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                        style={{
                                          bottom: "16%",
                                          left: "65%",
                                          zIndex: 1,
                                          position: "absolute",
                                        }}
                                        className="videoduration"
                                      >
                                        {0 +
                                          Math.floor(
                                            determinevideoindex(
                                              alldisplay.at(-1).file1.filetype,
                                              alldisplay.at(-1).file2.filetype,
                                              alldisplay.at(-1).file4.filetype,
                                              0
                                            )?.currentTime / 60
                                          )}
                                        :
                                        {determinevideoindex(
                                          alldisplay.at(-1).file1.filetype,
                                          alldisplay.at(-1).file2.filetype,
                                          alldisplay.at(-1).file4.filetype,
                                          0
                                        )?.currentTime < 10 && 0}
                                        {determinevideoindex(
                                          alldisplay.at(-1).file1.filetype,
                                          alldisplay.at(-1).file2.filetype,
                                          alldisplay.at(-1).file4.filetype,
                                          0
                                        )?.currentTime >= 60
                                          ? Math.floor(
                                              determinevideoindex(
                                                alldisplay.at(-1).file1
                                                  .filetype,
                                                alldisplay.at(-1).file2
                                                  .filetype,
                                                alldisplay.at(-1).file4
                                                  .filetype,
                                                0
                                              )?.currentTime % 60
                                            )
                                          : Math.floor(
                                              determinevideoindex(
                                                alldisplay.at(-1).file1
                                                  .filetype,
                                                alldisplay.at(-1).file2
                                                  .filetype,
                                                alldisplay.at(-1).file4
                                                  .filetype,
                                                0
                                              )?.currentTime
                                            )}
                                        <span
                                          style={{
                                            marginLeft: 5,
                                            marginRight: 5,
                                          }}
                                        >
                                          /
                                        </span>
                                        {0 +
                                          Math.floor(
                                            determinevideoindex(
                                              alldisplay.at(-1).file1.filetype,
                                              alldisplay.at(-1).file2.filetype,
                                              alldisplay.at(-1).file4.filetype,
                                              0
                                            )?.duration / 60
                                          )}
                                        :
                                        {determinevideoindex(
                                          alldisplay.at(-1).file1.filetype,
                                          alldisplay.at(-1).file2.filetype,
                                          alldisplay.at(-1).file4.filetype,
                                          0
                                        )?.duration < 10 && 0}
                                        {determinevideoindex(
                                          alldisplay.at(-1).file1.filetype,
                                          alldisplay.at(-1).file2.filetype,
                                          alldisplay.at(-1).file4.filetype,
                                          0
                                        )?.duration >= 60
                                          ? Math.floor(
                                              determinevideoindex(
                                                alldisplay.at(-1).file1
                                                  .filetype,
                                                alldisplay.at(-1).file2
                                                  .filetype,
                                                alldisplay.at(-1).file4
                                                  .filetype,
                                                0
                                              )?.duration % 60
                                            )
                                          : Math.floor(
                                              determinevideoindex(
                                                alldisplay.at(-1).file1
                                                  .filetype,
                                                alldisplay.at(-1).file2
                                                  .filetype,
                                                alldisplay.at(-1).file4
                                                  .filetype,
                                                0
                                              )?.duration
                                            )}
                                      </span>
                                    )}
                                </>
                              ) : (
                                alldisplay.at(-1).file3?.filetype ===
                                  "images" && (
                                  <img
                                    className="postphoto"
                                    src={alldisplay.at(-1).file3?.path}
                                    id={alldisplay.at(-1).file3?.id}
                                    style={{
                                      ...imagehtml(alldisplay.at(-1).file1),
                                      width: !alldisplay.at(-1).file4?.path
                                        ? 110
                                        : 130,
                                      height: 95,
                                      marginTop: 4,
                                    }}
                                  />
                                )
                              )}
                            </div>
                          ) : (
                            <>
                              {alldisplay.at(-1).file2?.filetype ===
                              "videos" ? (
                                <>
                                  <video
                                    width={
                                      fullscreened
                                        ? "100%"
                                        : alldisplay.at(-1).file4.path !== ""
                                        ? 130
                                        : alldisplay.at(-1).file3.path
                                        ? 110
                                        : 150
                                    }
                                    height={
                                      fullscreened
                                        ? "40%"
                                        : alldisplay.at(-1).file3.path
                                        ? 95
                                        : 150
                                    }
                                    ref={(element) =>
                                      (droppedvideoref.current[0] =
                                        alldisplay.at(-1).file1.filetype ===
                                        "videos"
                                          ? [
                                              droppedvideoref.current[0],
                                              element,
                                            ]
                                          : element)
                                    }
                                    playsInline
                                    muted
                                    id={alldisplay.at(-1).file2.id}
                                    style={videohtml(alldisplay.at(-1).file1)}
                                    onTimeUpdate={Videotimeupdate}
                                    onEnded={(e) =>
                                      Droppedvideotimeupdate(e, 0)
                                    }
                                  >
                                    <source
                                      type="video/mp4"
                                      src={alldisplay.at(-1).file2?.path}
                                    />
                                  </video>

                                  {droppedvideoref?.current[0] !== null &&
                                    !isNaN(
                                      Array.isArray(droppedvideoref.current[0])
                                        ? droppedvideoref.current[0][0]
                                            ?.duration
                                        : droppedvideoref.current[0]?.duration
                                    ) && (
                                      <span
                                        //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                        style={{
                                          bottom: "46%",
                                          left: "62%",

                                          zIndex: 1,
                                          position: "absolute",
                                        }}
                                        className="videoduration"
                                      >
                                        {0 +
                                          Math.floor(
                                            (alldisplay.at(-1).file1
                                              .filetype === "videos"
                                              ? droppedvideoref?.current[0][1]
                                              : Array.isArray(
                                                  droppedvideoref.current[0]
                                                )
                                              ? droppedvideoref?.current[0][0]
                                              : droppedvideoref?.current[0]
                                            )?.currentTime / 60
                                          )}
                                        :
                                        {(alldisplay.at(-1).file1.filetype ===
                                        "videos"
                                          ? droppedvideoref?.current[0][1]
                                          : Array.isArray(
                                              droppedvideoref.current[0]
                                            )
                                          ? droppedvideoref?.current[0][0]
                                          : droppedvideoref?.current[0]
                                        )?.currentTime < 10 && 0}
                                        {(alldisplay.at(-1).file1.filetype ===
                                        "videos"
                                          ? droppedvideoref?.current[0][1]
                                          : Array.isArray(
                                              droppedvideoref.current[0]
                                            )
                                          ? droppedvideoref?.current[0][0]
                                          : droppedvideoref?.current[0]
                                        )?.currentTime >= 60
                                          ? Math.floor(
                                              (alldisplay.at(-1).file1
                                                .filetype === "videos"
                                                ? droppedvideoref?.current[0][1]
                                                : Array.isArray(
                                                    droppedvideoref.current[0]
                                                  )
                                                ? droppedvideoref?.current[0][0]
                                                : droppedvideoref?.current[0]
                                              )?.currentTime % 60
                                            )
                                          : Math.floor(
                                              (alldisplay.at(-1).file1
                                                .filetype === "videos"
                                                ? droppedvideoref?.current[0][1]
                                                : Array.isArray(
                                                    droppedvideoref.current[0]
                                                  )
                                                ? droppedvideoref?.current[0][0]
                                                : droppedvideoref?.current[0]
                                              )?.currentTime
                                            )}
                                        <span
                                          style={{
                                            marginLeft: 5,
                                            marginRight: 5,
                                          }}
                                        >
                                          /
                                        </span>
                                        {0 +
                                          Math.floor(
                                            (alldisplay.at(-1).file1
                                              .filetype === "videos"
                                              ? droppedvideoref?.current[0][1]
                                              : Array.isArray(
                                                  droppedvideoref.current[0]
                                                )
                                              ? droppedvideoref?.current[0][0]
                                              : droppedvideoref?.current[0]
                                            )?.duration / 60
                                          )}
                                        :
                                        {(alldisplay.at(-1).file1.filetype ===
                                        "videos"
                                          ? droppedvideoref?.current[0][1]
                                          : Array.isArray(
                                              droppedvideoref.current[0]
                                            )
                                          ? droppedvideoref?.current[0][0]
                                          : droppedvideoref?.current[0]
                                        )?.duration < 10 && 0}
                                        {(alldisplay.at(-1).file1.filetype ===
                                        "videos"
                                          ? droppedvideoref?.current[0][1]
                                          : Array.isArray(
                                              droppedvideoref.current[0]
                                            )
                                          ? droppedvideoref?.current[0][0]
                                          : droppedvideoref?.current[0]
                                        )?.duration >= 60
                                          ? Math.floor(
                                              (alldisplay.at(-1).file1
                                                .filetype === "videos"
                                                ? droppedvideoref?.current[0][1]
                                                : Array.isArray(
                                                    droppedvideoref.current[0]
                                                  )
                                                ? droppedvideoref?.current[0][0]
                                                : droppedvideoref?.current[0]
                                              )?.duration % 60
                                            )
                                          : Math.floor(
                                              (alldisplay.at(-1).file1
                                                .filetype === "videos"
                                                ? droppedvideoref?.current[0][1]
                                                : Array.isArray(
                                                    droppedvideoref.current[0]
                                                  )
                                                ? droppedvideoref?.current[0][0]
                                                : droppedvideoref?.current[0]
                                              )?.duration
                                            )}
                                      </span>
                                    )}
                                </>
                              ) : (
                                alldisplay.at(-1).file2?.filetype ===
                                  "images" && (
                                  <img
                                    className="postphoto"
                                    src={alldisplay.at(-1).file2?.path}
                                    id={alldisplay.at(-1).file2?.id}
                                    style={
                                      alldisplay.at(-1).file3?.path
                                        ? {
                                            ...imagehtml(
                                              alldisplay.at(-1).file1
                                            ),
                                            width:
                                              (alldisplay.at(-1).file4?.path &&
                                                130) ||
                                              (alldisplay.at(-1).file3.path &&
                                                110),
                                            height:
                                              alldisplay.at(-1).file3.path &&
                                              95,
                                          }
                                        : imagehtml(alldisplay.at(-1).file1)
                                    }
                                  />
                                )
                              )}
                              {alldisplay.at(-1).file3?.filetype ===
                              "videos" ? (
                                <>
                                  <video
                                    width={
                                      fullscreened
                                        ? "100%"
                                        : alldisplay.at(-1).file4.path !== ""
                                        ? 130
                                        : 110
                                    }
                                    height={fullscreened ? "40%" : 95}
                                    ref={(element) =>
                                      (droppedvideoref.current[0] =
                                        alldisplay.at(-1).file1.filetype ===
                                          "videos" &&
                                        alldisplay.at(-1).file2.filetype ===
                                          "videos"
                                          ? [
                                              droppedvideoref.current[0][0],
                                              droppedvideoref.current[0][1],
                                              element,
                                            ]
                                          : alldisplay.at(-1).file1.filetype !==
                                              "videos" &&
                                            alldisplay.at(-1).file2.filetype !==
                                              "videos"
                                          ? element
                                          : alldisplay.at(-1).file1.filetype !==
                                              "videos" ||
                                            alldisplay.at(-1).file2.filetype !==
                                              "videos"
                                          ? [
                                              droppedvideoref?.current[0],
                                              element,
                                            ]
                                          : [])
                                    }
                                    playsInline
                                    muted
                                    id={alldisplay.at(-1).file3.id}
                                    style={{
                                      ...videohtml(alldisplay.at(-1).file1),

                                      marginTop: 4,
                                    }}
                                    onTimeUpdate={Videotimeupdate}
                                    onEnded={(e) =>
                                      Droppedvideotimeupdate(e, 0)
                                    }
                                  >
                                    <source
                                      type="video/mp4"
                                      src={alldisplay.at(-1).file3?.path}
                                    />
                                  </video>

                                  {droppedvideoref?.current[0] !== null &&
                                    !isNaN(
                                      Array.isArray(droppedvideoref.current[0])
                                        ? droppedvideoref.current[0][0]
                                            ?.duration
                                        : droppedvideoref.current[0]?.duration
                                    ) && (
                                      <span
                                        //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                        style={{
                                          bottom: "16%",
                                          left: "26%",
                                          zIndex: 1,
                                          position: "absolute",
                                        }}
                                        className="videoduration"
                                      >
                                        {0 +
                                          Math.floor(
                                            determinevideoindex(
                                              alldisplay.at(-1).file1.filetype,
                                              alldisplay.at(-1).file2.filetype,
                                              alldisplay.at(-1).file4.filetype,
                                              0
                                            )?.currentTime / 60
                                          )}
                                        :
                                        {determinevideoindex(
                                          alldisplay.at(-1).file1.filetype,
                                          alldisplay.at(-1).file2.filetype,
                                          alldisplay.at(-1).file4.filetype,
                                          0
                                        )?.currentTime < 10 && 0}
                                        {determinevideoindex(
                                          alldisplay.at(-1).file1.filetype,
                                          alldisplay.at(-1).file2.filetype,
                                          alldisplay.at(-1).file4.filetype,
                                          0
                                        )?.currentTime >= 60
                                          ? Math.floor(
                                              determinevideoindex(
                                                alldisplay.at(-1).file1
                                                  .filetype,
                                                alldisplay.at(-1).file2
                                                  .filetype,
                                                alldisplay.at(-1).file4
                                                  .filetype,
                                                0
                                              )?.currentTime % 60
                                            )
                                          : Math.floor(
                                              determinevideoindex(
                                                alldisplay.at(-1).file1
                                                  .filetype,
                                                alldisplay.at(-1).file2
                                                  .filetype,
                                                alldisplay.at(-1).file4
                                                  .filetype,
                                                0
                                              )?.currentTime
                                            )}
                                        <span
                                          style={{
                                            marginLeft: 5,
                                            marginRight: 5,
                                          }}
                                        >
                                          /
                                        </span>
                                        {0 +
                                          Math.floor(
                                            determinevideoindex(
                                              alldisplay.at(-1).file1.filetype,
                                              alldisplay.at(-1).file2.filetype,
                                              alldisplay.at(-1).file4.filetype,
                                              0
                                            )?.duration / 60
                                          )}
                                        :
                                        {determinevideoindex(
                                          alldisplay.at(-1).file1.filetype,
                                          alldisplay.at(-1).file2.filetype,
                                          alldisplay.at(-1).file4.filetype,
                                          0
                                        )?.duration < 10 && 0}
                                        {determinevideoindex(
                                          alldisplay.at(-1).file1.filetype,
                                          alldisplay.at(-1).file2.filetype,
                                          alldisplay.at(-1).file4.filetype,
                                          0
                                        )?.duration >= 60
                                          ? Math.floor(
                                              determinevideoindex(
                                                alldisplay.at(-1).file1
                                                  .filetype,
                                                alldisplay.at(-1).file2
                                                  .filetype,
                                                alldisplay.at(-1).file4
                                                  .filetype,
                                                0
                                              )?.duration % 60
                                            )
                                          : Math.floor(
                                              determinevideoindex(
                                                alldisplay.at(-1).file1
                                                  .filetype,
                                                alldisplay.at(-1).file2
                                                  .filetype,
                                                alldisplay.at(-1).file4
                                                  .filetype,
                                                0
                                              )?.duration
                                            )}
                                      </span>
                                    )}
                                </>
                              ) : (
                                alldisplay.at(-1).file3?.filetype ===
                                  "images" && (
                                  <img
                                    className="postphoto"
                                    src={alldisplay.at(-1).file3?.path}
                                    id={alldisplay.at(-1).file3?.id}
                                    style={{
                                      ...imagehtml(alldisplay.at(-1).file1),
                                      width: !alldisplay.at(-1).file4?.path
                                        ? 110
                                        : 130,
                                      height: 95,
                                      marginTop: 4,
                                    }}
                                  />
                                )
                              )}
                            </>
                          )}

                          {alldisplay.at(-1).file4?.filetype === "videos" ? (
                            <>
                              <video
                                width={
                                  fullscreened
                                    ? "100%"
                                    : alldisplay.at(-1).file4.path !== ""
                                    ? 130
                                    : 110
                                }
                                height={fullscreened ? "40%" : 95}
                                ref={(element) =>
                                  (droppedvideoref.current[0] =
                                    alldisplay.at(-1).file1.filetype ===
                                      "videos" &&
                                    alldisplay.at(-1).file2.filetype ===
                                      "videos" &&
                                    alldisplay.at(-1).file3.filetype ===
                                      "videos"
                                      ? [
                                          droppedvideoref.current[0][0],
                                          droppedvideoref.current[0][1],
                                          droppedvideoref.current[0][2],
                                          element,
                                        ]
                                      : alldisplay.at(-1).file1.filetype !==
                                          "videos" &&
                                        alldisplay.at(-1).file2.filetype !==
                                          "videos" &&
                                        alldisplay.at(-1).file3.filetype !==
                                          "videos"
                                      ? element
                                      : alldisplay.at(-1).file1.filetype !==
                                          "videos" ||
                                        alldisplay.at(-1).file2.filetype !==
                                          "videos" ||
                                        alldisplay.at(-1).file3.filetype !==
                                          "videos"
                                      ? [
                                          ...determinevideoref(
                                            alldisplay.at(-1).file1.filetype,
                                            alldisplay.at(-1).file2.filetype,
                                            alldisplay.at(-1).file3.filetype,
                                            0
                                          ),
                                          element,
                                        ]
                                      : [])
                                }
                                playsInline
                                muted
                                id={alldisplay.at(-1).file4.id}
                                style={{
                                  ...videohtml(alldisplay.at(-1).file1),

                                  marginTop: 4,
                                }}
                                onTimeUpdate={Videotimeupdate}
                                onEnded={(e) => Droppedvideotimeupdate(e, 0)}
                              >
                                <source
                                  type="video/mp4"
                                  src={alldisplay.at(-1).file4?.path}
                                />
                              </video>

                              {droppedvideoref?.current[0] !== null &&
                                !isNaN(
                                  Array.isArray(droppedvideoref.current[0])
                                    ? droppedvideoref.current[0][0]?.duration
                                    : droppedvideoref.current[0]?.duration
                                ) && (
                                  <span
                                    //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                    style={{
                                      bottom: "16%",
                                      left: "61.5%",
                                      zIndex: 1,
                                      position: "absolute",
                                    }}
                                    className="videoduration"
                                  >
                                    {0 +
                                      Math.floor(
                                        determinefourthvideoindex(
                                          alldisplay.at(-1).file1.filetype,
                                          alldisplay.at(-1).file2.filetype,
                                          alldisplay.at(-1).file3.filetype,
                                          0
                                        )?.currentTime / 60
                                      )}
                                    :
                                    {determinefourthvideoindex(
                                      alldisplay.at(-1).file1.filetype,
                                      alldisplay.at(-1).file2.filetype,
                                      alldisplay.at(-1).file3.filetype,
                                      0
                                    )?.currentTime < 10 && 0}
                                    {determinefourthvideoindex(
                                      alldisplay.at(-1).file1.filetype,
                                      alldisplay.at(-1).file2.filetype,
                                      alldisplay.at(-1).file3.filetype,
                                      0
                                    )?.currentTime >= 60
                                      ? Math.floor(
                                          determinefourthvideoindex(
                                            alldisplay.at(-1).file1.filetype,
                                            alldisplay.at(-1).file2.filetype,
                                            alldisplay.at(-1).file3.filetype,
                                            0
                                          )?.currentTime % 60
                                        )
                                      : Math.floor(
                                          determinefourthvideoindex(
                                            alldisplay.at(-1).file1.filetype,
                                            alldisplay.at(-1).file2.filetype,
                                            alldisplay.at(-1).file3.filetype,
                                            0
                                          )?.currentTime
                                        )}
                                    <span
                                      style={{ marginLeft: 5, marginRight: 5 }}
                                    >
                                      /
                                    </span>
                                    {0 +
                                      Math.floor(
                                        determinefourthvideoindex(
                                          alldisplay.at(-1).file1.filetype,
                                          alldisplay.at(-1).file2.filetype,
                                          alldisplay.at(-1).file3.filetype,
                                          0
                                        )?.duration / 60
                                      )}
                                    :
                                    {determinefourthvideoindex(
                                      alldisplay.at(-1).file1.filetype,
                                      alldisplay.at(-1).file2.filetype,
                                      alldisplay.at(-1).file3.filetype,
                                      0
                                    )?.duration < 10 && 0}
                                    {determinefourthvideoindex(
                                      alldisplay.at(-1).file1.filetype,
                                      alldisplay.at(-1).file2.filetype,
                                      alldisplay.at(-1).file3.filetype,
                                      0
                                    )?.duration >= 60
                                      ? Math.floor(
                                          determinefourthvideoindex(
                                            alldisplay.at(-1).file1.filetype,
                                            alldisplay.at(-1).file2.filetype,
                                            alldisplay.at(-1).file3.filetype,
                                            0
                                          )?.duration % 60
                                        )
                                      : Math.floor(
                                          determinefourthvideoindex(
                                            alldisplay.at(-1).file1.filetype,
                                            alldisplay.at(-1).file2.filetype,
                                            alldisplay.at(-1).file3.filetype,
                                            0
                                          )?.duration
                                        )}
                                  </span>
                                )}
                            </>
                          ) : (
                            alldisplay.at(-1).file4?.filetype === "images" && (
                              <img
                                src={alldisplay.at(-1).file4?.path}
                                className="postphoto"
                                id={alldisplay.at(-1).file4?.id}
                                style={{
                                  ...imagehtml(alldisplay.at(-1).file1),
                                  width: 130,
                                  height: 95,
                                  marginTop: 4,
                                }}
                              />
                            )
                          )}

                          {alldisplay.at(-1).image1 && (
                            <img
                              className="postphoto"
                              src={alldisplay.at(-1).image1}
                              style={{
                                width: alldisplay.at(-1).image2 && 150,
                                height: alldisplay.at(-1).image2 && 150,
                                marginLeft: alldisplay.at(-1).image2 && 50,
                                marginTop: alldisplay.at(-1).image2 && 20,
                              }}
                            />
                          )}
                        </div>

                        <div className="date">
                          {alldisplay.at(-1).year}/{alldisplay.at(-1).month}/
                          {alldisplay.at(-1).date}
                          <span className="Timesplit">|</span>
                          <span className="Time">
                            {alldisplay.at(-1).hour}:{alldisplay.at(-1).minutes}
                          </span>
                        </div>
                        <img
                          className="Rankmarked"
                          src="/RankMark.svg"
                          style={{
                            top:
                              (alldisplay.at(-1).image1 ||
                                alldisplay.at(-1).file1?.path) &&
                              51,
                          }}
                        />
                        <span
                          className="dropoption"
                          style={{ height: clickedcomment && 29 }}
                        >
                          {
                            //!clickedcomment && !showdrop && (
                            <span className="commentgroup">
                              <img
                                className="commenttap"
                                src="./Comment1.svg"
                                id={[
                                  alldisplay.at(-1).id,
                                  alldisplay.length === 1
                                    ? alldisplay.at(-1).Directory
                                    : alldisplay.at(-1).Sender_id,
                                ]}
                                onClick={comment}
                              />

                              <span className="commentcount">
                                {alldisplay.at(-1).Comment_Counter > 0 &&
                                  alldisplay.at(-1).Comment_Counter}
                              </span>
                            </span>
                            // )
                          }

                          <span className="redropline">
                            {alldisplay.at(-1).Redropped === "done" && (
                              /*!clickedcomment &&*/ <img
                                src="./Redrop.svg"
                                className="Redrop"
                                //couldnt we combine the id for redrop and askunredrop
                                onClick={AskUnredrop}
                                id={[
                                  alldisplay.at(-1).id,
                                  alldisplay.length === 1
                                    ? alldisplay.at(-1).Directory
                                    : alldisplay.at(-1).Sender_id,
                                ]}
                              />
                            )}
                            {alldisplay.at(-1).Redropped !== "done" && (
                              /*!clickedcomment &&*/ <img
                                className="Redrop"
                                src="./PlaneRedrop4.svg"
                                id={[
                                  alldisplay.at(-1).id,
                                  alldisplay.length === 1
                                    ? alldisplay.at(-1).Directory
                                    : alldisplay.at(-1).Sender_id,
                                ]}
                                onClick={redrop}
                              />
                            )}
                            <span className="commentcount">
                              {alldisplay.at(-1).Redropped_Counter == 0 ||
                              clickedcomment
                                ? null
                                : alldisplay.at(-1).Redropped_Counter}
                            </span>
                          </span>
                        </span>
                        {redroppoint &&
                          alldisplay.at(-1).id === redropid[0] && (
                            <span
                              style={{ fontSize: 8, bottom: "5%" }}
                              className="Pselect"
                            >
                              <button
                                style={{
                                  left: "30%",
                                  fontSize: 9,
                                  color: "white",
                                  bottom: alldisplay.at(-1).file1?.path
                                    ? "3%"
                                    : "5%",
                                }}
                                onClick={(e) =>
                                  confirmredrop(alldisplay.at(-1))
                                }
                              >
                                リドロップ
                              </button>
                              <button
                                style={{
                                  left: "30%",
                                  fontSize: 9,
                                  marginLeft: 85,
                                  bottom: alldisplay.at(-1).file1?.path
                                    ? "3%"
                                    : "5%",
                                }}
                                onClick={(e) => {
                                  setRedroppoint(false);
                                }}
                              >
                                キャンセル
                              </button>
                            </span>
                          )}
                        {unredroppoint &&
                          alldisplay.at(-1).id === unredropid[0] && (
                            <span
                              style={{ fontSize: 8, bottom: "5%" }}
                              className="Pselect"
                            >
                              <button
                                style={{
                                  left: "30%",
                                  fontSize: 9,
                                  color: "white",
                                  bottom: alldisplay.at(-1).file1?.path
                                    ? "3%"
                                    : "5%",
                                }}
                                onClick={(e) =>
                                  ConfirmUnredrop(
                                    alldisplay.at(-1).OriginalTimestamp
                                  )
                                }
                              >
                                リドロップ解除
                              </button>
                              <button
                                style={{
                                  left: "30%",
                                  fontSize: 9,
                                  marginLeft: 85,
                                  bottom: alldisplay.at(-1).file1?.path
                                    ? "3%"
                                    : "5%",
                                }}
                                onClick={CancelUnredrop}
                              >
                                キャンセル
                              </button>
                            </span>
                          )}
                        {blockid === alldisplay.at(-1).id && (
                          <div className="Blockposition">
                            <Block
                              style={{ width: "80%" }}
                              // style={{ zIndex: 8 }}
                              understandblocked={understandblocked}
                            />
                          </div>
                        )}
                      </div>

                      {simdisplays[simdisplays.length - 1]?.map(
                        (simdisplay, index) => (
                          <div
                            id={simdisplay.id}
                            className="drops"
                            key={simdisplay.id}
                            ref={
                              (commentbackid === simdisplay.id && scrollref) ||
                              alldisplay[alldisplay.length - 1]?.id ===
                                simdisplay.id
                                ? scrollref
                                : null
                            }
                            style={{
                              height:
                                simdisplay.file1?.path !== "" &&
                                simdisplay.file1?.path !== undefined &&
                                330,
                            }}
                          >
                            <div className="postlinetop">
                              <Link to={`/UserView/${simdisplay.Sender_id}`}>
                                <img
                                  src={simdisplay.Profile_image}
                                  className={
                                    simdisplay.Profile_image === "/Profile3.svg"
                                      ? "current3"
                                      : "current1"
                                  }
                                />
                              </Link>

                              {simdisplay.Comment.length >= 152 ||
                                (simdisplay.file1?.path && (
                                  <div
                                    className={
                                      simdisplay.Comment.length >= 20
                                        ? "text"
                                        : "text1"
                                    }
                                    style={{
                                      fontSize:
                                        simdisplay.Comment.length >= 152 && 12,
                                      width:
                                        simdisplay.Comment.length >= 152 &&
                                        "80%",
                                      marginTop:
                                        simdisplay.Comment.length >= 152 && 8,
                                      position:
                                        optionid[0] === simdisplay.id &&
                                        "absolute",
                                      left:
                                        optionid[0] === simdisplay.id &&
                                        simdisplay.Comment.length >= 20
                                          ? "20%"
                                          : `${
                                              49.5 - simdisplay.Comment.length
                                            }%`,
                                    }}
                                  >
                                    {simdisplay.Comment}
                                  </div>
                                ))}

                              {optionid[0] !== simdisplay.id && (
                                <img
                                  src="/DropOption.svg"
                                  className="postoption"
                                  id={[simdisplay.id, simdisplay.Sender_id]}
                                  onClick={OptionTerminal}
                                />
                              )}

                              {!deleteid && optionid[0] === simdisplay.id && (
                                <span className="Delete">
                                  {(id === simdisplay.Sender_id ||
                                    id === alldisplay[0].Directory) && (
                                    <button
                                      id={simdisplay.id}
                                      onClick={(e) =>
                                        setDeleteid(simdisplay.id)
                                      }
                                    >
                                      Delete
                                    </button>
                                  )}

                                  <button
                                    onClick={(e) => {
                                      setOptionId("");
                                      setDeleteid("");
                                    }}
                                  >
                                    Cancel
                                  </button>
                                </span>
                              )}

                              {deleteid === simdisplay.id && (
                                <span className="Delete">
                                  <button onClick={(e) => deletecomment(1)}>
                                    〇
                                  </button>
                                  <button onClick={(e) => setDeleteid("")}>
                                    X
                                  </button>
                                </span>
                              )}
                            </div>
                            <span className="textline">
                              <span
                                className="stylerank"
                                style={{
                                  marginLeft:
                                    simdisplay.Rank <= 9
                                      ? 19.4
                                      : simdisplay.Rank === 11
                                      ? 16
                                      : simdisplay.Rank <= 99
                                      ? 14.5
                                      : simdisplay.Rank > 99 && 10,
                                  flex:
                                    simdisplay.Comment.length >= 25
                                      ? 0.18
                                      : simdisplay.Comment.length === 20
                                      ? 0.14
                                      : 0.5,
                                  marginTop:
                                    simdisplay.Comment.length > 65 &&
                                    simdisplay.file1?.path
                                      ? -25
                                      : simdisplay.Comment.length >= 152
                                      ? -86.5
                                      : null,
                                }}
                              >
                                {simdisplay.Rank}
                              </span>
                              {simdisplay.Comment.length < 152 &&
                                !simdisplay.file1?.path && (
                                  <div
                                    className={
                                      simdisplay.Comment.length >= 20
                                        ? "text"
                                        : "text1"
                                    }
                                  >
                                    {simdisplay.Comment}
                                  </div>
                                )}
                            </span>
                            <div
                              className="alldrops"
                              onClick={(e) => display(simdisplay)}
                              style={{
                                height:
                                  //(post.image2 && 275) ||
                                  simdisplay.file1?.path && 295,
                              }}
                            ></div>

                            <div
                              style={{
                                marginLeft:
                                  !simdisplay.file3?.path &&
                                  simdisplay.file2?.path
                                    ? 40
                                    : simdisplay.file3?.path && 60,
                                marginTop: simdisplay.file3?.path && -10,
                                justifyContent:
                                  simdisplay.file3?.path &&
                                  !simdisplay.file4?.path &&
                                  "flex-start",
                              }}
                              className="postphotoline"
                            >
                              {simdisplay.file1?.filetype === "videos" ? (
                                <>
                                  <video
                                    controls={!simdisplay.file2.path && true}
                                    width={
                                      fullscreened
                                        ? "100%"
                                        : simdisplay.file2.path === ""
                                        ? 200
                                        : simdisplay.file4.path !== ""
                                        ? 130
                                        : 150
                                    }
                                    height={
                                      fullscreened
                                        ? "40%"
                                        : simdisplay.file2.path === ""
                                        ? 200
                                        : simdisplay.file4?.path !== ""
                                        ? 95
                                        : simdisplay.file3.path !== ""
                                        ? 195
                                        : 150
                                    }
                                    ref={(element) =>
                                      (droppedvideoref.current[index + 1] =
                                        element)
                                    }
                                    onTimeUpdate={Videotimeupdate}
                                    onEnded={
                                      simdisplay.file2.path
                                        ? (e) =>
                                            Droppedvideotimeupdate(e, index + 1)
                                        : undefined
                                    }
                                    playsInline
                                    muted
                                    loop={!simdisplay.file2.path && true}
                                    // loop={post.file2.filetype !== "videos" &&  post.file3.filetype !== "videos" && post.file4.filetype !== "videos" && true}

                                    id={simdisplay.file1.id}
                                    style={videohtml(
                                      simdisplay?.file2,
                                      simdisplay.file3,
                                      simdisplay.file4
                                    )}
                                  >
                                    <source
                                      type="video/mp4"
                                      src={simdisplay.file1?.path}
                                    />
                                  </video>
                                  {!isNaN(
                                    Array.isArray(
                                      droppedvideoref.current[index + 1]
                                    )
                                      ? droppedvideoref.current[index + 1][0]
                                          ?.duration
                                      : droppedvideoref.current[index + 1]
                                          ?.duration
                                  ) && (
                                    <span
                                      //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                      style={{
                                        bottom: !simdisplay.file2.filetype
                                          ? "18%"
                                          : !simdisplay.file3.filetype
                                          ? "27%"
                                          : simdisplay.file4.filetype
                                          ? "46%"
                                          : "16%",
                                        left:
                                          !simdisplay.file2.filetype ||
                                          simdisplay.file4.filetype
                                            ? "25%"
                                            : !simdisplay.file3.filetype
                                            ? "17%"
                                            : "23%",
                                        zIndex: 1,
                                        position: "absolute",
                                      }}
                                      className="videoduration"
                                    >
                                      {0 +
                                        Math.floor(
                                          (Array.isArray(
                                            droppedvideoref.current[index + 1]
                                          )
                                            ? droppedvideoref.current[
                                                index + 1
                                              ][0]
                                            : droppedvideoref.current[index + 1]
                                          )?.currentTime / 60
                                        )}
                                      :
                                      {(Array.isArray(
                                        droppedvideoref.current[index + 1]
                                      )
                                        ? droppedvideoref.current[index + 1][0]
                                        : droppedvideoref.current[index + 1]
                                      )?.currentTime < 10 && 0}
                                      {(Array.isArray(
                                        droppedvideoref.current[index + 1]
                                      )
                                        ? droppedvideoref.current[index + 1][0]
                                        : droppedvideoref.current[index + 1]
                                      )?.currentTime >= 60
                                        ? Math.floor(
                                            (Array.isArray(
                                              droppedvideoref.current[index + 1]
                                            )
                                              ? droppedvideoref.current[
                                                  index + 1
                                                ][0]
                                              : droppedvideoref.current[
                                                  index + 1
                                                ]
                                            )?.currentTime % 60
                                          )
                                        : Math.floor(
                                            (Array.isArray(
                                              droppedvideoref.current[index + 1]
                                            )
                                              ? droppedvideoref.current[
                                                  index + 1
                                                ][0]
                                              : droppedvideoref.current[
                                                  index + 1
                                                ]
                                            )?.currentTime
                                          )}
                                      <span
                                        style={{
                                          marginLeft: 5,
                                          marginRight: 5,
                                        }}
                                      >
                                        /
                                      </span>
                                      {0 +
                                        Math.floor(
                                          (Array.isArray(
                                            droppedvideoref.current[index + 1]
                                          )
                                            ? droppedvideoref.current[
                                                index + 1
                                              ][0]
                                            : droppedvideoref.current[index + 1]
                                          )?.duration / 60
                                        )}
                                      :
                                      {(Array.isArray(
                                        droppedvideoref.current[index + 1]
                                      )
                                        ? droppedvideoref.current[index + 1][0]
                                        : droppedvideoref.current[index + 1]
                                      )?.duration < 10 && 0}
                                      {(Array.isArray(
                                        droppedvideoref.current[index + 1]
                                      )
                                        ? droppedvideoref.current[index + 1][0]
                                        : droppedvideoref.current[index + 1]
                                      )?.duration >= 60
                                        ? Math.floor(
                                            (Array.isArray(
                                              droppedvideoref.current[index + 1]
                                            )
                                              ? droppedvideoref.current[
                                                  index + 1
                                                ][0]
                                              : droppedvideoref.current[
                                                  index + 1
                                                ]
                                            )?.duration % 60
                                          )
                                        : Math.floor(
                                            (Array.isArray(
                                              droppedvideoref.current[index + 1]
                                            )
                                              ? droppedvideoref.current[
                                                  index + 1
                                                ][0]
                                              : droppedvideoref.current[
                                                  index + 1
                                                ]
                                            )?.duration
                                          )}
                                    </span>
                                  )}
                                </>
                              ) : (
                                simdisplay.file1?.filetype === "images" && (
                                  <img
                                    className="postphoto"
                                    src={simdisplay.file1?.path}
                                    id={simdisplay.file1?.id}
                                    style={imagehtml(
                                      simdisplay.file2,
                                      simdisplay.file3,
                                      simdisplay.file4
                                    )}
                                  />
                                )
                              )}

                              {!simdisplay.file4?.path ? (
                                <div
                                  style={{
                                    display:
                                      simdisplay.file3?.path &&
                                      !simdisplay.file4?.path &&
                                      "flex",
                                    flexDirection:
                                      simdisplay.file3?.path &&
                                      !simdisplay.file4?.path &&
                                      "column",
                                  }}
                                >
                                  {simdisplay.file2?.filetype === "videos" ? (
                                    <>
                                      <video
                                        width={
                                          fullscreened
                                            ? "100%"
                                            : simdisplay.file3?.path
                                            ? 110
                                            : 150
                                        }
                                        height={
                                          fullscreened
                                            ? "40%"
                                            : simdisplay.file3?.path
                                            ? 95
                                            : 150
                                        }
                                        ref={(element) =>
                                          (droppedvideoref.current[index + 1] =
                                            simdisplay.file1.filetype ===
                                            "videos"
                                              ? [
                                                  droppedvideoref.current[
                                                    index + 1
                                                  ],
                                                  element,
                                                ]
                                              : element)
                                        }
                                        playsInline
                                        muted
                                        id={simdisplay.file2.id}
                                        style={videohtml(simdisplay.file1)}
                                        onTimeUpdate={Videotimeupdate}
                                        onEnded={(e) =>
                                          Droppedvideotimeupdate(e, index + 1)
                                        }
                                      >
                                        <source
                                          type="video/mp4"
                                          src={simdisplay.file2?.path}
                                        />
                                      </video>

                                      {droppedvideoref?.current[index + 1] !==
                                        null &&
                                        !isNaN(
                                          Array.isArray(
                                            droppedvideoref.current[index + 1]
                                          )
                                            ? droppedvideoref.current[
                                                index + 1
                                              ][0]?.duration
                                            : droppedvideoref.current[index + 1]
                                                ?.duration
                                        ) && (
                                          <span
                                            //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                            style={{
                                              bottom: !simdisplay.file3.filetype
                                                ? "27%"
                                                : "46%",
                                              left: !simdisplay.file3.filetype
                                                ? "59%"
                                                : "65%",
                                              zIndex: 1,
                                              position: "absolute",
                                            }}
                                            className="videoduration"
                                          >
                                            {0 +
                                              Math.floor(
                                                (simdisplay.file1.filetype ===
                                                "videos"
                                                  ? droppedvideoref?.current[
                                                      index + 1
                                                    ][1]
                                                  : Array.isArray(
                                                      droppedvideoref.current[
                                                        index + 1
                                                      ]
                                                    )
                                                  ? droppedvideoref?.current[
                                                      index + 1
                                                    ][0]
                                                  : droppedvideoref?.current[
                                                      index + 1
                                                    ]
                                                )?.currentTime / 60
                                              )}
                                            :
                                            {(simdisplay.file1.filetype ===
                                            "videos"
                                              ? droppedvideoref?.current[
                                                  index + 1
                                                ][1]
                                              : Array.isArray(
                                                  droppedvideoref.current[
                                                    index + 1
                                                  ]
                                                )
                                              ? droppedvideoref?.current[
                                                  index + 1
                                                ][0]
                                              : droppedvideoref?.current[
                                                  index + 1
                                                ]
                                            )?.currentTime < 10 && 0}
                                            {(simdisplay.file1.filetype ===
                                            "videos"
                                              ? droppedvideoref?.current[
                                                  index + 1
                                                ][1]
                                              : Array.isArray(
                                                  droppedvideoref.current[
                                                    index + 1
                                                  ]
                                                )
                                              ? droppedvideoref?.current[
                                                  index + 1
                                                ][0]
                                              : droppedvideoref?.current[
                                                  index + 1
                                                ]
                                            )?.currentTime >= 60
                                              ? Math.floor(
                                                  (simdisplay.file1.filetype ===
                                                  "videos"
                                                    ? droppedvideoref?.current[
                                                        index + 1
                                                      ][1]
                                                    : Array.isArray(
                                                        droppedvideoref.current[
                                                          index + 1
                                                        ]
                                                      )
                                                    ? droppedvideoref?.current[
                                                        index + 1
                                                      ][0]
                                                    : droppedvideoref?.current[
                                                        index + 1
                                                      ]
                                                  )?.currentTime % 60
                                                )
                                              : Math.floor(
                                                  (simdisplay.file1.filetype ===
                                                  "videos"
                                                    ? droppedvideoref?.current[
                                                        index + 1
                                                      ][1]
                                                    : Array.isArray(
                                                        droppedvideoref.current[
                                                          index + 1
                                                        ]
                                                      )
                                                    ? droppedvideoref?.current[
                                                        index + 1
                                                      ][0]
                                                    : droppedvideoref?.current[
                                                        index + 1
                                                      ]
                                                  )?.currentTime
                                                )}
                                            <span
                                              style={{
                                                marginLeft: 5,
                                                marginRight: 5,
                                              }}
                                            >
                                              /
                                            </span>
                                            {0 +
                                              Math.floor(
                                                (simdisplay.file1.filetype ===
                                                "videos"
                                                  ? droppedvideoref?.current[
                                                      index + 1
                                                    ][1]
                                                  : Array.isArray(
                                                      droppedvideoref.current[
                                                        index + 1
                                                      ]
                                                    )
                                                  ? droppedvideoref?.current[
                                                      index + 1
                                                    ][0]
                                                  : droppedvideoref?.current[
                                                      index + 1
                                                    ]
                                                )?.duration / 60
                                              )}
                                            :
                                            {(simdisplay.file1.filetype ===
                                            "videos"
                                              ? droppedvideoref?.current[
                                                  index + 1
                                                ][1]
                                              : Array.isArray(
                                                  droppedvideoref.current[
                                                    index + 1
                                                  ]
                                                )
                                              ? droppedvideoref?.current[
                                                  index + 1
                                                ][0]
                                              : droppedvideoref?.current[
                                                  index + 1
                                                ]
                                            )?.duration < 10 && 0}
                                            {(simdisplay.file1.filetype ===
                                            "videos"
                                              ? droppedvideoref?.current[
                                                  index + 1
                                                ][1]
                                              : Array.isArray(
                                                  droppedvideoref.current[
                                                    index + 1
                                                  ]
                                                )
                                              ? droppedvideoref?.current[
                                                  index + 1
                                                ][0]
                                              : droppedvideoref?.current[
                                                  index + 1
                                                ]
                                            )?.duration >= 60
                                              ? Math.floor(
                                                  (simdisplay.file1.filetype ===
                                                  "videos"
                                                    ? droppedvideoref?.current[
                                                        index + 1
                                                      ][1]
                                                    : Array.isArray(
                                                        droppedvideoref.current[
                                                          index + 1
                                                        ]
                                                      )
                                                    ? droppedvideoref?.current[
                                                        index + 1
                                                      ][0]
                                                    : droppedvideoref?.current[
                                                        index + 1
                                                      ]
                                                  )?.duration % 60
                                                )
                                              : Math.floor(
                                                  (simdisplay.file1.filetype ===
                                                  "videos"
                                                    ? droppedvideoref?.current[
                                                        index + 1
                                                      ][1]
                                                    : Array.isArray(
                                                        droppedvideoref.current[
                                                          index + 1
                                                        ]
                                                      )
                                                    ? droppedvideoref?.current[
                                                        index + 1
                                                      ][0]
                                                    : droppedvideoref?.current[
                                                        index + 1
                                                      ]
                                                  )?.duration
                                                )}
                                          </span>
                                        )}
                                    </>
                                  ) : (
                                    simdisplay.file2?.filetype === "images" && (
                                      <img
                                        className="postphoto"
                                        src={simdisplay.file2?.path}
                                        id={simdisplay.file2?.id}
                                        style={
                                          simdisplay.file3?.path
                                            ? {
                                                ...imagehtml(simdisplay.file1),
                                                width: 110,
                                                height:
                                                  simdisplay.file3.path && 95,
                                              }
                                            : imagehtml(simdisplay.file1)
                                        }
                                      />
                                    )
                                  )}

                                  {simdisplay.file3?.filetype === "videos" ? (
                                    <>
                                      <video
                                        width={fullscreened ? "100%" : 110}
                                        height={fullscreened ? "40%" : 95}
                                        ref={(element) =>
                                          (droppedvideoref.current[index + 1] =
                                            simdisplay.file1.filetype ===
                                              "videos" &&
                                            simdisplay.file2.filetype ===
                                              "videos"
                                              ? [
                                                  droppedvideoref.current[
                                                    index + 1
                                                  ][0],
                                                  droppedvideoref.current[
                                                    index + 1
                                                  ][1],
                                                  element,
                                                ]
                                              : simdisplay.file1.filetype !==
                                                  "videos" &&
                                                simdisplay.file2.filetype !==
                                                  "videos"
                                              ? element
                                              : simdisplay.file1.filetype !==
                                                  "videos" ||
                                                simdisplay.file2.filetype !==
                                                  "videos"
                                              ? [
                                                  droppedvideoref?.current[
                                                    index + 1
                                                  ],
                                                  element,
                                                ]
                                              : [])
                                        }
                                        playsInline
                                        muted
                                        id={simdisplay.file3.id}
                                        style={{
                                          ...videohtml(simdisplay.file1),
                                          marginTop: 4,
                                        }}
                                        onTimeUpdate={Videotimeupdate}
                                        onEnded={(e) =>
                                          Droppedvideotimeupdate(e, index + 1)
                                        }
                                      >
                                        <source
                                          type="video/mp4"
                                          src={simdisplay.file3?.path}
                                        />
                                      </video>

                                      {droppedvideoref?.current[index + 1] !==
                                        null &&
                                        !isNaN(
                                          Array.isArray(
                                            droppedvideoref.current[index + 1]
                                          )
                                            ? droppedvideoref.current[
                                                index + 1
                                              ][0]?.duration
                                            : droppedvideoref.current[index + 1]
                                                ?.duration
                                        ) && (
                                          <span
                                            //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                            style={{
                                              bottom: "16%",
                                              left: "65%",
                                              zIndex: 1,
                                              position: "absolute",
                                            }}
                                            className="videoduration"
                                          >
                                            {0 +
                                              Math.floor(
                                                determinevideoindex(
                                                  simdisplay.file1.filetype,
                                                  simdisplay.file2.filetype,
                                                  simdisplay.file4.filetype,
                                                  index + 1
                                                )?.currentTime / 60
                                              )}
                                            :
                                            {determinevideoindex(
                                              simdisplay.file1.filetype,
                                              simdisplay.file2.filetype,
                                              simdisplay.file4.filetype,
                                              index + 1
                                            )?.currentTime < 10 && 0}
                                            {determinevideoindex(
                                              simdisplay.file1.filetype,
                                              simdisplay.file2.filetype,
                                              simdisplay.file4.filetype,
                                              index + 1
                                            )?.currentTime >= 60
                                              ? Math.floor(
                                                  determinevideoindex(
                                                    simdisplay.file1.filetype,
                                                    simdisplay.file2.filetype,
                                                    simdisplay.file4.filetype,
                                                    index + 1
                                                  )?.currentTime % 60
                                                )
                                              : Math.floor(
                                                  determinevideoindex(
                                                    simdisplay.file1.filetype,
                                                    simdisplay.file2.filetype,
                                                    simdisplay.file4.filetype,
                                                    index + 1
                                                  )?.currentTime
                                                )}
                                            <span
                                              style={{
                                                marginLeft: 5,
                                                marginRight: 5,
                                              }}
                                            >
                                              /
                                            </span>
                                            {0 +
                                              Math.floor(
                                                determinevideoindex(
                                                  simdisplay.file1.filetype,
                                                  simdisplay.file2.filetype,
                                                  simdisplay.file4.filetype,
                                                  index + 1
                                                )?.duration / 60
                                              )}
                                            :
                                            {determinevideoindex(
                                              simdisplay.file1.filetype,
                                              simdisplay.file2.filetype,
                                              simdisplay.file4.filetype,
                                              index + 1
                                            )?.duration < 10 && 0}
                                            {determinevideoindex(
                                              simdisplay.file1.filetype,
                                              simdisplay.file2.filetype,
                                              simdisplay.file4.filetype,
                                              index + 1
                                            )?.duration >= 60
                                              ? Math.floor(
                                                  determinevideoindex(
                                                    simdisplay.file1.filetype,
                                                    simdisplay.file2.filetype,
                                                    simdisplay.file4.filetype,
                                                    index + 1
                                                  )?.duration % 60
                                                )
                                              : Math.floor(
                                                  determinevideoindex(
                                                    simdisplay.file1.filetype,
                                                    simdisplay.file2.filetype,
                                                    simdisplay.file4.filetype,
                                                    index + 1
                                                  )?.duration
                                                )}
                                          </span>
                                        )}
                                    </>
                                  ) : (
                                    simdisplay.file3?.filetype === "images" && (
                                      <img
                                        className="postphoto"
                                        src={simdisplay.file3?.path}
                                        id={simdisplay.file3?.id}
                                        style={{
                                          ...imagehtml(simdisplay.file1),
                                          width: !simdisplay.file4?.path
                                            ? 110
                                            : 130,
                                          height: 95,
                                          marginTop: 4,
                                        }}
                                      />
                                    )
                                  )}
                                </div>
                              ) : (
                                <>
                                  {simdisplay.file2?.filetype === "videos" ? (
                                    <>
                                      <video
                                        width={
                                          fullscreened
                                            ? "100%"
                                            : simdisplay.file4.path !== ""
                                            ? 130
                                            : simdisplay.file3.path
                                            ? 110
                                            : 150
                                        }
                                        height={
                                          fullscreened
                                            ? "40%"
                                            : simdisplay.file3.path
                                            ? 95
                                            : 150
                                        }
                                        ref={(element) =>
                                          (droppedvideoref.current[index + 1] =
                                            simdisplay.file1.filetype ===
                                            "videos"
                                              ? [
                                                  droppedvideoref.current[
                                                    index + 1
                                                  ],
                                                  element,
                                                ]
                                              : element)
                                        }
                                        playsInline
                                        muted
                                        id={simdisplay.file2.id}
                                        style={videohtml(simdisplay.file1)}
                                        onTimeUpdate={Videotimeupdate}
                                        onEnded={(e) =>
                                          Droppedvideotimeupdate(e, index + 1)
                                        }
                                      >
                                        <source
                                          type="video/mp4"
                                          src={simdisplay.file2?.path}
                                        />
                                      </video>

                                      {droppedvideoref?.current[index + 1] !==
                                        null &&
                                        !isNaN(
                                          Array.isArray(
                                            droppedvideoref.current[index + 1]
                                          )
                                            ? droppedvideoref.current[
                                                index + 1
                                              ][0]?.duration
                                            : droppedvideoref.current[index + 1]
                                                ?.duration
                                        ) && (
                                          <span
                                            //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                            style={{
                                              bottom: "46%",
                                              left: "62%",

                                              zIndex: 1,
                                              position: "absolute",
                                            }}
                                            className="videoduration"
                                          >
                                            {0 +
                                              Math.floor(
                                                (simdisplay.file1.filetype ===
                                                "videos"
                                                  ? droppedvideoref?.current[
                                                      index + 1
                                                    ][1]
                                                  : Array.isArray(
                                                      droppedvideoref.current[
                                                        index + 1
                                                      ]
                                                    )
                                                  ? droppedvideoref?.current[
                                                      index + 1
                                                    ][0]
                                                  : droppedvideoref?.current[
                                                      index + 1
                                                    ]
                                                )?.currentTime / 60
                                              )}
                                            :
                                            {(simdisplay.file1.filetype ===
                                            "videos"
                                              ? droppedvideoref?.current[
                                                  index + 1
                                                ][1]
                                              : Array.isArray(
                                                  droppedvideoref.current[
                                                    index + 1
                                                  ]
                                                )
                                              ? droppedvideoref?.current[
                                                  index + 1
                                                ][0]
                                              : droppedvideoref?.current[
                                                  index + 1
                                                ]
                                            )?.currentTime < 10 && 0}
                                            {(simdisplay.file1.filetype ===
                                            "videos"
                                              ? droppedvideoref?.current[
                                                  index + 1
                                                ][1]
                                              : Array.isArray(
                                                  droppedvideoref.current[
                                                    index + 1
                                                  ]
                                                )
                                              ? droppedvideoref?.current[
                                                  index + 1
                                                ][0]
                                              : droppedvideoref?.current[
                                                  index + 1
                                                ]
                                            )?.currentTime >= 60
                                              ? Math.floor(
                                                  (simdisplay.file1.filetype ===
                                                  "videos"
                                                    ? droppedvideoref?.current[
                                                        index + 1
                                                      ][1]
                                                    : Array.isArray(
                                                        droppedvideoref.current[
                                                          index + 1
                                                        ]
                                                      )
                                                    ? droppedvideoref?.current[
                                                        index + 1
                                                      ][0]
                                                    : droppedvideoref?.current[
                                                        index + 1
                                                      ]
                                                  )?.currentTime % 60
                                                )
                                              : Math.floor(
                                                  (simdisplay.file1.filetype ===
                                                  "videos"
                                                    ? droppedvideoref?.current[
                                                        index + 1
                                                      ][1]
                                                    : Array.isArray(
                                                        droppedvideoref.current[
                                                          index + 1
                                                        ]
                                                      )
                                                    ? droppedvideoref?.current[
                                                        index + 1
                                                      ][0]
                                                    : droppedvideoref?.current[
                                                        index + 1
                                                      ]
                                                  )?.currentTime
                                                )}
                                            <span
                                              style={{
                                                marginLeft: 5,
                                                marginRight: 5,
                                              }}
                                            >
                                              /
                                            </span>
                                            {0 +
                                              Math.floor(
                                                (simdisplay.file1.filetype ===
                                                "videos"
                                                  ? droppedvideoref?.current[
                                                      index + 1
                                                    ][1]
                                                  : Array.isArray(
                                                      droppedvideoref.current[
                                                        index + 1
                                                      ]
                                                    )
                                                  ? droppedvideoref?.current[
                                                      index + 1
                                                    ][0]
                                                  : droppedvideoref?.current[
                                                      index + 1
                                                    ]
                                                )?.duration / 60
                                              )}
                                            :
                                            {(simdisplay.file1.filetype ===
                                            "videos"
                                              ? droppedvideoref?.current[
                                                  index + 1
                                                ][1]
                                              : Array.isArray(
                                                  droppedvideoref.current[
                                                    index + 1
                                                  ]
                                                )
                                              ? droppedvideoref?.current[
                                                  index + 1
                                                ][0]
                                              : droppedvideoref?.current[
                                                  index + 1
                                                ]
                                            )?.duration < 10 && 0}
                                            {(simdisplay.file1.filetype ===
                                            "videos"
                                              ? droppedvideoref?.current[
                                                  index + 1
                                                ][1]
                                              : Array.isArray(
                                                  droppedvideoref.current[
                                                    index + 1
                                                  ]
                                                )
                                              ? droppedvideoref?.current[
                                                  index + 1
                                                ][0]
                                              : droppedvideoref?.current[
                                                  index + 1
                                                ]
                                            )?.duration >= 60
                                              ? Math.floor(
                                                  (simdisplay.file1.filetype ===
                                                  "videos"
                                                    ? droppedvideoref?.current[
                                                        index + 1
                                                      ][1]
                                                    : Array.isArray(
                                                        droppedvideoref.current[
                                                          index + 1
                                                        ]
                                                      )
                                                    ? droppedvideoref?.current[
                                                        index + 1
                                                      ][0]
                                                    : droppedvideoref?.current[
                                                        index + 1
                                                      ]
                                                  )?.duration % 60
                                                )
                                              : Math.floor(
                                                  (simdisplay.file1.filetype ===
                                                  "videos"
                                                    ? droppedvideoref?.current[
                                                        index + 1
                                                      ][1]
                                                    : Array.isArray(
                                                        droppedvideoref.current[
                                                          index + 1
                                                        ]
                                                      )
                                                    ? droppedvideoref?.current[
                                                        index + 1
                                                      ][0]
                                                    : droppedvideoref?.current[
                                                        index + 1
                                                      ]
                                                  )?.duration
                                                )}
                                          </span>
                                        )}
                                    </>
                                  ) : (
                                    simdisplay.file2?.filetype === "images" && (
                                      <img
                                        className="postphoto"
                                        src={simdisplay.file2?.path}
                                        id={simdisplay.file2?.id}
                                        style={
                                          simdisplay.file3?.path
                                            ? {
                                                ...imagehtml(simdisplay.file1),
                                                width:
                                                  (simdisplay.file4?.path &&
                                                    130) ||
                                                  (simdisplay.file3.path &&
                                                    110),
                                                height:
                                                  simdisplay.file3.path && 95,
                                              }
                                            : imagehtml(simdisplay.file1)
                                        }
                                      />
                                    )
                                  )}
                                  {simdisplay.file3?.filetype === "videos" ? (
                                    <>
                                      <video
                                        width={
                                          fullscreened
                                            ? "100%"
                                            : simdisplay.file4.path !== ""
                                            ? 130
                                            : 110
                                        }
                                        height={fullscreened ? "40%" : 95}
                                        ref={(element) =>
                                          (droppedvideoref.current[index + 1] =
                                            simdisplay.file1.filetype ===
                                              "videos" &&
                                            simdisplay.file2.filetype ===
                                              "videos"
                                              ? [
                                                  droppedvideoref.current[
                                                    index + 1
                                                  ][0],
                                                  droppedvideoref.current[
                                                    index + 1
                                                  ][1],
                                                  element,
                                                ]
                                              : simdisplay.file1.filetype !==
                                                  "videos" &&
                                                simdisplay.file2.filetype !==
                                                  "videos"
                                              ? element
                                              : simdisplay.file1.filetype !==
                                                  "videos" ||
                                                simdisplay.file2.filetype !==
                                                  "videos"
                                              ? [
                                                  droppedvideoref?.current[
                                                    index + 1
                                                  ],
                                                  element,
                                                ]
                                              : [])
                                        }
                                        playsInline
                                        muted
                                        id={simdisplay.file3.id}
                                        style={{
                                          ...videohtml(simdisplay.file1),

                                          marginTop: 4,
                                        }}
                                        onTimeUpdate={Videotimeupdate}
                                        onEnded={(e) =>
                                          Droppedvideotimeupdate(e, index + 1)
                                        }
                                      >
                                        <source
                                          type="video/mp4"
                                          src={simdisplay.file3?.path}
                                        />
                                      </video>

                                      {droppedvideoref?.current[index + 1] !==
                                        null &&
                                        !isNaN(
                                          Array.isArray(
                                            droppedvideoref.current[index + 1]
                                          )
                                            ? droppedvideoref.current[
                                                index + 1
                                              ][0]?.duration
                                            : droppedvideoref.current[index + 1]
                                                ?.duration
                                        ) && (
                                          <span
                                            //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                            style={{
                                              bottom: "16%",
                                              left: "26%",
                                              zIndex: 1,
                                              position: "absolute",
                                            }}
                                            className="videoduration"
                                          >
                                            {0 +
                                              Math.floor(
                                                determinevideoindex(
                                                  simdisplay.file1.filetype,
                                                  simdisplay.file2.filetype,
                                                  simdisplay.file4.filetype,
                                                  index + 1
                                                )?.currentTime / 60
                                              )}
                                            :
                                            {determinevideoindex(
                                              simdisplay.file1.filetype,
                                              simdisplay.file2.filetype,
                                              simdisplay.file4.filetype,
                                              index + 1
                                            )?.currentTime < 10 && 0}
                                            {determinevideoindex(
                                              simdisplay.file1.filetype,
                                              simdisplay.file2.filetype,
                                              simdisplay.file4.filetype,
                                              index + 1
                                            )?.currentTime >= 60
                                              ? Math.floor(
                                                  determinevideoindex(
                                                    simdisplay.file1.filetype,
                                                    simdisplay.file2.filetype,
                                                    simdisplay.file4.filetype,
                                                    index + 1
                                                  )?.currentTime % 60
                                                )
                                              : Math.floor(
                                                  determinevideoindex(
                                                    simdisplay.file1.filetype,
                                                    simdisplay.file2.filetype,
                                                    simdisplay.file4.filetype,
                                                    index + 1
                                                  )?.currentTime
                                                )}
                                            <span
                                              style={{
                                                marginLeft: 5,
                                                marginRight: 5,
                                              }}
                                            >
                                              /
                                            </span>
                                            {0 +
                                              Math.floor(
                                                determinevideoindex(
                                                  simdisplay.file1.filetype,
                                                  simdisplay.file2.filetype,
                                                  simdisplay.file4.filetype,
                                                  index + 1
                                                )?.duration / 60
                                              )}
                                            :
                                            {determinevideoindex(
                                              simdisplay.file1.filetype,
                                              simdisplay.file2.filetype,
                                              simdisplay.file4.filetype,
                                              index + 1
                                            )?.duration < 10 && 0}
                                            {determinevideoindex(
                                              simdisplay.file1.filetype,
                                              simdisplay.file2.filetype,
                                              simdisplay.file4.filetype,
                                              index + 1
                                            )?.duration >= 60
                                              ? Math.floor(
                                                  determinevideoindex(
                                                    simdisplay.file1.filetype,
                                                    simdisplay.file2.filetype,
                                                    simdisplay.file4.filetype,
                                                    index + 1
                                                  )?.duration % 60
                                                )
                                              : Math.floor(
                                                  determinevideoindex(
                                                    simdisplay.file1.filetype,
                                                    simdisplay.file2.filetype,
                                                    simdisplay.file4.filetype,
                                                    index + 1
                                                  )?.duration
                                                )}
                                          </span>
                                        )}
                                    </>
                                  ) : (
                                    simdisplay.file3?.filetype === "images" && (
                                      <img
                                        className="postphoto"
                                        src={simdisplay.file3?.path}
                                        id={simdisplay.file3?.id}
                                        style={{
                                          ...imagehtml(simdisplay.file1),
                                          width: !simdisplay.file4?.path
                                            ? 110
                                            : 130,
                                          height: 95,
                                          marginTop: 4,
                                        }}
                                      />
                                    )
                                  )}
                                </>
                              )}

                              {simdisplay.file4?.filetype === "videos" ? (
                                <>
                                  <video
                                    width={
                                      fullscreened
                                        ? "100%"
                                        : simdisplay.file4.path !== ""
                                        ? 130
                                        : 110
                                    }
                                    height={fullscreened ? "40%" : 95}
                                    ref={(element) =>
                                      (droppedvideoref.current[index + 1] =
                                        simdisplay.file1.filetype ===
                                          "videos" &&
                                        simdisplay.file2.filetype ===
                                          "videos" &&
                                        simdisplay.file3.filetype === "videos"
                                          ? [
                                              droppedvideoref.current[
                                                index + 1
                                              ][0],
                                              droppedvideoref.current[
                                                index + 1
                                              ][1],
                                              droppedvideoref.current[
                                                index + 1
                                              ][2],
                                              element,
                                            ]
                                          : simdisplay.file1.filetype !==
                                              "videos" &&
                                            simdisplay.file2.filetype !==
                                              "videos" &&
                                            simdisplay.file3.filetype !==
                                              "videos"
                                          ? element
                                          : simdisplay.file1.filetype !==
                                              "videos" ||
                                            simdisplay.file2.filetype !==
                                              "videos" ||
                                            simdisplay.file3.filetype !==
                                              "videos"
                                          ? [
                                              ...determinevideoref(
                                                simdisplay.file1.filetype,
                                                simdisplay.file2.filetype,
                                                simdisplay.file3.filetype,
                                                index + 1
                                              ),
                                              element,
                                            ]
                                          : [])
                                    }
                                    playsInline
                                    muted
                                    id={simdisplay.file4.id}
                                    style={{
                                      ...videohtml(simdisplay.file1),

                                      marginTop: 4,
                                    }}
                                    onTimeUpdate={Videotimeupdate}
                                    onEnded={(e) =>
                                      Droppedvideotimeupdate(e, index + 1)
                                    }
                                  >
                                    <source
                                      type="video/mp4"
                                      src={simdisplay.file4?.path}
                                    />
                                  </video>

                                  {droppedvideoref?.current[index + 1] !==
                                    null &&
                                    !isNaN(
                                      Array.isArray(
                                        droppedvideoref.current[index + 1]
                                      )
                                        ? droppedvideoref.current[index + 1][0]
                                            ?.duration
                                        : droppedvideoref.current[index + 1]
                                            ?.duration
                                    ) && (
                                      <span
                                        //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                        style={{
                                          bottom: "16%",
                                          left: "61.5%",
                                          zIndex: 1,
                                          position: "absolute",
                                        }}
                                        className="videoduration"
                                      >
                                        {0 +
                                          Math.floor(
                                            determinefourthvideoindex(
                                              simdisplay.file1.filetype,
                                              simdisplay.file2.filetype,
                                              simdisplay.file3.filetype,
                                              index + 1
                                            )?.currentTime / 60
                                          )}
                                        :
                                        {determinefourthvideoindex(
                                          simdisplay.file1.filetype,
                                          simdisplay.file2.filetype,
                                          simdisplay.file3.filetype,
                                          index + 1
                                        )?.currentTime < 10 && 0}
                                        {determinefourthvideoindex(
                                          simdisplay.file1.filetype,
                                          simdisplay.file2.filetype,
                                          simdisplay.file3.filetype,
                                          index + 1
                                        )?.currentTime >= 60
                                          ? Math.floor(
                                              determinefourthvideoindex(
                                                simdisplay.file1.filetype,
                                                simdisplay.file2.filetype,
                                                simdisplay.file3.filetype,
                                                index + 1
                                              )?.currentTime % 60
                                            )
                                          : Math.floor(
                                              determinefourthvideoindex(
                                                simdisplay.file1.filetype,
                                                simdisplay.file2.filetype,
                                                simdisplay.file3.filetype,
                                                index + 1
                                              )?.currentTime
                                            )}
                                        <span
                                          style={{
                                            marginLeft: 5,
                                            marginRight: 5,
                                          }}
                                        >
                                          /
                                        </span>
                                        {0 +
                                          Math.floor(
                                            determinefourthvideoindex(
                                              simdisplay.file1.filetype,
                                              simdisplay.file2.filetype,
                                              simdisplay.file3.filetype,
                                              index + 1
                                            )?.duration / 60
                                          )}
                                        :
                                        {determinefourthvideoindex(
                                          simdisplay.file1.filetype,
                                          simdisplay.file2.filetype,
                                          simdisplay.file3.filetype,
                                          index + 1
                                        )?.duration < 10 && 0}
                                        {determinefourthvideoindex(
                                          simdisplay.file1.filetype,
                                          simdisplay.file2.filetype,
                                          simdisplay.file3.filetype,
                                          index + 1
                                        )?.duration >= 60
                                          ? Math.floor(
                                              determinefourthvideoindex(
                                                simdisplay.file1.filetype,
                                                simdisplay.file2.filetype,
                                                simdisplay.file3.filetype,
                                                index + 1
                                              )?.duration % 60
                                            )
                                          : Math.floor(
                                              determinefourthvideoindex(
                                                simdisplay.file1.filetype,
                                                simdisplay.file2.filetype,
                                                simdisplay.file3.filetype,
                                                index + 1
                                              )?.duration
                                            )}
                                      </span>
                                    )}
                                </>
                              ) : (
                                simdisplay.file4?.filetype === "images" && (
                                  <img
                                    src={simdisplay.file4?.path}
                                    className="postphoto"
                                    id={simdisplay.file4?.id}
                                    style={{
                                      ...imagehtml(simdisplay.file1),
                                      width: 130,
                                      height: 95,
                                      marginTop: 4,
                                    }}
                                  />
                                )
                              )}
                            </div>

                            <div className="date">
                              {simdisplay.year}/{simdisplay.month}/
                              {simdisplay.date}
                              <span className="Timesplit">|</span>
                              <span className="Time">
                                {simdisplay.hour}:{simdisplay.minutes}
                              </span>
                            </div>

                            <img
                              style={{ top: simdisplay.file1?.path && 51 }}
                              className="Rankmarked"
                              src="/RankMark.svg"
                            />
                            <span className="dropoption">
                              <span className="commentgroup">
                                <img
                                  className="commenttap"
                                  src="./Comment1.svg"
                                  id={[simdisplay.id, simdisplay.Sender_id]}
                                  onClick={comment}
                                />
                                <span className="commentcount">
                                  {simdisplay.Comment_Counter == 0
                                    ? null
                                    : simdisplay.Comment_Counter}
                                </span>
                              </span>
                              <span className="redropline">
                                {simdisplay.Redropped == "done" ? (
                                  <>
                                    <img
                                      src="./Redrop.svg"
                                      className="Redrop"
                                      //couldnt we combine the id for redrop and askunredrop
                                      onClick={AskUnredrop}
                                      id={[simdisplay.id, simdisplay.Sender_id]}
                                    />
                                  </>
                                ) : (
                                  <img
                                    className="Redrop"
                                    src="./PlaneRedrop4.svg"
                                    id={[
                                      simdisplay.id,
                                      simdisplay.Sender_id,
                                      /*
                            simpost.Rank,
                            simpost.Profile_image,
                            simpost.Comment,
                            simpost.Comment_Counter,
                            simpost.hour,
                            simpost.minutes,
                            simpost.year,
                            simpost.month,
                            simpost.date,
                            simpost.image1,
                            simpost.image2,
                            simpost.image3,
                            simpost.image4,
                            simpost.video,
                            simpost.Redropper_id,
                            simpost.Redropped_Counter,
                            simpost.Sent_id,
                            simpost.Directory,
                            simpost.This_Directory,
                            */
                                    ]}
                                    onClick={redrop}
                                  />
                                )}
                                <span className="commentcount">
                                  {simdisplay.Redropped_Counter == 0
                                    ? null
                                    : simdisplay.Redropped_Counter}
                                </span>
                              </span>
                            </span>
                            {redroppoint && simdisplay.id === redropid[0] && (
                              <span
                                style={{ fontSize: 8, bottom: "5%" }}
                                className="Pselect"
                              >
                                <button
                                  style={{
                                    left: "30%",
                                    fontSize: 9,
                                    color: "white",
                                    bottom: simdisplay.file1?.path
                                      ? "3%"
                                      : "5%",
                                  }}
                                  onClick={(e) => confirmredrop(simdisplay)}
                                >
                                  リドロップ
                                </button>
                                <button
                                  style={{
                                    left: "30%",
                                    fontSize: 9,
                                    marginLeft: 85,
                                    bottom: simdisplay.file1?.path
                                      ? "3%"
                                      : "5%",
                                  }}
                                  onClick={(e) => {
                                    setRedroppoint(false);
                                  }}
                                >
                                  キャンセル
                                </button>
                              </span>
                            )}
                            {unredroppoint &&
                              simdisplay.id === unredropid[0] && (
                                <span
                                  style={{ fontSize: 8, bottom: "5%" }}
                                  className="Pselect"
                                >
                                  <button
                                    style={{
                                      left: "30%",
                                      fontSize: 9,
                                      color: "white",
                                      bottom: simdisplay.file1?.path
                                        ? "3%"
                                        : "5%",
                                    }}
                                    onClick={(e) =>
                                      ConfirmUnredrop(
                                        simdisplay.OriginalTimestamp
                                      )
                                    }
                                  >
                                    リドロップ解除
                                  </button>
                                  <button
                                    style={{
                                      left: "30%",
                                      fontSize: 9,
                                      marginLeft: 85,
                                      bottom: simdisplay.file1?.path
                                        ? "3%"
                                        : "5%",
                                    }}
                                    onClick={CancelUnredrop}
                                  >
                                    キャンセル
                                  </button>
                                </span>
                              )}
                            {blockid === simdisplay.id && (
                              <div className="Blockposition">
                                <Block
                                  style={{ width: "80%" }}
                                  // style={{ zIndex: 8 }}
                                  understandblocked={understandblocked}
                                />
                              </div>
                            )}
                          </div>
                        )
                      )}
                    </>
                  ) : (
                    <>
                      <div
                        id={clickedcomment.id}
                        className="drops"
                        // key={clickedcomment.id}
                        style={{
                          height:
                            (clickedcomment.image2 && 310) ||
                            ((clickedcomment.image1 ||
                              (clickedcomment.file1?.path !== "" &&
                                clickedcomment.file1?.path !== undefined)) &&
                              330),
                        }}
                      >
                        <div className="postlinetop">
                          <Link
                            to={`/UserView/${
                              clickedcomment.Sender_id
                                ? clickedcomment.Sender_id
                                : clickedcomment.Directory
                            }`}
                          >
                            <img
                              className={
                                clickedcomment.Profile_image === "/Profile3.svg"
                                  ? "current3"
                                  : "current1"
                              }
                              src={clickedcomment.Profile_image}
                            />
                          </Link>

                          {(clickedcomment.image1 ||
                            clickedcomment.file1?.path) && (
                            <div
                              style={{
                                fontSize:
                                  (
                                    clickedcomment.text ||
                                    clickedcomment.Comment
                                  )?.length >= 142 && 10.5,
                                position:
                                  optionid[0] === clickedcomment.id &&
                                  "absolute",
                                left:
                                  optionid[0] === clickedcomment.id &&
                                  (
                                    clickedcomment.text ||
                                    clickedcomment.Comment
                                  )?.length >= 23
                                    ? "20%"
                                    : `${
                                        49.5 -
                                        (
                                          clickedcomment.text ||
                                          clickedcomment.Comment
                                        )?.length
                                      }%`,
                              }}
                              className={
                                (clickedcomment.text || clickedcomment.Comment)
                                  ?.length >= 23
                                  ? "text"
                                  : "text1"
                              }
                            >
                              {clickedcomment.text || clickedcomment.Comment}
                            </div>
                          )}

                          {optionid[0] !== clickedcomment.id && (
                            <img
                              src="/DropOption.svg"
                              id={[clickedcomment.id, clickedcomment.Sender_id]}
                              className="postoption"
                              onClick={OptionTerminal}
                            />
                          )}

                          {!deleteid && optionid[0] === clickedcomment.id && (
                            <span className="Delete">
                              {(id === clickedcomment.Sender_id ||
                                id === alldisplay[0]?.Directory ||
                                id === clickedcomment.Directory) && (
                                <button
                                  id={clickedcomment.id}
                                  onClick={(e) =>
                                    setDeleteid(clickedcomment.id)
                                  }
                                >
                                  Delete
                                </button>
                              )}

                              <button
                                onClick={(e) => {
                                  setOptionId("");
                                  setDeleteid("");
                                }}
                              >
                                Cancel
                              </button>
                            </span>
                          )}

                          {deleteid === clickedcomment.id && (
                            <span className="Delete">
                              <button
                                id={clickedcomment.id}
                                onClick={
                                  alldisplay.length === 0 ||
                                  alldisplay[0].id === clickedcomment.id
                                    ? deletedrop
                                    : clickedcomment.id === alldisplay.at(-1).id
                                    ? (e) => deletecomment(2)
                                    : (e) => deletecomment(1)
                                }
                              >
                                〇
                              </button>

                              <button onClick={(e) => setDeleteid("")}>
                                X
                              </button>
                            </span>
                          )}
                        </div>
                        <span className="textline">
                          <span
                            className="stylerank"
                            style={{
                              marginLeft:
                                clickedcomment.Rank <= 9
                                  ? 19.4
                                  : clickedcomment.Rank === 11
                                  ? 16
                                  : null,
                              flex:
                                (clickedcomment.text || clickedcomment.Comment)
                                  ?.length >= 25
                                  ? 0.18
                                  : (
                                      clickedcomment.text ||
                                      clickedcomment.Comment
                                    )?.length === 20
                                  ? 0.14
                                  : 0.5,
                              marginTop:
                                (clickedcomment.text || clickedcomment.Comment)
                                  ?.length > 65 &&
                                (clickedcomment.image1 ||
                                  clickedcomment.file1?.path)
                                  ? -25
                                  : null,
                            }}
                          >
                            {clickedcomment.Rank}
                          </span>

                          {!clickedcomment.image1 &&
                            !clickedcomment.file1?.path &&
                            (clickedcomment.text || clickedcomment.Comment)
                              ?.length < 152 && (
                              <div
                                className={
                                  (
                                    clickedcomment.text ||
                                    clickedcomment.Comment
                                  )?.length >= 23
                                    ? "text"
                                    : "text1"
                                }
                              >
                                {clickedcomment.text || clickedcomment.Comment}
                              </div>
                            )}
                        </span>

                        <div
                          style={{
                            marginLeft:
                              !clickedcomment.file3?.path &&
                              clickedcomment.file2?.path
                                ? 40
                                : clickedcomment.file3?.path && 60,
                            marginTop: clickedcomment.file3?.path && -10,
                            justifyContent:
                              clickedcomment.file3?.path &&
                              !clickedcomment.file4?.path &&
                              "flex-start",
                          }}
                          className="postphotoline"
                        >
                          {clickedcomment.file1?.filetype === "videos" ? (
                            <>
                              <video
                                controls={!clickedcomment.file2.path && true}
                                width={
                                  fullscreened
                                    ? "100%"
                                    : clickedcomment.file2.path === ""
                                    ? 200
                                    : clickedcomment.file4.path !== ""
                                    ? 130
                                    : 150
                                }
                                height={
                                  fullscreened
                                    ? "40%"
                                    : clickedcomment.file2.path === ""
                                    ? 200
                                    : clickedcomment.file4?.path !== ""
                                    ? 95
                                    : clickedcomment.file3.path !== ""
                                    ? 195
                                    : 150
                                }
                                ref={(element) =>
                                  (droppedvideoref.current[0] = element)
                                }
                                onTimeUpdate={Videotimeupdate}
                                onEnded={
                                  clickedcomment.file2.path
                                    ? (e) => Droppedvideotimeupdate(e, 0)
                                    : undefined
                                }
                                playsInline
                                muted
                                loop={!clickedcomment.file2.path && true}
                                // loop={post.file2.filetype !== "videos" &&  post.file3.filetype !== "videos" && post.file4.filetype !== "videos" && true}

                                id={clickedcomment.file1.id}
                                style={videohtml(
                                  clickedcomment.file2,
                                  clickedcomment.file3,
                                  clickedcomment.file4
                                )}
                              >
                                <source
                                  type="video/mp4"
                                  src={clickedcomment.file1?.path}
                                />
                              </video>
                              {!isNaN(
                                Array.isArray(droppedvideoref.current[0])
                                  ? droppedvideoref.current[0][0]?.duration
                                  : droppedvideoref.current[0]?.duration
                              ) && (
                                <span
                                  //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                  style={{
                                    bottom: !clickedcomment.file2.filetype
                                      ? "18%"
                                      : !clickedcomment.file3.filetype
                                      ? "27%"
                                      : clickedcomment.file4.filetype
                                      ? "46%"
                                      : "16%",
                                    left:
                                      !clickedcomment.file2.filetype ||
                                      clickedcomment.file4.filetype
                                        ? "25%"
                                        : !clickedcomment.file3.filetype
                                        ? "17%"
                                        : "23%",
                                    zIndex: 1,
                                    position: "absolute",
                                  }}
                                  className="videoduration"
                                >
                                  {0 +
                                    Math.floor(
                                      (Array.isArray(droppedvideoref.current[0])
                                        ? droppedvideoref.current[0][0]
                                        : droppedvideoref.current[0]
                                      )?.currentTime / 60
                                    )}
                                  :
                                  {(Array.isArray(droppedvideoref.current[0])
                                    ? droppedvideoref.current[0][0]
                                    : droppedvideoref.current[0]
                                  )?.currentTime < 10 && 0}
                                  {(Array.isArray(droppedvideoref.current[0])
                                    ? droppedvideoref.current[0][0]
                                    : droppedvideoref.current[0]
                                  )?.currentTime >= 60
                                    ? Math.floor(
                                        (Array.isArray(
                                          droppedvideoref.current[0]
                                        )
                                          ? droppedvideoref.current[0][0]
                                          : droppedvideoref.current[0]
                                        )?.currentTime % 60
                                      )
                                    : Math.floor(
                                        (Array.isArray(
                                          droppedvideoref.current[0]
                                        )
                                          ? droppedvideoref.current[0][0]
                                          : droppedvideoref.current[0]
                                        )?.currentTime
                                      )}
                                  <span
                                    style={{ marginLeft: 5, marginRight: 5 }}
                                  >
                                    /
                                  </span>
                                  {0 +
                                    Math.floor(
                                      (Array.isArray(droppedvideoref.current[0])
                                        ? droppedvideoref.current[0][0]
                                        : droppedvideoref.current[0]
                                      )?.duration / 60
                                    )}
                                  :
                                  {(Array.isArray(droppedvideoref.current[0])
                                    ? droppedvideoref.current[0][0]
                                    : droppedvideoref.current[0]
                                  )?.duration < 10 && 0}
                                  {(Array.isArray(droppedvideoref.current[0])
                                    ? droppedvideoref.current[0][0]
                                    : droppedvideoref.current[0]
                                  )?.duration >= 60
                                    ? Math.floor(
                                        (Array.isArray(
                                          droppedvideoref.current[0]
                                        )
                                          ? droppedvideoref.current[0][0]
                                          : droppedvideoref.current[0]
                                        )?.duration % 60
                                      )
                                    : Math.floor(
                                        (Array.isArray(
                                          droppedvideoref.current[0]
                                        )
                                          ? droppedvideoref.current[0][0]
                                          : droppedvideoref.current[0]
                                        )?.duration
                                      )}
                                </span>
                              )}
                            </>
                          ) : (
                            clickedcomment.file1?.filetype === "images" && (
                              <img
                                className="postphoto"
                                src={clickedcomment.file1?.path}
                                id={clickedcomment.file1?.id}
                                style={imagehtml(
                                  clickedcomment.file2,
                                  clickedcomment.file3,
                                  clickedcomment.file4
                                )}
                              />
                            )
                          )}

                          {!clickedcomment.file4?.path ? (
                            <div
                              style={{
                                display:
                                  clickedcomment.file3?.path &&
                                  !clickedcomment.file4?.path &&
                                  "flex",
                                flexDirection:
                                  clickedcomment.file3?.path &&
                                  !clickedcomment.file4?.path &&
                                  "column",
                              }}
                            >
                              {clickedcomment.file2?.filetype === "videos" ? (
                                <>
                                  {console.log(droppedvideoref.current)}
                                  <video
                                    width={
                                      fullscreened
                                        ? "100%"
                                        : clickedcomment.file3?.path
                                        ? 110
                                        : 150
                                    }
                                    height={
                                      fullscreened
                                        ? "40%"
                                        : clickedcomment.file3?.path
                                        ? 95
                                        : 150
                                    }
                                    ref={(element) =>
                                      (droppedvideoref.current[0] =
                                        clickedcomment.file1.filetype ===
                                        "videos"
                                          ? [
                                              droppedvideoref.current[0],
                                              element,
                                            ]
                                          : element)
                                    }
                                    playsInline
                                    muted
                                    id={clickedcomment.file2.id}
                                    style={videohtml(clickedcomment.file1)}
                                    onTimeUpdate={Videotimeupdate}
                                    onEnded={(e) =>
                                      Droppedvideotimeupdate(e, 0)
                                    }
                                  >
                                    <source
                                      type="video/mp4"
                                      src={clickedcomment.file2?.path}
                                    />
                                  </video>

                                  {droppedvideoref?.current[0] !== null &&
                                    !isNaN(
                                      Array.isArray(droppedvideoref.current[0])
                                        ? droppedvideoref.current[0][0]
                                            ?.duration
                                        : droppedvideoref.current[0]?.duration
                                    ) && (
                                      <span
                                        //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                        style={{
                                          bottom: !clickedcomment.file3.filetype
                                            ? "27%"
                                            : "46%",
                                          left: !clickedcomment.file3.filetype
                                            ? "59%"
                                            : "65%",
                                          zIndex: 1,
                                          position: "absolute",
                                        }}
                                        className="videoduration"
                                      >
                                        {0 +
                                          Math.floor(
                                            (clickedcomment.file1.filetype ===
                                            "videos"
                                              ? droppedvideoref?.current[0][1]
                                              : Array.isArray(
                                                  droppedvideoref.current[0]
                                                )
                                              ? droppedvideoref?.current[0][0]
                                              : droppedvideoref?.current[0]
                                            )?.currentTime / 60
                                          )}
                                        :
                                        {(clickedcomment.file1.filetype ===
                                        "videos"
                                          ? droppedvideoref?.current[0][1]
                                          : Array.isArray(
                                              droppedvideoref.current[0]
                                            )
                                          ? droppedvideoref?.current[0][0]
                                          : droppedvideoref?.current[0]
                                        )?.currentTime < 10 && 0}
                                        {(clickedcomment.file1.filetype ===
                                        "videos"
                                          ? droppedvideoref?.current[0][1]
                                          : Array.isArray(
                                              droppedvideoref.current[0]
                                            )
                                          ? droppedvideoref?.current[0][0]
                                          : droppedvideoref?.current[0]
                                        )?.currentTime >= 60
                                          ? Math.floor(
                                              (clickedcomment.file1.filetype ===
                                              "videos"
                                                ? droppedvideoref?.current[0][1]
                                                : Array.isArray(
                                                    droppedvideoref.current[0]
                                                  )
                                                ? droppedvideoref?.current[0][0]
                                                : droppedvideoref?.current[0]
                                              )?.currentTime % 60
                                            )
                                          : Math.floor(
                                              (clickedcomment.file1.filetype ===
                                              "videos"
                                                ? droppedvideoref?.current[0][1]
                                                : Array.isArray(
                                                    droppedvideoref.current[0]
                                                  )
                                                ? droppedvideoref?.current[0][0]
                                                : droppedvideoref?.current[0]
                                              )?.currentTime
                                            )}
                                        <span
                                          style={{
                                            marginLeft: 5,
                                            marginRight: 5,
                                          }}
                                        >
                                          /
                                        </span>
                                        {0 +
                                          Math.floor(
                                            (clickedcomment.file1.filetype ===
                                            "videos"
                                              ? droppedvideoref?.current[0][1]
                                              : Array.isArray(
                                                  droppedvideoref.current[0]
                                                )
                                              ? droppedvideoref?.current[0][0]
                                              : droppedvideoref?.current[0]
                                            )?.duration / 60
                                          )}
                                        :
                                        {(clickedcomment.file1.filetype ===
                                        "videos"
                                          ? droppedvideoref?.current[0][1]
                                          : Array.isArray(
                                              droppedvideoref.current[0]
                                            )
                                          ? droppedvideoref?.current[0][0]
                                          : droppedvideoref?.current[0]
                                        )?.duration < 10 && 0}
                                        {(clickedcomment.file1.filetype ===
                                        "videos"
                                          ? droppedvideoref?.current[0][1]
                                          : Array.isArray(
                                              droppedvideoref.current[0]
                                            )
                                          ? droppedvideoref?.current[0][0]
                                          : droppedvideoref?.current[0]
                                        )?.duration >= 60
                                          ? Math.floor(
                                              (clickedcomment.file1.filetype ===
                                              "videos"
                                                ? droppedvideoref?.current[0][1]
                                                : Array.isArray(
                                                    droppedvideoref.current[0]
                                                  )
                                                ? droppedvideoref?.current[0][0]
                                                : droppedvideoref?.current[0]
                                              )?.duration % 60
                                            )
                                          : Math.floor(
                                              (clickedcomment.file1.filetype ===
                                              "videos"
                                                ? droppedvideoref?.current[0][1]
                                                : Array.isArray(
                                                    droppedvideoref.current[0]
                                                  )
                                                ? droppedvideoref?.current[0][0]
                                                : droppedvideoref?.current[0]
                                              )?.duration
                                            )}
                                      </span>
                                    )}
                                </>
                              ) : (
                                clickedcomment.file2?.filetype === "images" && (
                                  <img
                                    className="postphoto"
                                    src={clickedcomment.file2?.path}
                                    id={clickedcomment.file2?.id}
                                    style={
                                      clickedcomment.file3?.path
                                        ? {
                                            ...imagehtml(clickedcomment.file1),
                                            width: 110,
                                            height:
                                              clickedcomment.file3.path && 95,
                                          }
                                        : imagehtml(clickedcomment.file1)
                                    }
                                  />
                                )
                              )}

                              {clickedcomment.file3?.filetype === "videos" ? (
                                <>
                                  <video
                                    width={fullscreened ? "100%" : 110}
                                    height={fullscreened ? "40%" : 95}
                                    ref={(element) =>
                                      (droppedvideoref.current[0] =
                                        clickedcomment.file1.filetype ===
                                          "videos" &&
                                        clickedcomment.file2.filetype ===
                                          "videos"
                                          ? [
                                              droppedvideoref.current[0][0],
                                              droppedvideoref.current[0][1],
                                              element,
                                            ]
                                          : clickedcomment.file1.filetype !==
                                              "videos" &&
                                            clickedcomment.file2.filetype !==
                                              "videos"
                                          ? element
                                          : clickedcomment.file1.filetype !==
                                              "videos" ||
                                            clickedcomment.file2.filetype !==
                                              "videos"
                                          ? [
                                              droppedvideoref?.current[0],
                                              element,
                                            ]
                                          : [])
                                    }
                                    playsInline
                                    muted
                                    id={clickedcomment.file3.id}
                                    style={{
                                      ...videohtml(clickedcomment.file1),
                                      marginTop: 4,
                                    }}
                                    onTimeUpdate={Videotimeupdate}
                                    onEnded={(e) =>
                                      Droppedvideotimeupdate(e, 0)
                                    }
                                  >
                                    <source
                                      type="video/mp4"
                                      src={clickedcomment.file3?.path}
                                    />
                                  </video>

                                  {droppedvideoref?.current[0] !== null &&
                                    !isNaN(
                                      Array.isArray(droppedvideoref.current[0])
                                        ? droppedvideoref.current[0][0]
                                            ?.duration
                                        : droppedvideoref.current[0]?.duration
                                    ) && (
                                      <span
                                        //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                        style={{
                                          bottom: "16%",
                                          left: "65%",
                                          zIndex: 1,
                                          position: "absolute",
                                        }}
                                        className="videoduration"
                                      >
                                        {0 +
                                          Math.floor(
                                            determinevideoindex(
                                              clickedcomment.file1.filetype,
                                              clickedcomment.file2.filetype,
                                              clickedcomment.file4.filetype,
                                              0
                                            )?.currentTime / 60
                                          )}
                                        :
                                        {determinevideoindex(
                                          clickedcomment.file1.filetype,
                                          clickedcomment.file2.filetype,
                                          clickedcomment.file4.filetype,
                                          0
                                        )?.currentTime < 10 && 0}
                                        {determinevideoindex(
                                          clickedcomment.file1.filetype,
                                          clickedcomment.file2.filetype,
                                          clickedcomment.file4.filetype,
                                          0
                                        )?.currentTime >= 60
                                          ? Math.floor(
                                              determinevideoindex(
                                                clickedcomment.file1.filetype,
                                                clickedcomment.file2.filetype,
                                                clickedcomment.file4.filetype,
                                                0
                                              )?.currentTime % 60
                                            )
                                          : Math.floor(
                                              determinevideoindex(
                                                clickedcomment.file1.filetype,
                                                clickedcomment.file2.filetype,
                                                clickedcomment.file4.filetype,
                                                0
                                              )?.currentTime
                                            )}
                                        <span
                                          style={{
                                            marginLeft: 5,
                                            marginRight: 5,
                                          }}
                                        >
                                          /
                                        </span>
                                        {0 +
                                          Math.floor(
                                            determinevideoindex(
                                              clickedcomment.file1.filetype,
                                              clickedcomment.file2.filetype,
                                              clickedcomment.file4.filetype,
                                              0
                                            )?.duration / 60
                                          )}
                                        :
                                        {determinevideoindex(
                                          clickedcomment.file1.filetype,
                                          clickedcomment.file2.filetype,
                                          clickedcomment.file4.filetype,
                                          0
                                        )?.duration < 10 && 0}
                                        {determinevideoindex(
                                          clickedcomment.file1.filetype,
                                          clickedcomment.file2.filetype,
                                          clickedcomment.file4.filetype,
                                          0
                                        )?.duration >= 60
                                          ? Math.floor(
                                              determinevideoindex(
                                                clickedcomment.file1.filetype,
                                                clickedcomment.file2.filetype,
                                                clickedcomment.file4.filetype,
                                                0
                                              )?.duration % 60
                                            )
                                          : Math.floor(
                                              determinevideoindex(
                                                clickedcomment.file1.filetype,
                                                clickedcomment.file2.filetype,
                                                clickedcomment.file4.filetype,
                                                0
                                              )?.duration
                                            )}
                                      </span>
                                    )}
                                </>
                              ) : (
                                clickedcomment.file3?.filetype === "images" && (
                                  <img
                                    className="postphoto"
                                    src={clickedcomment.file3?.path}
                                    id={clickedcomment.file3?.id}
                                    style={{
                                      ...imagehtml(clickedcomment.file1),
                                      width: !clickedcomment.file4?.path
                                        ? 110
                                        : 130,
                                      height: 95,
                                      marginTop: 4,
                                    }}
                                  />
                                )
                              )}
                            </div>
                          ) : (
                            <>
                              {clickedcomment.file2?.filetype === "videos" ? (
                                <>
                                  <video
                                    width={
                                      fullscreened
                                        ? "100%"
                                        : clickedcomment.file4.path !== ""
                                        ? 130
                                        : clickedcomment.file3.path
                                        ? 110
                                        : 150
                                    }
                                    height={
                                      fullscreened
                                        ? "40%"
                                        : clickedcomment.file3.path
                                        ? 95
                                        : 150
                                    }
                                    ref={(element) =>
                                      (droppedvideoref.current[0] =
                                        clickedcomment.file1.filetype ===
                                        "videos"
                                          ? [
                                              droppedvideoref.current[0],
                                              element,
                                            ]
                                          : element)
                                    }
                                    playsInline
                                    muted
                                    id={clickedcomment.file2.id}
                                    style={videohtml(clickedcomment.file1)}
                                    onTimeUpdate={Videotimeupdate}
                                    onEnded={(e) =>
                                      Droppedvideotimeupdate(e, 0)
                                    }
                                  >
                                    <source
                                      type="video/mp4"
                                      src={clickedcomment.file2?.path}
                                    />
                                  </video>

                                  {droppedvideoref?.current[0] !== null &&
                                    !isNaN(
                                      Array.isArray(droppedvideoref.current[0])
                                        ? droppedvideoref.current[0][0]
                                            ?.duration
                                        : droppedvideoref.current[0]?.duration
                                    ) && (
                                      <span
                                        //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                        style={{
                                          bottom: "46%",
                                          left: "62%",

                                          zIndex: 1,
                                          position: "absolute",
                                        }}
                                        className="videoduration"
                                      >
                                        {0 +
                                          Math.floor(
                                            (clickedcomment.file1.filetype ===
                                            "videos"
                                              ? droppedvideoref?.current[0][1]
                                              : Array.isArray(
                                                  droppedvideoref.current[0]
                                                )
                                              ? droppedvideoref?.current[0][0]
                                              : droppedvideoref?.current[0]
                                            )?.currentTime / 60
                                          )}
                                        :
                                        {(clickedcomment.file1.filetype ===
                                        "videos"
                                          ? droppedvideoref?.current[0][1]
                                          : Array.isArray(
                                              droppedvideoref.current[0]
                                            )
                                          ? droppedvideoref?.current[0][0]
                                          : droppedvideoref?.current[0]
                                        )?.currentTime < 10 && 0}
                                        {(clickedcomment.file1.filetype ===
                                        "videos"
                                          ? droppedvideoref?.current[0][1]
                                          : Array.isArray(
                                              droppedvideoref.current[0]
                                            )
                                          ? droppedvideoref?.current[0][0]
                                          : droppedvideoref?.current[0]
                                        )?.currentTime >= 60
                                          ? Math.floor(
                                              (clickedcomment.file1.filetype ===
                                              "videos"
                                                ? droppedvideoref?.current[0][1]
                                                : Array.isArray(
                                                    droppedvideoref.current[0]
                                                  )
                                                ? droppedvideoref?.current[0][0]
                                                : droppedvideoref?.current[0]
                                              )?.currentTime % 60
                                            )
                                          : Math.floor(
                                              (clickedcomment.file1.filetype ===
                                              "videos"
                                                ? droppedvideoref?.current[0][1]
                                                : Array.isArray(
                                                    droppedvideoref.current[0]
                                                  )
                                                ? droppedvideoref?.current[0][0]
                                                : droppedvideoref?.current[0]
                                              )?.currentTime
                                            )}
                                        <span
                                          style={{
                                            marginLeft: 5,
                                            marginRight: 5,
                                          }}
                                        >
                                          /
                                        </span>
                                        {0 +
                                          Math.floor(
                                            (clickedcomment.file1.filetype ===
                                            "videos"
                                              ? droppedvideoref?.current[0][1]
                                              : Array.isArray(
                                                  droppedvideoref.current[0]
                                                )
                                              ? droppedvideoref?.current[0][0]
                                              : droppedvideoref?.current[0]
                                            )?.duration / 60
                                          )}
                                        :
                                        {(clickedcomment.file1.filetype ===
                                        "videos"
                                          ? droppedvideoref?.current[0][1]
                                          : Array.isArray(
                                              droppedvideoref.current[0]
                                            )
                                          ? droppedvideoref?.current[0][0]
                                          : droppedvideoref?.current[0]
                                        )?.duration < 10 && 0}
                                        {(clickedcomment.file1.filetype ===
                                        "videos"
                                          ? droppedvideoref?.current[0][1]
                                          : Array.isArray(
                                              droppedvideoref.current[0]
                                            )
                                          ? droppedvideoref?.current[0][0]
                                          : droppedvideoref?.current[0]
                                        )?.duration >= 60
                                          ? Math.floor(
                                              (clickedcomment.file1.filetype ===
                                              "videos"
                                                ? droppedvideoref?.current[0][1]
                                                : Array.isArray(
                                                    droppedvideoref.current[0]
                                                  )
                                                ? droppedvideoref?.current[0][0]
                                                : droppedvideoref?.current[0]
                                              )?.duration % 60
                                            )
                                          : Math.floor(
                                              (alldisplay.at(-1).file1
                                                .filetype === "videos"
                                                ? droppedvideoref?.current[0][1]
                                                : Array.isArray(
                                                    droppedvideoref.current[0]
                                                  )
                                                ? droppedvideoref?.current[0][0]
                                                : droppedvideoref?.current[0]
                                              )?.duration
                                            )}
                                      </span>
                                    )}
                                </>
                              ) : (
                                clickedcomment.file2?.filetype === "images" && (
                                  <img
                                    className="postphoto"
                                    src={clickedcomment.file2?.path}
                                    id={clickedcomment.file2?.id}
                                    style={
                                      clickedcomment.file3?.path
                                        ? {
                                            ...imagehtml(clickedcomment.file1),
                                            width:
                                              (clickedcomment.file4?.path &&
                                                130) ||
                                              (clickedcomment.file3.path &&
                                                110),
                                            height:
                                              clickedcomment.file3.path && 95,
                                          }
                                        : imagehtml(clickedcomment.file1)
                                    }
                                  />
                                )
                              )}
                              {clickedcomment.file3?.filetype === "videos" ? (
                                <>
                                  <video
                                    width={
                                      fullscreened
                                        ? "100%"
                                        : clickedcomment.file4.path !== ""
                                        ? 130
                                        : 110
                                    }
                                    height={fullscreened ? "40%" : 95}
                                    ref={(element) =>
                                      (droppedvideoref.current[0] =
                                        clickedcomment.file1.filetype ===
                                          "videos" &&
                                        clickedcomment.file2.filetype ===
                                          "videos"
                                          ? [
                                              droppedvideoref.current[0][0],
                                              droppedvideoref.current[0][1],
                                              element,
                                            ]
                                          : clickedcomment.file1.filetype !==
                                              "videos" &&
                                            clickedcomment.file2.filetype !==
                                              "videos"
                                          ? element
                                          : clickedcomment.file1.filetype !==
                                              "videos" ||
                                            clickedcomment.file2.filetype !==
                                              "videos"
                                          ? [
                                              droppedvideoref?.current[0],
                                              element,
                                            ]
                                          : [])
                                    }
                                    playsInline
                                    muted
                                    id={clickedcomment.file3.id}
                                    style={{
                                      ...videohtml(clickedcomment.file1),

                                      marginTop: 4,
                                    }}
                                    onTimeUpdate={Videotimeupdate}
                                    onEnded={(e) =>
                                      Droppedvideotimeupdate(e, 0)
                                    }
                                  >
                                    <source
                                      type="video/mp4"
                                      src={clickedcomment.file3?.path}
                                    />
                                  </video>

                                  {droppedvideoref?.current[0] !== null &&
                                    !isNaN(
                                      Array.isArray(droppedvideoref.current[0])
                                        ? droppedvideoref.current[0][0]
                                            ?.duration
                                        : droppedvideoref.current[0]?.duration
                                    ) && (
                                      <span
                                        //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                        style={{
                                          bottom: "16%",
                                          left: "26%",
                                          zIndex: 1,
                                          position: "absolute",
                                        }}
                                        className="videoduration"
                                      >
                                        {0 +
                                          Math.floor(
                                            determinevideoindex(
                                              clickedcomment.file1.filetype,
                                              clickedcomment.file2.filetype,
                                              clickedcomment.file4.filetype,
                                              0
                                            )?.currentTime / 60
                                          )}
                                        :
                                        {determinevideoindex(
                                          clickedcomment.file1.filetype,
                                          clickedcomment.file2.filetype,
                                          clickedcomment.file4.filetype,
                                          0
                                        )?.currentTime < 10 && 0}
                                        {determinevideoindex(
                                          clickedcomment.file1.filetype,
                                          clickedcomment.file2.filetype,
                                          clickedcomment.file4.filetype,
                                          0
                                        )?.currentTime >= 60
                                          ? Math.floor(
                                              determinevideoindex(
                                                clickedcomment.file1.filetype,
                                                clickedcomment.file2.filetype,
                                                clickedcomment.file4.filetype,
                                                0
                                              )?.currentTime % 60
                                            )
                                          : Math.floor(
                                              determinevideoindex(
                                                clickedcomment.file1.filetype,
                                                clickedcomment.file2.filetype,
                                                clickedcomment.file4.filetype,
                                                0
                                              )?.currentTime
                                            )}
                                        <span
                                          style={{
                                            marginLeft: 5,
                                            marginRight: 5,
                                          }}
                                        >
                                          /
                                        </span>
                                        {0 +
                                          Math.floor(
                                            determinevideoindex(
                                              clickedcomment.file1.filetype,
                                              clickedcomment.file2.filetype,
                                              clickedcomment.file4.filetype,
                                              0
                                            )?.duration / 60
                                          )}
                                        :
                                        {determinevideoindex(
                                          clickedcomment.file1.filetype,
                                          clickedcomment.file2.filetype,
                                          clickedcomment.file4.filetype,
                                          0
                                        )?.duration < 10 && 0}
                                        {determinevideoindex(
                                          clickedcomment.file1.filetype,
                                          clickedcomment.file2.filetype,
                                          clickedcomment.file4.filetype,
                                          0
                                        )?.duration >= 60
                                          ? Math.floor(
                                              determinevideoindex(
                                                clickedcomment.file1.filetype,
                                                clickedcomment.file2.filetype,
                                                clickedcomment.file4.filetype,
                                                0
                                              )?.duration % 60
                                            )
                                          : Math.floor(
                                              determinevideoindex(
                                                clickedcomment.file1.filetype,
                                                clickedcomment.file2.filetype,
                                                clickedcomment.file4.filetype,
                                                0
                                              )?.duration
                                            )}
                                      </span>
                                    )}
                                </>
                              ) : (
                                clickedcomment.file3?.filetype === "images" && (
                                  <img
                                    className="postphoto"
                                    src={clickedcomment.file3?.path}
                                    id={clickedcomment.file3?.id}
                                    style={{
                                      ...imagehtml(clickedcomment.file1),
                                      width: !clickedcomment.file4?.path
                                        ? 110
                                        : 130,
                                      height: 95,
                                      marginTop: 4,
                                    }}
                                  />
                                )
                              )}
                            </>
                          )}

                          {clickedcomment.file4?.filetype === "videos" ? (
                            <>
                              <video
                                width={
                                  fullscreened
                                    ? "100%"
                                    : clickedcomment.file4.path !== ""
                                    ? 130
                                    : 110
                                }
                                height={fullscreened ? "40%" : 95}
                                ref={(element) =>
                                  (droppedvideoref.current[0] =
                                    clickedcomment.file1.filetype ===
                                      "videos" &&
                                    clickedcomment.file2.filetype ===
                                      "videos" &&
                                    clickedcomment.file3.filetype === "videos"
                                      ? [
                                          droppedvideoref.current[0][0],
                                          droppedvideoref.current[0][1],
                                          droppedvideoref.current[0][2],
                                          element,
                                        ]
                                      : clickedcomment.file1.filetype !==
                                          "videos" &&
                                        clickedcomment.file2.filetype !==
                                          "videos" &&
                                        clickedcomment.file3.filetype !==
                                          "videos"
                                      ? element
                                      : clickedcomment.file1.filetype !==
                                          "videos" ||
                                        clickedcomment.file2.filetype !==
                                          "videos" ||
                                        clickedcomment.file3.filetype !==
                                          "videos"
                                      ? [
                                          ...determinevideoref(
                                            clickedcomment.file1.filetype,
                                            clickedcomment.file2.filetype,
                                            clickedcomment.file3.filetype,
                                            0
                                          ),
                                          element,
                                        ]
                                      : [])
                                }
                                playsInline
                                muted
                                id={clickedcomment.file4.id}
                                style={{
                                  ...videohtml(clickedcomment.file1),

                                  marginTop: 4,
                                }}
                                onTimeUpdate={Videotimeupdate}
                                onEnded={(e) => Droppedvideotimeupdate(e, 0)}
                              >
                                <source
                                  type="video/mp4"
                                  src={clickedcomment.file4?.path}
                                />
                              </video>

                              {droppedvideoref?.current[0] !== null &&
                                !isNaN(
                                  Array.isArray(droppedvideoref.current[0])
                                    ? droppedvideoref.current[0][0]?.duration
                                    : droppedvideoref.current[0]?.duration
                                ) && (
                                  <span
                                    //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                                    style={{
                                      bottom: "16%",
                                      left: "61.5%",
                                      zIndex: 1,
                                      position: "absolute",
                                    }}
                                    className="videoduration"
                                  >
                                    {0 +
                                      Math.floor(
                                        determinefourthvideoindex(
                                          clickedcomment.file1.filetype,
                                          clickedcomment.file2.filetype,
                                          clickedcomment.file3.filetype,
                                          0
                                        )?.currentTime / 60
                                      )}
                                    :
                                    {determinefourthvideoindex(
                                      clickedcomment.file1.filetype,
                                      clickedcomment.file2.filetype,
                                      clickedcomment.file3.filetype,
                                      0
                                    )?.currentTime < 10 && 0}
                                    {determinefourthvideoindex(
                                      clickedcomment.file1.filetype,
                                      clickedcomment.file2.filetype,
                                      clickedcomment.file3.filetype,
                                      0
                                    )?.currentTime >= 60
                                      ? Math.floor(
                                          determinefourthvideoindex(
                                            clickedcomment.file1.filetype,
                                            clickedcomment.file2.filetype,
                                            clickedcomment.file3.filetype,
                                            0
                                          )?.currentTime % 60
                                        )
                                      : Math.floor(
                                          determinefourthvideoindex(
                                            clickedcomment.file1.filetype,
                                            clickedcomment.file2.filetype,
                                            clickedcomment.file3.filetype,
                                            0
                                          )?.currentTime
                                        )}
                                    <span
                                      style={{ marginLeft: 5, marginRight: 5 }}
                                    >
                                      /
                                    </span>
                                    {0 +
                                      Math.floor(
                                        determinefourthvideoindex(
                                          clickedcomment.file1.filetype,
                                          clickedcomment.file2.filetype,
                                          clickedcomment.file3.filetype,
                                          0
                                        )?.duration / 60
                                      )}
                                    :
                                    {determinefourthvideoindex(
                                      clickedcomment.file1.filetype,
                                      clickedcomment.file2.filetype,
                                      clickedcomment.file3.filetype,
                                      0
                                    )?.duration < 10 && 0}
                                    {determinefourthvideoindex(
                                      clickedcomment.file1.filetype,
                                      clickedcomment.file2.filetype,
                                      clickedcomment.file3.filetype,
                                      0
                                    )?.duration >= 60
                                      ? Math.floor(
                                          determinefourthvideoindex(
                                            clickedcomment.file1.filetype,
                                            clickedcomment.file2.filetype,
                                            clickedcomment.file3.filetype,
                                            0
                                          )?.duration % 60
                                        )
                                      : Math.floor(
                                          determinefourthvideoindex(
                                            clickedcomment.file1.filetype,
                                            clickedcomment.file2.filetype,
                                            clickedcomment.file3.filetype,
                                            0
                                          )?.duration
                                        )}
                                  </span>
                                )}
                            </>
                          ) : (
                            clickedcomment.file4?.filetype === "images" && (
                              <img
                                src={clickedcomment.file4?.path}
                                className="postphoto"
                                id={clickedcomment.file4?.id}
                                style={{
                                  ...imagehtml(clickedcomment.file1),
                                  width: 130,
                                  height: 95,
                                  marginTop: 4,
                                }}
                              />
                            )
                          )}

                          {clickedcomment.image1 && (
                            <img
                              className="postphoto"
                              src={clickedcomment.image1}
                              /*
                          style={{
                            width: clickedcomment.image2 && 150,
                            height: clickedcomment.image2 && 150,
                            marginLeft: clickedcomment.image2 && 50,
                            marginTop: clickedcomment.image2 && 20,
                          }}
                          */
                            />
                          )}
                        </div>

                        <div className="date">
                          {clickedcomment.year}/{clickedcomment.month}/
                          {clickedcomment.date}
                          <span className="Timesplit">|</span>
                          {clickedcomment.hour}:{clickedcomment.minutes}
                        </div>

                        <img
                          style={{
                            top:
                              (clickedcomment.image1 ||
                                clickedcomment.file1?.path) &&
                              51,
                          }}
                          className="Rankmarked"
                          src="/RankMark.svg"
                        />
                      </div>

                      <textarea
                        className="drop"
                        placeholder="返信..."
                        value={send}
                        onChange={(e) => setSend(e.target.value)}
                        style={{
                          marginTop: 0,
                          height: tempfiles.length > 0 ? 350 : 100,
                        }}
                      />
                      {tempfiles.length > 0 && (
                        <div
                          style={{
                            justifyContent: tempfiles.length > 2 && "normal",
                            // top:"52%",
                            position: "relative",
                            bottom: 280,
                          }}
                          className="commentimagecontainer"
                        >
                          {tempfiles.map((tempfile, index) => (
                            <div
                              ref={(element) => (ref3.current[index] = element)}
                              key={tempfile.id}
                            >
                              <div>
                                {tempfile.realfile.type.includes("image") && (
                                  <img
                                    className="dropfile"
                                    style={{
                                      marginLeft: tempfiles.length > 1 && 10,
                                      marginRight:
                                        (tempfiles.length === 2 &&
                                          tempfile.id ===
                                            tempfiles[tempfiles.length - 1]
                                              .id &&
                                          13) ||
                                        (tempfiles.length > 2 &&
                                          tempfile.id ===
                                            tempfiles[tempfiles.length - 1]
                                              .id &&
                                          10),
                                      width: tempfiles.length > 1 && 175,
                                      height: tempfiles.length > 1 && 175,
                                    }}
                                    src={tempfile.file}
                                  />
                                )}
                                {tempfile.realfile.type.includes("video") && (
                                  <>
                                    <span>
                                      {controlon && (
                                        <>
                                          <img
                                            style={{
                                              zIndex: 200,
                                              marginLeft: 5,
                                              position: "absolute",
                                              top: 5,
                                            }}
                                            src="/Arrow1.svg"
                                            onClick={Fullscreencancel}
                                          />
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                            }}
                                          >
                                            {!ref3.current[index]?.children[0]
                                              ?.children[0]?.children[0]
                                              ?.paused && !paused ? (
                                              <img
                                                style={{
                                                  zIndex: 200,

                                                  position: "absolute",
                                                  top: "46%",

                                                  width: 15,
                                                }}
                                                src="/ConnectPause.svg"
                                                onClick={(e) => {
                                                  setPaused(true);
                                                  ref3.current[
                                                    index
                                                  ]?.children[0]?.children[0].children[4]?.pause();
                                                }}
                                              />
                                            ) : (
                                              <img
                                                style={{
                                                  zIndex: 200,

                                                  position: "absolute",
                                                  top: "46%",

                                                  width: 50,
                                                }}
                                                src="/ConnectPlay.svg"
                                                onClick={(e) => {
                                                  ref3.current[
                                                    index
                                                  ].children[0].children[0].children[4].play();
                                                  setPaused(false);
                                                  setVideolocation(4);
                                                }}
                                              />
                                            )}
                                          </div>
                                          {(!isNaN(
                                            ref3.current[index]?.children[0]
                                              .children[0].children[3]?.duration
                                          ) ||
                                            !isNaN(
                                              ref3.current[index]?.children[0]
                                                .children[0].children[4]
                                                ?.duration
                                            )) && (
                                            <div>
                                              <span
                                                style={{
                                                  bottom: "-430px",
                                                  zIndex: 1,
                                                }}
                                                className="videoduration"
                                              >
                                                {0 +
                                                  Math.floor(
                                                    ref3.current[index]
                                                      ?.children[0].children[0]
                                                      .children[videolocation]
                                                      ?.currentTime / 60
                                                  )}
                                                :
                                                {ref3.current[index]
                                                  ?.children[0].children[0]
                                                  .children[videolocation]
                                                  ?.currentTime < 10 && 0}
                                                {ref3.current[index]
                                                  ?.children[0].children[0]
                                                  .children[videolocation]
                                                  ?.currentTime >= 60
                                                  ? Math.floor(
                                                      ref3.current[index]
                                                        ?.children[0]
                                                        .children[0].children[
                                                        videolocation
                                                      ]?.currentTime % 60
                                                    )
                                                  : Math.floor(
                                                      ref3.current[index]
                                                        ?.children[0]
                                                        .children[0].children[
                                                        videolocation
                                                      ]?.currentTime
                                                    )}
                                                <span
                                                  style={{
                                                    marginLeft: 5,
                                                    marginRight: 5,
                                                  }}
                                                >
                                                  /
                                                </span>
                                                {0 +
                                                  Math.floor(
                                                    ref3.current[index]
                                                      ?.children[0].children[0]
                                                      .children[videolocation]
                                                      ?.duration / 60
                                                  )}
                                                :
                                                {ref3.current[index]
                                                  ?.children[0].children[0]
                                                  .children[videolocation]
                                                  ?.duration < 10 && 0}
                                                {ref3.current[index]
                                                  ?.children[0].children[0]
                                                  .children[videolocation]
                                                  ?.duration >= 60
                                                  ? Math.floor(
                                                      ref3.current[index]
                                                        ?.children[0]
                                                        .children[0].children[
                                                        videolocation
                                                      ]?.duration % 60
                                                    )
                                                  : Math.floor(
                                                      ref3.current[index]
                                                        ?.children[0]
                                                        .children[0].children[
                                                        videolocation
                                                      ]?.duration
                                                    )}
                                              </span>
                                              <input
                                                type="range"
                                                style={{
                                                  width: "100%",
                                                  position: "relative",
                                                  bottom: "-440px",
                                                  zIndex: 1,
                                                }}
                                                min="0"
                                                max={Math.floor(
                                                  ref3.current[index]
                                                    ?.children[0].children[0]
                                                    .children[videolocation]
                                                    ?.duration
                                                )}
                                                value={videotime}
                                                onChange={Videotimechange}
                                                id={tempfile.id}
                                              ></input>
                                            </div>
                                          )}
                                        </>
                                      )}
                                      {fullscreened && (
                                        <div
                                          style={{
                                            display: "flex",
                                          }}
                                          id={tempfile.id}
                                        >
                                          <div
                                            className="fullscreencontrol"
                                            id={[tempfile.id, "left"]}
                                            onClick={fullscreenside}
                                          ></div>
                                          <div
                                            style={{ right: 0 }}
                                            className="fullscreencontrol"
                                            id={[tempfile.id, "right"]}
                                            onClick={fullscreenside}
                                          ></div>
                                        </div>
                                      )}
                                      <video
                                        controls={tempfiles.length < 2 && true}
                                        width={
                                          fullscreened
                                            ? "100%"
                                            : tempfiles.length === 1
                                            ? 200
                                            : 175
                                        }
                                        height={
                                          fullscreened
                                            ? "40%"
                                            : tempfiles.length === 1
                                            ? 200
                                            : 175
                                        }
                                        playsInline
                                        autoPlay
                                        muted
                                        loop
                                        id={tempfile.id}
                                        onClick={
                                          tempfiles.length > 1
                                            ? fullscreen
                                            : undefined
                                        }
                                        onTimeUpdate={
                                          tempfiles.length > 1
                                            ? Videotimeupdate
                                            : undefined
                                        }
                                        style={{
                                          objectFit: "cover",
                                          borderRadius: 20,
                                          marginLeft:
                                            tempfiles.length > 1 &&
                                            !fullscreened &&
                                            10,
                                          marginRight:
                                            (tempfiles.length === 2 &&
                                              tempfile.id ===
                                                tempfiles[tempfiles.length - 1]
                                                  .id &&
                                              !fullscreened &&
                                              13) ||
                                            (tempfiles.length > 2 &&
                                              tempfile.id ===
                                                tempfiles[tempfiles.length - 1]
                                                  .id &&
                                              !fullscreened &&
                                              10),
                                          position: fullscreened && "absolute",
                                          top: fullscreened && "30%",
                                        }}
                                      >
                                        <source
                                          src={tempfile.file}
                                          type="video/mp4"
                                        />
                                      </video>
                                      {tempfiles.length > 1 &&
                                        tempfile.realfile.type.includes(
                                          "video"
                                        ) &&
                                        !fullscreened &&
                                        (!isNaN(
                                          ref3.current[tempfiles.length - 1]
                                            ?.children[0].children[0]
                                            .children[0]?.duration
                                        ) ||
                                          tempfiles[
                                            tempfiles.length - 1
                                          ].realfile.type.includes(
                                            "image"
                                          )) && (
                                          <span className="videoduration">
                                            {0 +
                                              Math.floor(
                                                ref3.current[index]?.children[0]
                                                  .children[0].children[0]
                                                  .currentTime / 60
                                              )}
                                            :
                                            {ref3.current[index]?.children[0]
                                              .children[0].children[0]
                                              .currentTime < 10 && 0}
                                            {ref3.current[index]?.children[0]
                                              .children[0].children[0]
                                              .currentTime >= 60
                                              ? Math.floor(
                                                  ref3.current[index]
                                                    ?.children[0].children[0]
                                                    .children[0].currentTime %
                                                    60
                                                )
                                              : Math.floor(
                                                  ref3.current[index]
                                                    ?.children[0].children[0]
                                                    .children[0].currentTime
                                                )}
                                            <span
                                              style={{
                                                marginLeft: 5,
                                                marginRight: 5,
                                              }}
                                            >
                                              /
                                            </span>
                                            {0 +
                                              Math.floor(
                                                ref3.current[index]?.children[0]
                                                  .children[0].children[0]
                                                  .duration / 60
                                              )}
                                            :
                                            {ref3.current[index]?.children[0]
                                              .children[0].children[0]
                                              .duration < 10 && 0}
                                            {ref3.current[index]?.children[0]
                                              .children[0].children[0]
                                              .duration >= 60
                                              ? Math.floor(
                                                  ref3.current[index]
                                                    ?.children[0].children[0]
                                                    .children[0].duration % 60
                                                )
                                              : Math.floor(
                                                  ref3.current[index]
                                                    ?.children[0].children[0]
                                                    .children[0].duration
                                                )}
                                          </span>
                                        )}
                                    </span>
                                  </>
                                )}

                                {(tempfiles[
                                  tempfiles.length - 1
                                ].realfile.type.includes("image") ||
                                  !isNaN(
                                    ref3.current[tempfiles.length - 1]
                                      ?.children[0].children[0].children[0]
                                      .duration
                                  ) ||
                                  tempfiles.length === 1) && (
                                  <img
                                    style={{
                                      width: 25,
                                      position: "relative",
                                      left:
                                        (tempfiles.length === 1 && -35) ||
                                        (tempfiles.length > 1 &&
                                          tempfile.realfile.type.includes(
                                            "video"
                                          ) &&
                                          95) ||
                                        (tempfiles.length > 1 && 150),

                                      bottom:
                                        (tempfiles.length === 1 && 170) ||
                                        (tempfiles.length > 1 && 173),
                                    }}
                                    src="/Xout.svg"
                                    onClick={filedelete}
                                    id={tempfile.id}
                                  />
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                      <div
                        style={{
                          position: "relative",
                          bottom: tempfiles.length === 1 ? 300 : 310,
                        }}
                      >
                        <Progressbar percent={progresspercent} />
                      </div>

                      <span
                        style={{
                          bottom: tempfiles?.length === 0 ? 38 : 30,
                          marginTop: tempfiles.length > 0 && -258,
                        }}
                        className="CommentCameraline"
                      >
                        <label>
                          <img className="camera" src="./Photo.svg" />
                          <input
                            style={{ display: "none" }}
                            type="file"
                            multiple="multiple"
                            onChange={fileedit}
                          />
                        </label>
                        <span style={{ marginLeft: 4, marginBottom: 4 }}>
                          |
                        </span>
                        <button
                          style={{ marginLeft: 4, marginBottom: 4 }}
                          className="Commentsendbutton"
                          onClick={
                            (tempfiles.length > 0 || send) && !disablepress //dropref.current
                              ? SendComment
                              : undefined
                          }
                        >
                          送信
                        </button>
                      </span>
                    </>
                  )}
                </>
              )}
            </div>
          )}
          {posts.length > 0 && (
            <LowControl
              notification={userinfo.Notification}
              currenturl={currenturl}
              alldisplay={alldisplay.length}
              commentlength={simdisplays.at(-1)?.length}
              clickedcomment={clickedcomment}
            />
          )}
        </>
      )}
    </div>
  );
}

export default Connect;
