import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./Header.js";
import Main from "./Main.js";
import PopUp from "./PopUp.js";
import User from "./User.js";
import Connect from "./Connect.js";
import UserView from "./UserView.js";
import Ranks from "./Ranks.js";
import Message from "./Message.js";
import MessageView from "./MessageView.js";
import Home from "./Home.js";
import Currency from "./Currency.js";
import UserSettings from "./UserSettings.js";
import Notification from "./Notification.js";
import Pledge from "./Pledge.js";
import PledgeSettings from "./PledgeSettings.js";
import PledgeSubscribe from "./PledgeSubscribe.js";
import EStation from "./EStation.js";
import CreditLaws from "./CreditLaws.js";
import LowControl from "./LowControl.js";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path={`/E-Station`} element={<EStation />} />
          <Route path={`/CreditLaws`} element={<CreditLaws />} />
          <Route
            path={`/PledgeSubscribe/:userid`}
            element={<PledgeSubscribe />}
          />
          <Route path={`/PledgeSettings`} element={<PledgeSettings />} />
          <Route path={`/Pledge/:userid`} element={<Pledge />} />
          <Route path={`/UserSettings`} element={<UserSettings />} />
          <Route path={`/Currency`} element={<Currency />} />
          <Route path={`/Home`} element={<Home />} />
          <Route
            path={`/Notification`}
            element={<Notification ImageUrl="./Banner.svg" />}
          />
          <Route path={`/MessageView`} element={<MessageView />} />
          <Route path={`/Message/:room`} element={<Message />} />
          <Route path={`/Ranks`} element={<Ranks />} />
          <Route exact path={`/UserView/:see`} element={<UserView />} />
          <Route
            path={`/Connect`}
            element={<Connect ImageUrl="./Banner.svg" />}
          />
          <Route exact path={`/User/:userid`} element={<User />} />
          <Route path={`/SignUp`} element={<PopUp />} />
          <Route
            path={`/`}
            element={
              <>
                <Header ImageUrl="./Banner.svg" />
              </>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
