import React, { useState } from "react";
import "./Main.css";
import { Link, useNavigate } from "react-router-dom";
import FakeConnect from "./FakeConnect.js";

function Main() {
  const [imageloaded, setImageloaded] = useState(false);
  const [componentloaded, setComponentloaded] = useState(false);
  return (
    /* <img className="Bottom" src="./test4.svg" />*/
    <div
      style={{
        width: "100vw",
        ////////////////////this position fixed needs to be turned off when in google ad sense 審査
        // position: "fixed",
        position: !componentloaded ? "fixed" : "",
        //height: "100vh",
        //height: "100%",
        overflowX: "clip",
        // marginTop: -6.5,
        //borderWidth: "1px",
        //border: "solid black",
        //borderBottom: "none",
        /*border: "1px solid black",*/
        zIndex: -1,

        //this way you dont need to have borders, however the sizing is weird
        //background: "url(EpliseGirl.svg)",
        //backgroundPosition: "center",
        //backgroundSize: "cover",
        //backgroundRepeat: "no-repeat",
      }}
    >
      <img
        className="Bottom"
        style={{
          display: "block",
          border: "1px solid black",
          /*borderTop: 0.5,*/
        }}
        onLoad={() => setImageloaded(true)}
        src="EpliseGirl.svg"
      />

      <div onLoad={() => setComponentloaded(true)}>{/*<FakeConnect />*/}</div>

      {imageloaded && (
        <Link to={`/CreditLaws`} style={{ textDecoration: "none" }}>
          <img
            style={{
              width: "100%",
              display: "block",
            }}
            src="Bottombar.svg"
          />
        </Link>
      )}
    </div>
  );
}

export default Main;
