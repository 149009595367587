import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  getDocs,
  query,
  where,
  collection,
  writeBatch,
  serverTimestamp,
  addDoc,
  doc,
  increment,
  updateDoc,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "./Firebase.js";
import "./Home.css";
import { stringify } from "@firebase/util";

function Home() {
  const [following, setFollowing] = useState([]);
  const [realfollowings, setRealfollowings] = useState([]);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userId = user.uid;
        console.log(userId);
        const followRef = collection(db, "users", userId, "Following");
        const querySnapshot = getDocs(followRef).then((querySnapshot) => {
          let posts = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          console.log(posts.length);
          console.log(posts[0].Follow_id);
          let num = -1;
          let followkeep = [];
          for (let step = 0; step < posts.length; step++) {
            num++;
            followkeep.push(posts[num].Follow_id);
          }
          console.log(followkeep);

          let followposts = [];
          let num1 = -1;
          for (let step = 0; step < followkeep.length; step++) {
            num1++;
            const displayRef = collection(
              db,
              "users",
              followkeep[num1],
              "Drops"
            );
            const querySnapshot = getDocs(displayRef).then((querySnapshot) => {
              followposts.push(
                querySnapshot.docs.map((doc) => ({
                  ...doc.data(),
                  id: doc.id,
                }))
              );
              setRealfollowings(followposts.flat());
              console.log(realfollowings);
            });
          }
        });
      }
    });
  }, []);

  const sendit = async () => {
    let posts;
    let new_posts;
    const userCollectionRef = collection(db, "users");
    await getDocs(userCollectionRef).then((querySnapshot) => {
      posts = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
    });
    console.log(posts);
    await new Promise((resolve) => {
      /*let*/ new_posts = posts.filter(
        (post) =>
          post.id !== "jJ8UZgDVEzU734mwX4GPskYvXzA3" &&
          post.id !== "1MNrwvjBwEbB7NmJR05bLLTNueq1" &&
          post.id !== "b3wZEloxcxRwAZ9fsYYzhjRaQBF3" &&
          post.id !== "2mJrdxNuRKbqVjAnxeb0cHTrrZR2" &&
          post.id !== "nMjRHgEF6ldn2cHoMxbpJx2k0Df2" &&
          post.id !== "67Ts6SSTpcZyHiu7YzxWmhDQGET2" &&
          post.id !== "BOhuxP952PTB48Er58AnNFWV0rp1" &&
          post.id !== "RKOselx1VsUvvx2u6UxtyvB1kdy1" &&
          post.id !== "nwiA8YNllRNei0CiBrepxwF5N443" &&
          post.id !== "RTQyHbxrnyPfdnVmWOsVQkJR9uI2" &&
          post.id !== "jjZZxnuk1Nc0lYmBWqHzRQM9sTv2" &&
          post.id !== "WLp3v9lyz9T0CC7ixyNcloOXLzV2" &&
          post.id !== "qdvaliFKl1aZyMzU8E2MSMEcEZi1" &&
          post.id !== "xhBTimLSOebBHkaugCg8pYoXex63" &&
          post.id !== "XujOBo8Wp6ewaxHcGybfcnj9oo22" &&
          post.id !== "jQDLCTZyYVWaGVXe9L5FBceuxKl2" &&
          post.id !== "skEtq9tQivV6qIxYoULFqGMAdjk1" &&
          post.id !== "p3wVtRTaBZXi6JQ5llZnfOM7Nhn2" &&
          post.id !== "NKJcporduURY9BIwIsPFo2Qpb5W2" &&
          post.id !== "PEMvmIStwRebBYY5lfliQgZkV062" &&
          post.id !== "JGbmZAIZmrfRpGE6v2EIy0F5cyj2" &&
          post.id !== "BZ4VrkU7SrQk02vgDVCEXVhwhVD3" &&
          post.id !== "V5ZiV6R1eOfvc1cmXCb3pxaMGXl1" &&
          post.id !== "CkqPoESBPFNgqEndJbXvLGaexSD2" &&
          post.id !== "Rs4MxZ6XuJYG3AXI4dhBlFRrwRW2" &&
          post.id !== "kgeYVbZcE4QsUbcMce4GyGpuhVk2" &&
          post.id !== "csLdLwL4WkQpsiIbrXPpRJpbrSr2" &&
          post.id !== "kgeYVbZcE4QsUbcMce4GyGpuhVk2" &&
          post.id !== "bcG8d2dmtFMjaFKcoKUMzscMYYJ2" &&
          post.id !== "WLZ01HbTfhe3M7SmALRHAjZpBqq2"
        //always change
      );
      //10 まで
      console.log(new_posts);
      resolve();
    });
    //let messageinfo;
    //let messageinfo1;
    let setref;
    let updateref;
    let addref;
    let addrefid;
    let finaladdref;
    let batchArray = [];
    let batchCount = 0;
    let batchIndex = 0;
    let messageinfo = {
      text: "こんにちは！このサイト始めたばかりだけど仲良くして>∀<", //always change
      Timestamp: serverTimestamp(),
      image1: "",
      video: "",
      from: "bcG8d2dmtFMjaFKcoKUMzscMYYJ2", //always change
      attached: false,
    };
    batchArray.push(writeBatch(db));

    setTimeout(() => {
      //maybe for of instead of foreach if we have issues
      new_posts.forEach(async (post) => {
        messageinfo = { ...messageinfo, to: post.id };
        setref = doc(
          db,
          `users/${post.id}/Message/bcG8d2dmtFMjaFKcoKUMzscMYYJ2` //always change
        );
        await new Promise((resolve) => {
          batchArray[batchIndex].set(setref, {
            ...messageinfo,
            MessageName: "くるん", //always change
            MessageRank: 238, //always change
            MessagePic:
              "https://firebasestorage.googleapis.com/v0/b/eplise.appspot.com/o/images%2FAAtUAAA1726034276005.png?alt=media&token=619c93c5-619f-4c59-b681-faadf20015bf", //always change
            Opened: false,
          });

          resolve();
        });

        //.then(() => {

        addref = collection(
          db,
          "users",
          post.id,
          "Message",
          "bcG8d2dmtFMjaFKcoKUMzscMYYJ2", //always change
          "Message_details"
        );
        addrefid = doc(addref).id;
        finaladdref = doc(
          db,
          "users",
          post.id,
          "Message",
          "bcG8d2dmtFMjaFKcoKUMzscMYYJ2", //always change
          "Message_details",
          addrefid
        );

        batchArray[batchIndex].set(finaladdref, messageinfo);

        updateref = doc(db, `users/${post.id}/`);

        batchArray[batchIndex].update(updateref, {
          MessageNotification: increment(1),
        });
      });
      batchCount++;
      batchCount++;
      batchCount++;
      if (batchCount === 499) {
        batchArray.push(writeBatch(db));
        batchIndex++;
        batchCount = 0;
      }
    }, 5000);

    setTimeout(() => {
      batchArray.forEach(async (batch) => await batch.commit());
    }, 6000);
    //});
  };

  return (
    <div>
      {/*<button onClick={sendit}>YOOOOOOOOOO</button>*/}
      <img src="/Arrow1.svg" onClick={null} />
      <img src="./Planet1.svg" className="Planet" />
      <img src="./DropView.svg" className="dropb" />
      {realfollowings.map((realfollowing) => (
        <div className="drops" key={realfollowing.id}>
          <img
            className={
              realfollowing.Profile_image === "/Profile3.svg"
                ? "current3"
                : "current1"
            }
            src={realfollowing.Profile_image}
          />
          {realfollowing.Rank}
        </div>
      ))}
    </div>
  );
}

export default Home;
