import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getDocs, query } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "./Firebase.js";
import "./FullScreenPromotion.css";

function FullScreenPromotion() {
  const allpromos = ["./EpliseGirlNeon1.svg", "./nyunpromo5.svg"];
  const [promo, setPromo] = useState("");
  const [imageloaded, setImageloaded] = useState(false);

  useEffect(() => {
    let promoindex = Math.floor(Math.random() * allpromos.length); /*- 1*/
    console.log(promoindex);
    setPromo(allpromos[promoindex]);
  }, []);

  return (
    <div style={{ position: "fixed" }}>
      <img
        style={{ display: "block", width: "100%" }}
        src={promo}
        onLoad={() => setImageloaded(true)}
      />
      {imageloaded && (
        <div
          style={{
            width: "100%",
            height: 60,
            display: "block",
            //background:"linear-gradient(#2f17e7, rgb(26, 155, 214)"
            background: "black",
          }}
        ></div>
      )}
    </div>
  );
}

export default FullScreenPromotion;
