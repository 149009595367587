import React, { useState, useEffect, useRef } from "react";
import "./UserView.css";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
  onSnapshot,
  collection,
  deleteDoc,
  getDocs,
  writeBatch,
  increment,
  addDoc,
  updateDoc,
  collectionGroup,
  query,
  where,
  orderBy,
  limit,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { db, auth } from "./Firebase.js";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  getStorage,
  deleteObject,
} from "firebase/storage";
import Block from "./Customhooks/Block.js";
import axios from "axios";

function UserView() {
  const { see } = useParams();
  const navigate = useNavigate();
  const ref1 = useRef(false);
  const scrollref = useRef(null);
  const currenturl = "UserView";
  const droppedvideoref = useRef([]);

  useEffect(() => {
    if (!ref1.current) {
      ref1.current = true;
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          const userBlockRef = doc(db, "users", see, "Blocked", user.uid);
          getDoc(userBlockRef).then((documentSnapshot) => {
            if (documentSnapshot.data()?.Blocked_id) setBlockon(true);
          });

          const userDocumentRef = doc(db, "users", user.uid, "Following", see);
          getDoc(userDocumentRef).then((documentSnapshot) => {
            if (documentSnapshot.data()?.Follow_id === see) setFollowing(true);
          });

          const userUnblockRef = doc(db, "users", user.uid, "Blocked", see);
          getDoc(userUnblockRef).then((documentSnapshot) => {
            if (documentSnapshot.data()?.Blocked_id) setUnblock(true);
          });

          const userDocumentRef1 = doc(db, "users", see);
          //await
          getDoc(userDocumentRef1).then((documentSnapshot) => {
            setViewinfo(documentSnapshot.data());
          });

          const userDocumentRef2 = doc(db, "users", user.uid);
          //await
          getDoc(userDocumentRef2).then((documentSnapshot) => {
            setUserinfo({ ...documentSnapshot.data(), id: user.uid });
          });

          const userDocumentRef3 = collection(db, "users", see, "PledgePlan");
          getDocs(userDocumentRef3).then((documentSnapshot) => {
            if (documentSnapshot.size > 0) {
              setPledgegreen(true);
            }
          });

          const dropsRef = query(
            collectionGroup(db, "Following"),
            where("Follow_id", "==", see)
          );
          getDocs(dropsRef).then((querySnapshot) => {
            setFollowercount(querySnapshot.docs.length);
          });

          let posts1 = [];

          console.log(posts1);
          const userCollectionRef = collection(db, "users", see, "Drops");
          await getDocs(
            query(
              userCollectionRef,

              orderBy("Timestamp", "desc")
            )
          ).then((querySnapshot) => {
            let posts = querySnapshot.docs.map((doc) => ({
              ...doc.data(),
              id: doc.id,
            }));

            if (see !== user.uid) {
              const userCollectionRef = collection(
                db,
                "users",
                user.uid,
                "Drops"
              );
              getDocs(userCollectionRef).then((querySnapshot) => {
                posts1 = querySnapshot.docs.map((doc) => ({
                  ...doc.data(),
                  id: doc.id,
                }));

                ////
                posts.map((post) => {
                  posts1.forEach((post1) => {
                    if (
                      (post.Redrop_id && post1.Redrop_id === post.Redrop_id) ||
                      (!post.Redrop_id && post1.Redrop_id === post.id)
                    ) {
                      post.Redropped = "done";
                    }
                  });
                });
              });
            } else {
              posts.map((post) => {
                console.log("HOW MANY");
                if (post.Redropper_id === see) post.Redropped = "done";
              });
            }

            posts.map((post) => {
              console.log("HOW MANY");
              if (!post.Redropper_id) {
                post.year = post.Timestamp.toDate().getFullYear();
                post.month = (
                  "0" +
                  (post.Timestamp.toDate().getMonth() + 1)
                ).slice(-2);
                post.date = ("0" + post.Timestamp.toDate().getDate()).slice(-2);
                post.hour = post.Timestamp.toDate().getHours();
                post.minutes = (
                  "0" + post.Timestamp.toDate().getMinutes()
                ).slice(-2);
              }

              //dont want this running when foreach runs
              //rerun mapped function outside of the this mapped function  conditionally

              /*if (post.Redropper_id === see && see === user.uid)
                post.Redropped = "done";*/

              console.log(posts1);
              console.log(posts);
            });

            setTimeout(() => {
              setPosts(posts);
            }, 100);
          });

          setId(user.uid);

          const userCollectionRef1 = collection(
            db,
            "users",
            user.uid,
            "OutstandingPay"
          );
          await getDocs(userCollectionRef1).then((querySnapshot) => {
            let posts2 = querySnapshot.docs.map((doc) => ({
              ...doc.data(),
              id: doc.id,
            }));
            console.log(posts2);

            posts2 = posts2.filter((post) => post.For === "Pledge");

            posts2.forEach((post) => {
              (async () => {
                console.log(post.Stripe_id);
                try {
                  const response = await axios.get(
                    `http://localhost:4000/v1/payment_intents/${post.Stripe_id}`,
                    {
                      params: {
                        data: post.Stripe_id,
                      },
                    }
                  );

                  if (response.data.success) {
                    console.log(response.data);

                    if (
                      response.data.status === "succeeded" ||
                      response.data.status === "requires_payment_method"
                    ) {
                      const userDocumentRef = doc(
                        db,
                        "users",
                        user.uid,
                        "OutstandingPay",
                        post.id
                      );
                      await deleteDoc(userDocumentRef);
                    }

                    if (response.data.status === "succeeded") {
                      addDoc(
                        collection(db, "users", post.Directory, "PledgeHold"),
                        {
                          Pledgeid: post.Pledgeid,
                          PaidUser: user.uid,
                          Price: post.Price,
                          ExpireNumber:
                            new Date().getTime() + 86400000 * post.a,
                          FullExpireDate: new Date(
                            new Date().getTime() + 86400000 * post.a
                          ),
                          Timestamp: serverTimestamp(),
                          Profile_image: userinfo.Profile_image,
                          User_Name: userinfo.User_Name,
                          Rank: userinfo.Rank,
                        }
                      );
                    } else if (
                      response.data.status === "requires_payment_method"
                    ) {
                      updateDoc(
                        doc(
                          db,
                          "users",
                          post.Directory,
                          "PledgePlan",
                          post.Pledgeid
                        ),
                        {
                          subscribers: increment(-1),
                        }
                      );
                    }
                  }
                } catch (error) {
                  console.log(error);
                }
              })();
            });
          });
        }
      });
    }
    setTimeout(() => {
      setVideorefload(!videorefload);
    }, 500);
  }, []);

  const [id, setId] = useState("");
  const [following, setFollowing] = useState(false);
  const [ask, setAsk] = useState(false);
  const [blockon, setBlockon] = useState(false);
  const [unblock, setUnblock] = useState(false);
  const [confirmblock, setConfirmblock] = useState(false);
  const [confirmunblock, setConfirmunblock] = useState(false);
  const [finaldeleteredrop, setFinaldeleteredrop] = useState([]);
  const [userinfo, setUserinfo] = useState("");
  const [viewinfo, setViewinfo] = useState("");
  const [followercount, setFollowercount] = useState("");
  const [posts, setPosts] = useState([]);
  const [unredrop, setUnredrop] = useState("");
  const [pledgegreen, setPledgegreen] = useState(false);
  const [clickedcomment, setClickedcomment] = useState("");
  const [alldisplay, setAlldisplay] = useState([]);
  const [optionid, setOptionId] = useState("");
  const [redroppoint, setRedroppoint] = useState("");
  const [unredroppoint, setUnredroppoint] = useState("");
  const [deleteid, setDeleteid] = useState("");
  const [simdisplays, setSimdisplays] = useState([]);
  const [showdrop, setShowdrop] = useState(true);
  const [videorefload, setVideorefload] = useState(false);
  const [blockid, setBlockid] = useState("");
  const [fullscreened, setFullscreened] = useState("");
  const [controlon, setControlon] = useState(false);
  const [showtime, setShowtime] = useState(false);
  const [videotime, setVideotime] = useState(0);
  const [redropid, setRedropid] = useState([]);
  const [unredropdid, setUnredropdid] = useState([]);
  const [redropswitch, setRedropswitch] = useState(true);
  const [simposts, setSimposts] = useState([]);
  const [redropped, setRedropped] = useState("");

  const Follow = () => {
    if (id !== see) {
      let follow = {
        Profile_image: userinfo.Profile_image,
        Name: userinfo.User_Name,
        Rank: userinfo.Rank,
        Directory: id,
      };

      setDoc(doc(db, "users", id, "Following", see), {
        Last_Checked: serverTimestamp(),
        Follow_id: see,
        ...follow,
      });
      addDoc(collection(db, `users/${see}/Notification/`), {
        Type: "Follow",
        Loadtype: "Follow",
        Timestamp: serverTimestamp(),
        ...follow,
        Followed_id: see,
      });
      updateDoc(doc(db, `users/${see}`), {
        Notification: increment(1),
      });
      setFollowing(true);
      setFollowercount(followercount + 1);
    }
  };

  const AskUnfollow = () => {
    setAsk(true);
  };

  const back = () => {
    setAsk(false);
  };

  const Unfollow = async () => {
    const userDocumentRef = doc(db, "users", id, "Following", see);
    await deleteDoc(userDocumentRef);

    setFollowing(false);
    setAsk(false);
    setFollowercount(followercount - 1);
  };

  const askblock = () => {
    setConfirmblock(true);

    const redropdeleteRef = collection(db, "users", see, "Drops");
    const querySnapshot = getDocs(redropdeleteRef).then((querySnapshot) => {
      let posts = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setFinaldeleteredrop(posts.filter((post) => post.Directory === id));
    });
  };

  const block = async () => {
    setDoc(doc(db, "users", id, "Blocked", see), {
      Blocked_id: see,
    });

    setUnblock(true);
    setConfirmblock(false);

    console.log(finaldeleteredrop);

    let batchArray = [];
    batchArray.push(writeBatch(db));
    let batchCount = 0;
    let batchIndex = 0;
    finaldeleteredrop.forEach((post) => {
      const userDocumentRef = doc(db, "users", see, "Drops", post.id);
      batchArray[batchIndex].delete(userDocumentRef);
      batchCount++;
      if (batchCount === 499) {
        batchArray.push(writeBatch(db));
        batchIndex++;
        batchCount = 0;
      }
    });

    batchArray.forEach(async (batch) => await batch.commit());

    let batchArray1 = [];
    batchArray1.push(writeBatch(db));
    let batchCount1 = 0;
    let batchIndex1 = 0;
    finaldeleteredrop.forEach((post) => {
      const userDocumentRef = doc(
        db,
        "users",
        id,
        post.Sender_id ? "Comments" : "Drops",
        post.Redrop_id
      );
      batchArray1[batchIndex1].update(userDocumentRef, {
        Redropped_Counter: increment(-1),
      });
      batchCount1++;
      if (batchCount1 === 499) {
        batchArray1.push(writeBatch(db));
        batchIndex1++;
        batchCount1 = 0;
      }
    });

    batchArray1.forEach(async (batch) => await batch.commit());

    const userBlockUnfollowRef = doc(db, "users", see, "Following", id);
    await getDoc(userBlockUnfollowRef).then((documentSnapshot) => {
      documentSnapshot.data().Follow_id && deleteDoc(userBlockUnfollowRef);
    });
  };

  const understandblocked = () => {
    setBlockid("");
  };

  const Unblock1 = async () => {
    const userUnblockRef = doc(db, "users", id, "Blocked", see);
    await deleteDoc(userUnblockRef);

    setUnblock(false);
    setConfirmunblock(false);
  };

  const message = () => {
    const userBlockRef = doc(db, "users", see);
    const userBlockRef1 = collection(db, "users", see, "PledgeHold");

    getDocs(userBlockRef1).then((querySnapshot) => {
      let posts = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      const inthere = (element) => element.PaidUser === id;

      getDoc(userBlockRef).then((documentSnapshot) => {
        if (documentSnapshot.data().Message_Only_From_Pledge === "No") {
          navigate(`/Message/${see}`, { state: "UserView" });
        } else if (
          documentSnapshot.data().Message_Only_From_Pledge === "Yes" &&
          posts.some(inthere) === true
        ) {
          console.log("Hey");
          navigate(`/Message/${see}`, { state: "UserView" });
        } else if (documentSnapshot.data().Message_Only_From_Pledge === "Yes") {
          alert("この方にメッセージを送るには有料サービスに加入して下さい");
        }
      });
    });
  };

  const display = () => {};

  const comment = () => {};

  const AskUnredrop = () => {};

  const redrop = () => {};

  const CancelUnredrop = () => {
    setUnredroppoint(false);
  };
  const ConfirmUnredrop = () => {};

  const confirmredrop = async () => {
    //make sure pure:true is not in the adddoc
    try {
      if (redropid[1] !== id) {
        await updateDoc(
          doc(
            db,
            `users/${redropid[1]}/${
              alldisplay.length === 0 ||
              alldisplay[0]?.displayid === redropid[0]
                ? "Drops"
                : "Comments"
            }/${redropid[0]}`
          ),
          {
            Redropped_Counter: increment(1),
          }
        );
      } else {
        await updateDoc(
          doc(
            db,
            `users/${redropid[1]}/${
              alldisplay.length === 0 ||
              alldisplay[0]?.displayid === redropid[0]
                ? "Drops"
                : "Comments"
            }/${redropid[0]}`
          ),
          {
            Redropped_Counter: increment(1),
            Redrop_id: redropid[0],
            Redropped: true,
            Redropper_id: id,
          }
        );
      }

      if (
        redropid[1] !== id &&
        (alldisplay.length === 0 || alldisplay[0]?.displayid === redropid[0])
      ) {
        addDoc(collection(db, `users/${id}/Drops/`), {
          Redropped: true,
          Directory: redropid[1],
          Real_Timestamp: [
            redropid[6],
            redropid[7],
            redropid[8],
            redropid[9],
            redropid[10],
          ],
          image1: redropid[11],
          image2: redropid[12],
          image3: redropid[13],
          image4: redropid[14],
          video: redropid[15],
          Rank: parseInt(redropid[2]),
          Profile_image: redropid[3],
          Comment_Counter: parseInt(redropid[5]),
          Redropped_Counter: parseInt(redropid[17]),
          Redrop_id: redropid[0],
          text: redropid[4],

          Redropper_id: id,
          Timestamp: serverTimestamp(),
        });
      } else if (
        alldisplay.length > 0 &&
        alldisplay[0]?.displayid !== redropid[0]
      ) {
        addDoc(collection(db, `users/${id}/Drops/`), {
          Redropped: true,
          Directory: redropid[19],
          Real_Timestamp: [
            redropid[6],
            redropid[7],
            redropid[8],
            redropid[9],
            redropid[10],
          ],
          image1: redropid[11],
          image2: redropid[12],
          image3: redropid[13],
          image4: redropid[14],
          video: redropid[15],
          Rank: parseInt(redropid[2]),
          Profile_image: redropid[3],
          Comment_Counter: parseInt(redropid[5]),
          Redropped_Counter: parseInt(redropid[17]),
          Redrop_id: redropid[0],
          text: redropid[4],
          Redropper_id: id,
          Timestamp: serverTimestamp(),
          Sender_id: redropid[1],
          Sent_id: redropid[18],
          This_Directory: redropid[20],
        });
      }
    } catch (error) {
      console.log(error);
    }

    const dropsRef = query(
      collectionGroup(db, "Drops"),
      where("Redrop_id", "==", redropid[0])
    );
    const querySnapshot = getDocs(dropsRef).then((querySnapshot) => {
      let posts = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      console.log(posts.length);

      let new_posts =
        alldisplay.length === 0 || alldisplay[0]?.displayid === redropid[0]
          ? posts.filter((post) => post.Directory !== post.Redropper_id)
          : alldisplay.length > 0 && alldisplay[0]?.displayid !== redropid[0]
          ? posts
          : null;

      console.log(new_posts.length);

      let batchArray = [];
      batchArray.push(writeBatch(db));
      let batchCount = 0;
      let batchIndex = 0;
      new_posts.forEach((post) => {
        const userDocumentRef = doc(
          db,
          "users",
          post.Redropper_id,
          "Drops",
          post.id
        );
        batchArray[batchIndex].update(userDocumentRef, {
          Redropped_Counter: increment(1),
        });
        batchCount++;
        if (batchCount === 499) {
          batchArray.push(writeBatch(db));
          batchIndex++;
          batchCount = 0;
        }
      });

      batchArray.forEach(async (batch) => await batch.commit());
    });
    alldisplay.length === 0 && setRedropswitch(!redropswitch);

    if (alldisplay.length === 1 && redropid[0] === alldisplay[0].displayid) {
      /*realids.forEach(
        (realid) => (
          (realid.Redropped = "done"),
          (realid.Redropped_Counter = realid.Redropped_Counter + 1)
        )
      );*/
    } else if (
      alldisplay.length >= 1 &&
      redropid[0] !== alldisplay[alldisplay.length - 1].displayid
    ) {
      simposts.forEach((simpost) => {
        if (simpost.id === redropid[0]) {
          (simpost.Redropped = "done") &&
            (simpost.Redropped_Counter = simpost.Redropped_Counter + 1);
        }
      });
    } else if (
      alldisplay.length > 1 &&
      redropid[0] === alldisplay[alldisplay.length - 1].displayid
    ) {
      setRedropped(true);
      alldisplay[alldisplay.length - 1].Redropped_Counter =
        alldisplay[alldisplay.length - 1].Redropped_Counter + 1;
    }

    setRedroppoint(false);

    if (redropid[1] !== id) {
      let a =
        alldisplay.length === 0 || redropid[0] === alldisplay[0]?.displayid
          ? "Drops"
          : alldisplay.length === 1 || redropid[0] === alldisplay[1]?.displayid
          ? "Comments"
          : "NestedComments";
      if (a === "Drops") {
        addDoc(collection(db, `users/${redropid[1]}/Notification/`), {
          Type: "Redrop",
          Loadtype: a,
          Timestamp: serverTimestamp(),
          Profile_image: userinfo.Profile_image,
          Name: userinfo.User_Name,
          Rank: userinfo.Rank,
          Directory: redropid[1],
          rootid: redropid[0],
          Redropper_id: id,
        });
      } else if (a === "Comments") {
        addDoc(collection(db, `users/${redropid[1]}/Notification/`), {
          Type: "Redrop",
          Loadtype: a,
          Timestamp: serverTimestamp(),
          Profile_image: userinfo.Profile_image,
          Name: userinfo.User_Name,
          Rank: userinfo.Rank,
          Directory: redropid[1],
          Comment_Directory: redropid[0],
          rootid: alldisplay[0]?.displayid,
          root_user: alldisplay[0]?.userid,
          Redropper_id: id,
        });
      } else if (a === "NestedComments") {
        let b =
          redropid[0] === alldisplay[alldisplay.length - 1].displayid
            ? alldisplay[alldisplay.length - 2].displayid
            : alldisplay[alldisplay.length - 1].displayid;
        let c =
          redropid[0] === alldisplay[alldisplay.length - 1].displayid
            ? alldisplay[alldisplay.length - 2].userid
            : alldisplay[alldisplay.length - 1].userid;

        addDoc(collection(db, `users/${redropid[1]}/Notification/`), {
          Type: "Redrop",
          Loadtype: a,
          Timestamp: serverTimestamp(),
          Profile_image: userinfo.Profile_image,
          Name: userinfo.User_Name,
          Rank: userinfo.Rank,
          Directory: c,
          Comment_Directory: b,
          Nested_Directory: redropid[0],
          Nested_user: redropid[1],
          rootid: alldisplay[0]?.displayid,
          root_user: alldisplay[0]?.userid,
          Redropper_id: id,
        });
      }
      updateDoc(doc(db, `users/${redropid[1]}`), {
        Notification: increment(1),
      });
    }
  };

  const deletedrop = async (e) => {
    console.log(e.target.id);

    const getreplacementdrop = async () => {
      let addingdoc = "";
      const dropsRef1 = collection(db, "users", id, "Drops");
      await getDocs(
        query(
          dropsRef1,
          where("Pure", "==", true),
          orderBy("Timestamp", "desc"),
          limit(3)
        )
      ).then((querySnapshot) => {
        console.log();
        if (querySnapshot.docs.length === 3) {
          let tempaddingdoc = querySnapshot.docs.at(-1);
          console.log(tempaddingdoc);
          addingdoc = {
            ...tempaddingdoc.data(),
            year: tempaddingdoc.data().Timestamp.toDate().getFullYear(),
            month: (
              "0" +
              (tempaddingdoc.data().Timestamp.toDate().getMonth() + 1)
            ).slice(-2),
            date: (
              "0" + tempaddingdoc.data().Timestamp.toDate().getDate()
            ).slice(-2),
            hour: tempaddingdoc.data().Timestamp.toDate().getHours(),
            minutes: (
              "0" + tempaddingdoc.data().Timestamp.toDate().getMinutes()
            ).slice(-2),

            id: tempaddingdoc.id,
          };
        }
      });

      return addingdoc;
    };

    let deletetarget = posts.findIndex((element) => e.target.id === element.id);

    console.log(posts[deletetarget]);

    let rank = posts[deletetarget].Rank;

    if (rank === 1) {
      if (posts[deletetarget + 1]?.Directory === id) {
        let finaladdingdoc = await getreplacementdrop();
        let postinfo = finaladdingdoc
          ? [[finaladdingdoc], posts.length]
          : [[], posts.length - 1];
        console.log(postinfo);
        setPosts([
          posts[deletetarget + 1],
          ...postinfo[0],
          ...posts.slice(deletetarget + 2),
        ]);

        setTimeout(() => {
          droppedvideoref.current = new Array(postinfo[1]).fill(null);
        });
      } else if (posts[deletetarget - 1]?.Directory === id) {
        let finaladdingdoc = await getreplacementdrop();
        let postinfo = finaladdingdoc
          ? [[finaladdingdoc], posts.length]
          : [[], posts.length - 1];
        console.log(postinfo);
        setPosts([
          posts[deletetarget - 1],
          ...postinfo[0],
          ...posts.slice(deletetarget + 2),
        ]);

        setTimeout(() => {
          droppedvideoref.current = new Array(postinfo[1]).fill(null);
        });
      } else {
        setPosts([...posts.slice(deletetarget + 1)]);
        setTimeout(() => {
          droppedvideoref.current = new Array(posts.length - 1).fill(null);
        });
      }
    } else {
      if (posts[deletetarget + 1]?.Directory === id) {
        let finaladdingdoc = await getreplacementdrop();
        console.log(finaladdingdoc);
        let postinfo = finaladdingdoc
          ? [[finaladdingdoc], posts.length]
          : [[], posts.length - 1];
        console.log(postinfo);
        console.log(posts.length);
        console.log(droppedvideoref.current);
        setPosts([
          ...posts.slice(0, deletetarget),
          posts[deletetarget + 1],
          ...postinfo[0],
          ...posts.slice(deletetarget + 2),
        ]);

        setTimeout(() => {
          droppedvideoref.current = new Array(postinfo[1]).fill(null);
        });
        console.log(droppedvideoref.current);
      } else if (posts[deletetarget - 1]?.Directory === id) {
        let finaladdingdoc = await getreplacementdrop();
        let postinfo = finaladdingdoc
          ? [[finaladdingdoc], posts.length]
          : [[], posts.length - 1];
        console.log(postinfo);
        setPosts([
          ...posts.slice(0, deletetarget),
          ...postinfo[0],
          ...posts.slice(deletetarget + 2),
        ]);
        setTimeout(() => {
          droppedvideoref.current = new Array(postinfo[1]).fill(null);
        });
      } else {
        setPosts([
          ...posts.slice(0, deletetarget),
          // ...[],
          ...posts.slice(deletetarget + 1),
        ]);
        setTimeout(() => {
          droppedvideoref.current = new Array(posts.length - 1).fill(null);
        });
      }
    }

    if (alldisplay.length === 1) {
      setAlldisplay([]);
      setSimdisplays([]);
      setShowdrop(true);
    }
    if (clickedcomment) {
      setClickedcomment("");
      setShowdrop(true);
    }

    console.log(posts[deletetarget]);
    if (posts[deletetarget].file1.path) {
      let deletefilelist = [
        ...(posts[deletetarget].file1.path
          ? [posts[deletetarget].file1.path]
          : []),
        ...(posts[deletetarget].file2.path
          ? [posts[deletetarget].file2.path]
          : []),
        ...(posts[deletetarget].file3.path
          ? [posts[deletetarget].file3.path]
          : []),
        ...(posts[deletetarget].file4.path
          ? [posts[deletetarget].file4.path]
          : []),
      ];

      console.log(deletefilelist);

      const storage = getStorage();
      deletefilelist.forEach((file) => {
        let oldRef = ref(storage, file);
        console.log("How many times");
        deleteObject(oldRef);
      });
    }

    const userDocumentRef = doc(db, "users", id, "Drops", optionid[0]);
    await deleteDoc(userDocumentRef);
    const dropsRef = query(
      collectionGroup(db, "Drops"),
      where("Redrop_id", "==", optionid[0])
    );
    getDocs(dropsRef).then((querySnapshot) => {
      let posts = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      let batchArray = [];
      batchArray.push(writeBatch(db));
      let batchCount = 0;
      let batchIndex = 0;
      posts.forEach((item) => {
        const userDocumentRef = doc(
          db,
          "users",
          item.Redropper_id,
          "Drops",
          item.id
        );
        batchArray[batchIndex].delete(userDocumentRef);
        batchCount++;
        if (batchCount === 499) {
          batchArray.push(writeBatch(db));
          batchIndex++;
          batchCount = 0;
        }
      });

      batchArray.forEach(async (batch) => await batch.commit());
    });

    setTimeout(() => {
      setVideorefload(!videorefload);
    }, 500);
  };

  useEffect(() => {
    console.log(droppedvideoref.current);
    let filteredref = droppedvideoref.current.filter(
      (element) => element !== null
    );
    console.log(filteredref);
    if (filteredref.length > 0) {
      let num = -1;
      let options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.95,
      };
      const observer = new IntersectionObserver((entries) => {
        console.log(entries);
        console.log(entries[0]);

        entries.forEach((entry) => {
          console.log(entry);

          if (entry.isIntersecting) {
            console.log(entry.target);

            entry.target.play();
            num++;
          } else {
            if (num > -1) {
              //might need to pause other 2 3 4 video
              console.log(num);
              //what if the video is already paused
              try {
                if (!entry.target.paused) {
                  entry.target.pause();
                } else if (!filteredref[num][1]?.paused) {
                  filteredref[num][1].pause();
                } else if (!filteredref[num][2]?.paused) {
                  filteredref[num][2].pause();
                } else {
                  filteredref[num][3].pause();
                }
              } catch (e) {
                console.log(e);
                //"Its already paused","move on to the rest of function"
              }

              num--;
            }
          }
        });
      }, options);

      console.log(observer);

      filteredref.forEach((element) => {
        let observertarget = Array.isArray(element) ? element[0] : element;

        observer.observe(observertarget);
      });

      return () => {
        observer.disconnect();
      };
    }
  }, [videorefload]);

  const Droppedvideotimeupdate = (e, index) => {
    console.log(e.target.id);
    console.log(index);
    console.log(droppedvideoref.current[index]);
    console.log(droppedvideoref.current[index].length);
    console.log(droppedvideoref.current[index][0].id);

    if (Array.isArray(droppedvideoref.current[index])) {
      //this finds which video is being played right now
      let playtarget = droppedvideoref.current[index].findIndex(
        (element) => element.id === e.target.id
      );
      console.log(playtarget);
      console.log(playtarget !== droppedvideoref.current[index].length - 1);
      if (playtarget !== droppedvideoref.current[index].length - 1) {
        droppedvideoref.current[index][playtarget + 1].play();
      } else {
        droppedvideoref.current[index][0].play();
      }
    } else {
      droppedvideoref.current[index].play();
    }

    //put the video to currentime 0?
  };

  const videohtml = (post, post1, post2) => {
    /*
      controls: tempfiles.length < 2 && true,
      width: fullscreened ? "100%" : tempfiles.length === 1 ? 200 : 175,
      height: fullscreened ? "40%" : tempfiles.length === 1 ? "200" : 175,
      playsInline,
      autoPlay,
      muted,
      loop,
      */

    const videos = {
      objectFit: "cover",
      borderRadius: 20,
      marginLeft:
        !fullscreened && post1?.path !== "" && post2?.path === ""
          ? 20
          : post?.path && !fullscreened && 5,
      marginTop: post?.path !== "" && 20,
      position: fullscreened && "absolute",
      top: fullscreened && "30%",
    };
    return videos;
  };

  const determinevideoindex = (file1, file2, file4, index) => {
    if (file1 === "videos" && file2 === "videos")
      return droppedvideoref.current[index][2];
    else if (file1 === "videos" || file2 === "videos")
      return droppedvideoref.current[index][1];
    else if (file4 === "videos") return droppedvideoref.current[index][0];
    else return droppedvideoref.current[index];
  };

  const determinevideoref = (post, post1, post2, index) => {
    let finalvideos = [];
    let num = 0;

    let videoornot = [];

    post === "videos" && videoornot.push("videos");
    post1 === "videos" && videoornot.push("videos");
    post2 === "videos" && videoornot.push("videos");

    if (videoornot.length === 1) {
      finalvideos[0] = droppedvideoref.current[index];
    } else {
      for (const video of videoornot) {
        if (video === "videos") {
          finalvideos.push(droppedvideoref.current[index][num]);
          num++;
        }
      }
    }
    return finalvideos;
  };

  const determinefourthvideoindex = (file1, file2, file3, index) => {
    let allfiles = [file1, file2, file3];
    let num = 0;
    for (const file of allfiles) {
      if (file === "videos") num++;
    }
    if (num > 0) return droppedvideoref.current[index][num];
    else return droppedvideoref.current[index];
  };

  const OptionTerminal = (e) => {
    setOptionId(e.target.id.split(","));
    setRedroppoint(false);
    setUnredroppoint(false);
    setDeleteid("");
  };

  const Videotimeupdate = (e) => {
    console.log(e.target.id);

    if (controlon || !fullscreened) setShowtime(true);

    let fullscreenedid;

    if (controlon) {
      fullscreenedid = document.fullscreenElement?.children[0].children[0]
        .children[4]?.id
        ? document.fullscreenElement.children[0].children[0].children[4].id
        : "";

      if (e.target.id === fullscreenedid) {
        setVideotime(
          Math.floor(
            document.fullscreenElement.children[0].children[0].children[4]
              .currentTime
          )
        );
      }
    }
  };

  const imagehtml = (post, post1, post2) => {
    const images = {
      width: 0,
      height: 0,
      marginLeft: 0,
      marginTop: 0,
    };
    //if (post1.id === ){}

    images.width = post2?.path
      ? 130
      : post2?.path !== "" && post2?.path !== undefined
      ? 110
      : ((post1?.path !== "" && post1?.path !== undefined) ||
          post?.path !== "") &&
        150;
    images.height =
      post2?.path !== "" && post2?.path !== undefined
        ? 95
        : post1?.path !== "" && post2?.path === ""
        ? 195
        : post?.path !== "" && 150;

    images.marginLeft =
      post1?.path !== "" && post2?.path === "" ? 20 : post?.path !== "" && 5;
    images.marginTop = post?.path !== "" && 20;

    return images;
  };
  return (
    <div className="userpage">
      {!blockon ? (
        <>
          <Link to={`/Connect`}>
            <img
              style={{ zIndex: 2, position: "absolute", top: 5, left: 4 }}
              src="/Arrow1.svg"
            />
          </Link>

          <img className="option1" src="/option.svg" />
          {viewinfo.Head_image ? (
            <img className="testpic1" src={viewinfo.Head_image} />
          ) : (
            <div className="testpic1"></div>
          )}
          <div className="usernameroot">
            <div className="username">
              <div className="username1">{viewinfo.User_Name}</div>
            </div>
          </div>
          {viewinfo.Intro && (
            <div
              className="aboutme"
              style={{ textAlign: "center", marginTop: 10, fontSize: 12 }}
            >
              {viewinfo.Intro}
            </div>
          )}

          <div className="IntroLine2">
            {id !== see && (
              <>
                <img
                  className="interact"
                  src="/messageplus.svg"
                  onClick={message}
                />

                {!unblock && !confirmblock && (
                  <img
                    className="interact"
                    src="/Block.svg"
                    onClick={(e) => setConfirmblock(true)}
                  />
                )}

                {unblock && !confirmunblock && (
                  <button onClick={(e) => setConfirmunblock(true)}>
                    unblock
                  </button>
                )}
                {confirmblock && (
                  <>
                    <button onClick={block}>ブロック</button>
                    <button onClick={(e) => setConfirmblock(false)}>
                      キャンセル
                    </button>
                  </>
                )}
                {confirmunblock && (
                  <>
                    <button onClick={Unblock1}>
                      Confirm Unblock Button at the top right
                    </button>
                    <button onClick={(e) => setConfirmunblock(false)}>
                      cancel
                    </button>
                  </>
                )}
              </>
            )}
          </div>

          <div className="MiddleProfile">
            {viewinfo.Profile_image !== "/Profile3.svg" && (
              <img className="current2" src={viewinfo.Profile_image} />
            )}
            {viewinfo.Profile_image === "/Profile3.svg" && (
              <img className="Profile3" src="/Profile3.svg" />
            )}
            <div className="info">
              <img
                onClick={(e) => navigate(`/Ranks`, { state: [userinfo, see] })}
                className="rankd"
                src="/Rank.svg"
              />
              <hr />

              <div
                onClick={(e) => navigate(`/Ranks`, { state: [userinfo, see] })}
                className={viewinfo.Rank < 10 ? "rankn1" : "rankn"}
              >
                {viewinfo.Rank}
              </div>

              <img
                className="pledge"
                src={pledgegreen ? "/Pledge1.svg" : "/PledgeEmpty.svg"}
                onClick={(e) =>
                  pledgegreen
                    ? navigate(`/Pledge/${see}`, {
                        state: userinfo,
                      })
                    : null
                }
              />

              <hr />
              <span>
                {!following ? (
                  <img
                    className="Profile2"
                    src="/Profile3.svg"
                    onClick={Follow}
                  />
                ) : (
                  <img
                    className="Profile2"
                    src="/Profile4.svg"
                    onClick={AskUnfollow}
                  />
                )}
                {followercount}
              </span>
              {ask && (
                <>
                  <button onClick={Unfollow}>Confirm</button>
                  <button onClick={back}>Deny</button>
                </>
              )}
              <hr />
              <img className="camera" src="/Photo.svg" />
              <hr />
            </div>
          </div>
          <hr className="middle" />
          <img src="/DropView.svg" />
        </>
      ) : (
        <>
          <button>backbutton</button>
          {!unblock && !confirmblock && (
            <button onClick={askblock}>block</button>
          )}
          {unblock && !confirmunblock && (
            <button onClick={(e) => setConfirmunblock(true)}>unblock</button>
          )}
          {confirmblock && (
            <>
              <button onClick={block}>
                Confirm Block Button at the top right
              </button>
              <button onClick={(e) => setConfirmblock(false)}>cancel</button>
            </>
          )}
          {confirmunblock && (
            <>
              <button onClick={Unblock1}>
                Confirm Unblock Button at the top right
              </button>
              <button onClick={(e) => setConfirmunblock(false)}>cancel</button>
            </>
          )}
          "You are blocked"
        </>
      )}
      {posts.map((post, index) => (
        <div
          className="drops"
          key={post.id}
          ref={
            (clickedcomment.id === post.id && scrollref) ||
            alldisplay[0]?.id === post.id
              ? scrollref
              : null
          }
          style={{
            height:
              (post.image2 && 310) ||
              ((post.image1 ||
                (post.file1?.path !== "" && post.file1?.path !== undefined)) &&
                330),
          }}
        >
          <div className="postlinetop">
            <Link to={`/UserView/${post.Directory}`}>
              <img
                className={
                  post.Profile_image === "/Profile3.svg"
                    ? "current3"
                    : "current1"
                }
                src={post.Profile_image}
              />
            </Link>

            {(post.image1 || post.file1?.path) && (
              <div
                style={{
                  fontSize: post.text.length >= 142 && 10.5,
                  //width: post.text.length >= 142 && "80%",
                  //marginTop: post.text.length >= 142 && 8,
                  position: optionid[0] === post.id && "absolute",
                  left:
                    optionid[0] === post.id && post.text.length >= 23
                      ? "20%"
                      : `${49.5 - post.text.length}%`,
                }}
                className={post.text.length >= 23 ? "text" : "text1"}
              >
                {post.text}
              </div>
            )}
            {optionid[0] !== post.id && (
              <img
                className="postoption"
                src="/DropOption.svg"
                id={[post.id, post.Directory]}
                onClick={OptionTerminal}
              />
            )}

            {!deleteid && optionid[0] === post.id && (
              <span className="Delete">
                {id === optionid[1] && (
                  <button id={post.id} onClick={(e) => setDeleteid(post.id)}>
                    削除?
                  </button>
                )}
                <button
                  onClick={(e) => {
                    setOptionId("");
                    setDeleteid("");
                  }}
                >
                  キャンセル
                </button>
              </span>
            )}

            {deleteid === post.id && (
              <span className="Delete">
                <button id={post.id} onClick={deletedrop}>
                  〇
                </button>
                <button onClick={(e) => setDeleteid("")}>X</button>
              </span>
            )}
          </div>

          <span className="textline">
            <span
              className="stylerank"
              //this is where we left off, take this to simdisplay
              style={{
                marginLeft:
                  post.Rank <= 9
                    ? 19.4
                    : post.Rank === 11
                    ? 16
                    : post.Rank <= 99
                    ? 14.5
                    : post.Rank > 99 && 10,
                flex:
                  (post.text ? post.text : post.Comment) >= 25
                    ? 0.18
                    : (post.text ? post.text : post.Comment) === 20
                    ? 0.14
                    : 0.5,
                marginTop:
                  (post.text ? post.text : post.Comment) > 65 &&
                  (post.image1 || post.file1?.path)
                    ? -25
                    : null,
              }}
            >
              {post.Rank}
            </span>
            {!post.image1 && !post.file1?.path && (
              <div
                className={
                  (post.text ? post.text : post.Comment) >= 23
                    ? "text"
                    : "text1"
                }
              >
                {post.text ? post.text : post.Comment}
              </div>
            )}
          </span>

          <div
            className="alldrops"
            //id={[post.id, post.Directory, post.Comment_Counter]}
            onClick={blockid !== post.id ? (e) => display(post) : undefined}
            style={{
              height:
                (post.image2 && 275) ||
                ((post.image1 || post.file1?.path) && 295),
            }}
          ></div>

          <div
            style={{
              marginLeft:
                !post.file3?.path && post.file2?.path
                  ? 40
                  : post.file3?.path && 60,
              marginTop: post.file3?.path && -10,
              justifyContent:
                post.file3?.path && !post.file4?.path && "flex-start",
            }}
            className="postphotoline"
          >
            {post.file1?.filetype === "videos" ? (
              <>
                <video
                  controls={!post.file2.path && true}
                  width={
                    fullscreened
                      ? "100%"
                      : post.file2.path === ""
                      ? 200
                      : post.file4.path !== ""
                      ? 130
                      : 150
                  }
                  height={
                    fullscreened
                      ? "40%"
                      : post.file2.path === ""
                      ? 200
                      : post.file4?.path !== ""
                      ? 95
                      : post.file3.path !== ""
                      ? 195
                      : 150
                  }
                  ref={(element) => (droppedvideoref.current[index] = element)}
                  onTimeUpdate={Videotimeupdate}
                  onEnded={
                    post.file2.path
                      ? (e) => Droppedvideotimeupdate(e, index)
                      : undefined
                  }
                  playsInline
                  muted
                  loop={!post.file2.path && true}
                  // loop={post.file2.filetype !== "videos" &&  post.file3.filetype !== "videos" && post.file4.filetype !== "videos" && true}

                  id={post.file1.id}
                  style={videohtml(post?.file2, post.file3, post.file4)}
                >
                  <source type="video/mp4" src={post.file1?.path} />
                </video>
                {console.log(droppedvideoref.current)}
                {!isNaN(
                  Array.isArray(droppedvideoref.current[index])
                    ? droppedvideoref.current[index][0]?.duration
                    : droppedvideoref.current[index]?.duration
                ) && (
                  <span
                    //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                    style={{
                      bottom: !post.file2.filetype
                        ? "18%"
                        : !post.file3.filetype
                        ? "27%"
                        : post.file4.filetype
                        ? "46%"
                        : "16%",
                      left:
                        !post.file2.filetype || post.file4.filetype
                          ? "25%"
                          : !post.file3.filetype
                          ? "17%"
                          : "23%",
                      zIndex: 1,
                      position: "absolute",
                    }}
                    className="videoduration"
                  >
                    {0 +
                      Math.floor(
                        (Array.isArray(droppedvideoref.current[index])
                          ? droppedvideoref.current[index][0]
                          : droppedvideoref.current[index]
                        )?.currentTime / 60
                      )}
                    :
                    {(Array.isArray(droppedvideoref.current[index])
                      ? droppedvideoref.current[index][0]
                      : droppedvideoref.current[index]
                    )?.currentTime < 10 && 0}
                    {(Array.isArray(droppedvideoref.current[index])
                      ? droppedvideoref.current[index][0]
                      : droppedvideoref.current[index]
                    )?.currentTime >= 60
                      ? Math.floor(
                          (Array.isArray(droppedvideoref.current[index])
                            ? droppedvideoref.current[index][0]
                            : droppedvideoref.current[index]
                          )?.currentTime % 60
                        )
                      : Math.floor(
                          (Array.isArray(droppedvideoref.current[index])
                            ? droppedvideoref.current[index][0]
                            : droppedvideoref.current[index]
                          )?.currentTime
                        )}
                    <span style={{ marginLeft: 5, marginRight: 5 }}>/</span>
                    {0 +
                      Math.floor(
                        (Array.isArray(droppedvideoref.current[index])
                          ? droppedvideoref.current[index][0]
                          : droppedvideoref.current[index]
                        )?.duration / 60
                      )}
                    :
                    {(Array.isArray(droppedvideoref.current[index])
                      ? droppedvideoref.current[index][0]
                      : droppedvideoref.current[index]
                    )?.duration < 10 && 0}
                    {(Array.isArray(droppedvideoref.current[index])
                      ? droppedvideoref.current[index][0]
                      : droppedvideoref.current[index]
                    )?.duration >= 60
                      ? Math.floor(
                          (Array.isArray(droppedvideoref.current[index])
                            ? droppedvideoref.current[index][0]
                            : droppedvideoref.current[index]
                          )?.duration % 60
                        )
                      : Math.floor(
                          (Array.isArray(droppedvideoref.current[index])
                            ? droppedvideoref.current[index][0]
                            : droppedvideoref.current[index]
                          )?.duration
                        )}
                  </span>
                )}
              </>
            ) : (
              post.file1?.filetype === "images" && (
                <img
                  className="postphoto"
                  src={post.file1?.path}
                  id={post.file1?.id}
                  style={imagehtml(post.file2, post.file3, post.file4)}
                />
              )
            )}

            {!post.file4?.path ? (
              <div
                style={{
                  display: post.file3?.path && !post.file4?.path && "flex",
                  flexDirection:
                    post.file3?.path && !post.file4?.path && "column",
                }}
              >
                {post.file2?.filetype === "videos" ? (
                  <>
                    {console.log(droppedvideoref.current)}
                    <video
                      width={
                        fullscreened ? "100%" : post.file3?.path ? 110 : 150
                      }
                      height={
                        fullscreened ? "40%" : post.file3?.path ? 95 : 150
                      }
                      ref={(element) =>
                        (droppedvideoref.current[index] =
                          post.file1.filetype === "videos"
                            ? [droppedvideoref.current[index], element]
                            : element)
                      }
                      playsInline
                      muted
                      id={post.file2.id}
                      style={videohtml(post.file1)}
                      onTimeUpdate={Videotimeupdate}
                      onEnded={(e) => Droppedvideotimeupdate(e, index)}
                    >
                      <source type="video/mp4" src={post.file2?.path} />
                    </video>

                    {droppedvideoref?.current[index] !== null &&
                      !isNaN(
                        Array.isArray(droppedvideoref.current[index])
                          ? droppedvideoref.current[index][0]?.duration
                          : droppedvideoref.current[index]?.duration
                      ) && (
                        <span
                          //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                          style={{
                            bottom: !post.file3.filetype ? "27%" : "46%",
                            left: !post.file3.filetype ? "59%" : "65%",
                            zIndex: 1,
                            position: "absolute",
                          }}
                          className="videoduration"
                        >
                          {0 +
                            Math.floor(
                              (post.file1.filetype === "videos"
                                ? droppedvideoref?.current[index][1]
                                : Array.isArray(droppedvideoref.current[index])
                                ? droppedvideoref?.current[index][0]
                                : droppedvideoref?.current[index]
                              )?.currentTime / 60
                            )}
                          :
                          {(post.file1.filetype === "videos"
                            ? droppedvideoref?.current[index][1]
                            : Array.isArray(droppedvideoref.current[index])
                            ? droppedvideoref?.current[index][0]
                            : droppedvideoref?.current[index]
                          )?.currentTime < 10 && 0}
                          {(post.file1.filetype === "videos"
                            ? droppedvideoref?.current[index][1]
                            : Array.isArray(droppedvideoref.current[index])
                            ? droppedvideoref?.current[index][0]
                            : droppedvideoref?.current[index]
                          )?.currentTime >= 60
                            ? Math.floor(
                                (post.file1.filetype === "videos"
                                  ? droppedvideoref?.current[index][1]
                                  : Array.isArray(
                                      droppedvideoref.current[index]
                                    )
                                  ? droppedvideoref?.current[index][0]
                                  : droppedvideoref?.current[index]
                                )?.currentTime % 60
                              )
                            : Math.floor(
                                (post.file1.filetype === "videos"
                                  ? droppedvideoref?.current[index][1]
                                  : Array.isArray(
                                      droppedvideoref.current[index]
                                    )
                                  ? droppedvideoref?.current[index][0]
                                  : droppedvideoref?.current[index]
                                )?.currentTime
                              )}
                          <span style={{ marginLeft: 5, marginRight: 5 }}>
                            /
                          </span>
                          {0 +
                            Math.floor(
                              (post.file1.filetype === "videos"
                                ? droppedvideoref?.current[index][1]
                                : Array.isArray(droppedvideoref.current[index])
                                ? droppedvideoref?.current[index][0]
                                : droppedvideoref?.current[index]
                              )?.duration / 60
                            )}
                          :
                          {(post.file1.filetype === "videos"
                            ? droppedvideoref?.current[index][1]
                            : Array.isArray(droppedvideoref.current[index])
                            ? droppedvideoref?.current[index][0]
                            : droppedvideoref?.current[index]
                          )?.duration < 10 && 0}
                          {(post.file1.filetype === "videos"
                            ? droppedvideoref?.current[index][1]
                            : Array.isArray(droppedvideoref.current[index])
                            ? droppedvideoref?.current[index][0]
                            : droppedvideoref?.current[index]
                          )?.duration >= 60
                            ? Math.floor(
                                (post.file1.filetype === "videos"
                                  ? droppedvideoref?.current[index][1]
                                  : Array.isArray(
                                      droppedvideoref.current[index]
                                    )
                                  ? droppedvideoref?.current[index][0]
                                  : droppedvideoref?.current[index]
                                )?.duration % 60
                              )
                            : Math.floor(
                                (post.file1.filetype === "videos"
                                  ? droppedvideoref?.current[index][1]
                                  : Array.isArray(
                                      droppedvideoref.current[index]
                                    )
                                  ? droppedvideoref?.current[index][0]
                                  : droppedvideoref?.current[index]
                                )?.duration
                              )}
                        </span>
                      )}
                  </>
                ) : (
                  post.file2?.filetype === "images" && (
                    <img
                      className="postphoto"
                      src={post.file2?.path}
                      id={post.file2?.id}
                      style={
                        post.file3?.path
                          ? {
                              ...imagehtml(post.file1),
                              width: 110,
                              height: post.file3.path && 95,
                            }
                          : imagehtml(post.file1)
                      }
                    />
                  )
                )}

                {post.file3?.filetype === "videos" ? (
                  <>
                    <video
                      width={fullscreened ? "100%" : 110}
                      height={fullscreened ? "40%" : 95}
                      ref={(element) =>
                        (droppedvideoref.current[index] =
                          post.file1.filetype === "videos" &&
                          post.file2.filetype === "videos"
                            ? [
                                droppedvideoref.current[index][0],
                                droppedvideoref.current[index][1],
                                element,
                              ]
                            : post.file1.filetype !== "videos" &&
                              post.file2.filetype !== "videos"
                            ? element
                            : post.file1.filetype !== "videos" ||
                              post.file2.filetype !== "videos"
                            ? [droppedvideoref?.current[index], element]
                            : [])
                      }
                      playsInline
                      muted
                      id={post.file3.id}
                      style={{
                        ...videohtml(post.file1),
                        marginTop: 4,
                      }}
                      onTimeUpdate={Videotimeupdate}
                      onEnded={(e) => Droppedvideotimeupdate(e, index)}
                    >
                      <source type="video/mp4" src={post.file3?.path} />
                    </video>

                    {droppedvideoref?.current[index] !== null &&
                      !isNaN(
                        Array.isArray(droppedvideoref.current[index])
                          ? droppedvideoref.current[index][0]?.duration
                          : droppedvideoref.current[index]?.duration
                      ) && (
                        <span
                          //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                          style={{
                            bottom: "16%",
                            left: "65%",
                            zIndex: 1,
                            position: "absolute",
                          }}
                          className="videoduration"
                        >
                          {0 +
                            Math.floor(
                              determinevideoindex(
                                post.file1.filetype,
                                post.file2.filetype,
                                post.file4.filetype,
                                index
                              )?.currentTime / 60
                            )}
                          :
                          {determinevideoindex(
                            post.file1.filetype,
                            post.file2.filetype,
                            post.file4.filetype,
                            index
                          )?.currentTime < 10 && 0}
                          {determinevideoindex(
                            post.file1.filetype,
                            post.file2.filetype,
                            post.file4.filetype,
                            index
                          )?.currentTime >= 60
                            ? Math.floor(
                                determinevideoindex(
                                  post.file1.filetype,
                                  post.file2.filetype,
                                  post.file4.filetype,
                                  index
                                )?.currentTime % 60
                              )
                            : Math.floor(
                                determinevideoindex(
                                  post.file1.filetype,
                                  post.file2.filetype,
                                  post.file4.filetype,
                                  index
                                )?.currentTime
                              )}
                          <span style={{ marginLeft: 5, marginRight: 5 }}>
                            /
                          </span>
                          {0 +
                            Math.floor(
                              determinevideoindex(
                                post.file1.filetype,
                                post.file2.filetype,
                                post.file4.filetype,
                                index
                              )?.duration / 60
                            )}
                          :
                          {determinevideoindex(
                            post.file1.filetype,
                            post.file2.filetype,
                            post.file4.filetype,
                            index
                          )?.duration < 10 && 0}
                          {determinevideoindex(
                            post.file1.filetype,
                            post.file2.filetype,
                            post.file4.filetype,
                            index
                          )?.duration >= 60
                            ? Math.floor(
                                determinevideoindex(
                                  post.file1.filetype,
                                  post.file2.filetype,
                                  post.file4.filetype,
                                  index
                                )?.duration % 60
                              )
                            : Math.floor(
                                determinevideoindex(
                                  post.file1.filetype,
                                  post.file2.filetype,
                                  post.file4.filetype,
                                  index
                                )?.duration
                              )}
                        </span>
                      )}
                  </>
                ) : (
                  post.file3?.filetype === "images" && (
                    <img
                      className="postphoto"
                      src={post.file3?.path}
                      id={post.file3?.id}
                      style={{
                        ...imagehtml(post.file1),
                        width: !post.file4?.path ? 110 : 130,
                        height: 95,
                        marginTop: 4,
                      }}
                    />
                  )
                )}
              </div>
            ) : (
              <>
                {post.file2?.filetype === "videos" ? (
                  <>
                    <video
                      width={
                        fullscreened
                          ? "100%"
                          : post.file4.path !== ""
                          ? 130
                          : post.file3.path
                          ? 110
                          : 150
                      }
                      height={fullscreened ? "40%" : post.file3.path ? 95 : 150}
                      ref={(element) =>
                        (droppedvideoref.current[index] =
                          post.file1.filetype === "videos"
                            ? [droppedvideoref.current[index], element]
                            : element)
                      }
                      playsInline
                      muted
                      id={post.file2.id}
                      style={videohtml(post.file1)}
                      onTimeUpdate={Videotimeupdate}
                      onEnded={(e) => Droppedvideotimeupdate(e, index)}
                    >
                      <source type="video/mp4" src={post.file2?.path} />
                    </video>

                    {droppedvideoref?.current[index] !== null &&
                      !isNaN(
                        Array.isArray(droppedvideoref.current[index])
                          ? droppedvideoref.current[index][0]?.duration
                          : droppedvideoref.current[index]?.duration
                      ) && (
                        <span
                          //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                          style={{
                            bottom: "46%",
                            left: "62%",

                            zIndex: 1,
                            position: "absolute",
                          }}
                          className="videoduration"
                        >
                          {0 +
                            Math.floor(
                              (post.file1.filetype === "videos"
                                ? droppedvideoref?.current[index][1]
                                : Array.isArray(droppedvideoref.current[index])
                                ? droppedvideoref?.current[index][0]
                                : droppedvideoref?.current[index]
                              )?.currentTime / 60
                            )}
                          :
                          {(post.file1.filetype === "videos"
                            ? droppedvideoref?.current[index][1]
                            : Array.isArray(droppedvideoref.current[index])
                            ? droppedvideoref?.current[index][0]
                            : droppedvideoref?.current[index]
                          )?.currentTime < 10 && 0}
                          {(post.file1.filetype === "videos"
                            ? droppedvideoref?.current[index][1]
                            : Array.isArray(droppedvideoref.current[index])
                            ? droppedvideoref?.current[index][0]
                            : droppedvideoref?.current[index]
                          )?.currentTime >= 60
                            ? Math.floor(
                                (post.file1.filetype === "videos"
                                  ? droppedvideoref?.current[index][1]
                                  : Array.isArray(
                                      droppedvideoref.current[index]
                                    )
                                  ? droppedvideoref?.current[index][0]
                                  : droppedvideoref?.current[index]
                                )?.currentTime % 60
                              )
                            : Math.floor(
                                (post.file1.filetype === "videos"
                                  ? droppedvideoref?.current[index][1]
                                  : Array.isArray(
                                      droppedvideoref.current[index]
                                    )
                                  ? droppedvideoref?.current[index][0]
                                  : droppedvideoref?.current[index]
                                )?.currentTime
                              )}
                          <span style={{ marginLeft: 5, marginRight: 5 }}>
                            /
                          </span>
                          {0 +
                            Math.floor(
                              (post.file1.filetype === "videos"
                                ? droppedvideoref?.current[index][1]
                                : Array.isArray(droppedvideoref.current[index])
                                ? droppedvideoref?.current[index][0]
                                : droppedvideoref?.current[index]
                              )?.duration / 60
                            )}
                          :
                          {(post.file1.filetype === "videos"
                            ? droppedvideoref?.current[index][1]
                            : Array.isArray(droppedvideoref.current[index])
                            ? droppedvideoref?.current[index][0]
                            : droppedvideoref?.current[index]
                          )?.duration < 10 && 0}
                          {(post.file1.filetype === "videos"
                            ? droppedvideoref?.current[index][1]
                            : Array.isArray(droppedvideoref.current[index])
                            ? droppedvideoref?.current[index][0]
                            : droppedvideoref?.current[index]
                          )?.duration >= 60
                            ? Math.floor(
                                (post.file1.filetype === "videos"
                                  ? droppedvideoref?.current[index][1]
                                  : Array.isArray(
                                      droppedvideoref.current[index]
                                    )
                                  ? droppedvideoref?.current[index][0]
                                  : droppedvideoref?.current[index]
                                )?.duration % 60
                              )
                            : Math.floor(
                                (post.file1.filetype === "videos"
                                  ? droppedvideoref?.current[index][1]
                                  : Array.isArray(
                                      droppedvideoref.current[index]
                                    )
                                  ? droppedvideoref?.current[index][0]
                                  : droppedvideoref?.current[index]
                                )?.duration
                              )}
                        </span>
                      )}
                  </>
                ) : (
                  post.file2?.filetype === "images" && (
                    <img
                      className="postphoto"
                      src={post.file2?.path}
                      id={post.file2?.id}
                      style={
                        post.file3?.path
                          ? {
                              ...imagehtml(post.file1),
                              width:
                                (post.file4?.path && 130) ||
                                (post.file3.path && 110),
                              height: post.file3.path && 95,
                            }
                          : imagehtml(post.file1)
                      }
                    />
                  )
                )}
                {post.file3?.filetype === "videos" ? (
                  <>
                    <video
                      width={
                        fullscreened
                          ? "100%"
                          : post.file4.path !== ""
                          ? 130
                          : 110
                      }
                      height={fullscreened ? "40%" : 95}
                      ref={(element) =>
                        (droppedvideoref.current[index] =
                          post.file1.filetype === "videos" &&
                          post.file2.filetype === "videos"
                            ? [
                                droppedvideoref.current[index][0],
                                droppedvideoref.current[index][1],
                                element,
                              ]
                            : post.file1.filetype !== "videos" &&
                              post.file2.filetype !== "videos"
                            ? element
                            : post.file1.filetype !== "videos" ||
                              post.file2.filetype !== "videos"
                            ? [droppedvideoref?.current[index], element]
                            : [])
                      }
                      playsInline
                      muted
                      id={post.file3.id}
                      style={{
                        ...videohtml(post.file1),

                        marginTop: 4,
                      }}
                      onTimeUpdate={Videotimeupdate}
                      onEnded={(e) => Droppedvideotimeupdate(e, index)}
                    >
                      <source type="video/mp4" src={post.file3?.path} />
                    </video>

                    {droppedvideoref?.current[index] !== null &&
                      !isNaN(
                        Array.isArray(droppedvideoref.current[index])
                          ? droppedvideoref.current[index][0]?.duration
                          : droppedvideoref.current[index]?.duration
                      ) && (
                        <span
                          //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                          style={{
                            bottom: "16%",
                            left: "26%",
                            zIndex: 1,
                            position: "absolute",
                          }}
                          className="videoduration"
                        >
                          {0 +
                            Math.floor(
                              determinevideoindex(
                                post.file1.filetype,
                                post.file2.filetype,
                                post.file4.filetype,
                                index
                              )?.currentTime / 60
                            )}
                          :
                          {determinevideoindex(
                            post.file1.filetype,
                            post.file2.filetype,
                            post.file4.filetype,
                            index
                          )?.currentTime < 10 && 0}
                          {determinevideoindex(
                            post.file1.filetype,
                            post.file2.filetype,
                            post.file4.filetype,
                            index
                          )?.currentTime >= 60
                            ? Math.floor(
                                determinevideoindex(
                                  post.file1.filetype,
                                  post.file2.filetype,
                                  post.file4.filetype,
                                  index
                                )?.currentTime % 60
                              )
                            : Math.floor(
                                determinevideoindex(
                                  post.file1.filetype,
                                  post.file2.filetype,
                                  post.file4.filetype,
                                  index
                                )?.currentTime
                              )}
                          <span style={{ marginLeft: 5, marginRight: 5 }}>
                            /
                          </span>
                          {0 +
                            Math.floor(
                              determinevideoindex(
                                post.file1.filetype,
                                post.file2.filetype,
                                post.file4.filetype,
                                index
                              )?.duration / 60
                            )}
                          :
                          {determinevideoindex(
                            post.file1.filetype,
                            post.file2.filetype,
                            post.file4.filetype,
                            index
                          )?.duration < 10 && 0}
                          {determinevideoindex(
                            post.file1.filetype,
                            post.file2.filetype,
                            post.file4.filetype,
                            index
                          )?.duration >= 60
                            ? Math.floor(
                                determinevideoindex(
                                  post.file1.filetype,
                                  post.file2.filetype,
                                  post.file4.filetype,
                                  index
                                )?.duration % 60
                              )
                            : Math.floor(
                                determinevideoindex(
                                  post.file1.filetype,
                                  post.file2.filetype,
                                  post.file4.filetype,
                                  index
                                )?.duration
                              )}
                        </span>
                      )}
                  </>
                ) : (
                  post.file3?.filetype === "images" && (
                    <img
                      className="postphoto"
                      src={post.file3?.path}
                      id={post.file3?.id}
                      style={{
                        ...imagehtml(post.file1),
                        width: !post.file4?.path ? 110 : 130,
                        height: 95,
                        marginTop: 4,
                      }}
                    />
                  )
                )}
              </>
            )}

            {post.file4?.filetype === "videos" ? (
              <>
                <video
                  width={
                    fullscreened ? "100%" : post.file4.path !== "" ? 130 : 110
                  }
                  height={fullscreened ? "40%" : 95}
                  ref={(element) =>
                    (droppedvideoref.current[index] =
                      post.file1.filetype === "videos" &&
                      post.file2.filetype === "videos" &&
                      post.file3.filetype === "videos"
                        ? [
                            droppedvideoref.current[index][0],
                            droppedvideoref.current[index][1],
                            droppedvideoref.current[index][2],
                            element,
                          ]
                        : post.file1.filetype !== "videos" &&
                          post.file2.filetype !== "videos" &&
                          post.file3.filetype !== "videos"
                        ? element
                        : post.file1.filetype !== "videos" ||
                          post.file2.filetype !== "videos" ||
                          post.file3.filetype !== "videos"
                        ? [
                            ...determinevideoref(
                              post.file1.filetype,
                              post.file2.filetype,
                              post.file3.filetype,
                              index
                            ),
                            element,
                          ]
                        : [])
                  }
                  playsInline
                  muted
                  id={post.file4.id}
                  style={{
                    ...videohtml(post.file1),

                    marginTop: 4,
                  }}
                  onTimeUpdate={Videotimeupdate}
                  onEnded={(e) => Droppedvideotimeupdate(e, index)}
                >
                  <source type="video/mp4" src={post.file4?.path} />
                </video>

                {droppedvideoref?.current[index] !== null &&
                  !isNaN(
                    Array.isArray(droppedvideoref.current[index])
                      ? droppedvideoref.current[index][0]?.duration
                      : droppedvideoref.current[index]?.duration
                  ) && (
                    <span
                      //this position absolute should be relative but for now make it absolute now until we find out a way to make it relative without breaking style
                      style={{
                        bottom: "16%",
                        left: "61.5%",
                        zIndex: 1,
                        position: "absolute",
                      }}
                      className="videoduration"
                    >
                      {0 +
                        Math.floor(
                          determinefourthvideoindex(
                            post.file1.filetype,
                            post.file2.filetype,
                            post.file3.filetype,
                            index
                          )?.currentTime / 60
                        )}
                      :
                      {determinefourthvideoindex(
                        post.file1.filetype,
                        post.file2.filetype,
                        post.file3.filetype,
                        index
                      )?.currentTime < 10 && 0}
                      {determinefourthvideoindex(
                        post.file1.filetype,
                        post.file2.filetype,
                        post.file3.filetype,
                        index
                      )?.currentTime >= 60
                        ? Math.floor(
                            determinefourthvideoindex(
                              post.file1.filetype,
                              post.file2.filetype,
                              post.file3.filetype,
                              index
                            )?.currentTime % 60
                          )
                        : Math.floor(
                            determinefourthvideoindex(
                              post.file1.filetype,
                              post.file2.filetype,
                              post.file3.filetype,
                              index
                            )?.currentTime
                          )}
                      <span style={{ marginLeft: 5, marginRight: 5 }}>/</span>
                      {0 +
                        Math.floor(
                          determinefourthvideoindex(
                            post.file1.filetype,
                            post.file2.filetype,
                            post.file3.filetype,
                            index
                          )?.duration / 60
                        )}
                      :
                      {determinefourthvideoindex(
                        post.file1.filetype,
                        post.file2.filetype,
                        post.file3.filetype,
                        index
                      )?.duration < 10 && 0}
                      {determinefourthvideoindex(
                        post.file1.filetype,
                        post.file2.filetype,
                        post.file3.filetype,
                        index
                      )?.duration >= 60
                        ? Math.floor(
                            determinefourthvideoindex(
                              post.file1.filetype,
                              post.file2.filetype,
                              post.file3.filetype,
                              index
                            )?.duration % 60
                          )
                        : Math.floor(
                            determinefourthvideoindex(
                              post.file1.filetype,
                              post.file2.filetype,
                              post.file3.filetype,
                              index
                            )?.duration
                          )}
                    </span>
                  )}
              </>
            ) : (
              post.file4?.filetype === "images" && (
                <img
                  src={post.file4?.path}
                  className="postphoto"
                  id={post.file4?.id}
                  style={{
                    ...imagehtml(post.file1),
                    width: 130,
                    height: 95,
                    marginTop: 4,
                  }}
                />
              )
            )}

            {post.image1 && (
              <img
                className="postphoto"
                src={post.image1}
                style={{
                  width: post.image2 && 150,
                  height: post.image2 && 150,
                  marginLeft: post.image2 && 50,
                  marginTop: post.image2 && 20,
                }}
              />
            )}
          </div>
          <div className="date">
            {post.year}/{post.month}/{post.date}
            <span className="Timesplit">|</span>
            <span className="Time">
              {post.hour}:{post.minutes}
            </span>
          </div>
          <img
            className="Rankmarked"
            src="/RankMark.svg"
            style={{ top: (post.image1 || post.file1?.path) && 51 }}
          />

          <span className="dropoption">
            <span className="commentgroup">
              <img
                className="commenttap"
                src="/Comment1.svg"
                id={[post.id, post.Directory]}
                onClick={comment}
              />
              <span className="commentcount">
                {post.Comment_Counter == 0 ? null : post.Comment_Counter}
              </span>
            </span>
            <span className="redropline">
              {post.Redropped == "done" ? (
                <img
                  src="/Redrop.svg"
                  className="Redrop"
                  onClick={AskUnredrop}
                  id={[post.id, post.Directory, post.Redropped_Conter]}
                />
              ) : (
                <img
                  className="Redrop"
                  src="/PlaneRedrop4.svg"
                  id={[
                    post.id,
                    post.Directory,
                    post.Rank,
                    post.Profile_image,
                    post.text,
                    post.Comment_Counter,
                    post.hour,
                    post.minutes,
                    post.year,
                    post.month,
                    post.date,
                    post.image1,
                    post.image2,
                    post.image3,
                    post.image4,
                    post.video,
                    post.Redropper_id,
                    post.Redropped_Counter,
                  ]}
                  onClick={redrop}
                />
              )}
              <span className="commentcount">
                {post.Redropped_Counter == 0 ? null : post.Redropped_Counter}
              </span>
            </span>
          </span>

          {redroppoint && post.id === redropid[0] && (
            <span className="redropstyle">
              <button onClick={confirmredrop}>confirm redrop</button>
              <button
                onClick={(e) => {
                  setRedroppoint(false);
                }}
              >
                Cancel redrop
              </button>
            </span>
          )}
          {unredroppoint && post.id === unredropdid[0] && (
            <span className="redropstyle">
              <button onClick={ConfirmUnredrop}>Unredrop</button>
              <button onClick={CancelUnredrop}>Cancel</button>
            </span>
          )}
          {blockid === post.id && (
            <div className="Blockposition">
              <Block
                style={{ width: "80%" }}
                // style={{ zIndex: 8 }}
                understandblocked={understandblocked}
              />
            </div>
          )}
        </div>
      ))}

      {unredrop && (
        <>
          <button onClick={ConfirmUnredrop}>Unredrop</button>
          <button onClick={(e) => setUnredrop(false)}>Cancel</button>
        </>
      )}
    </div>
  );
}

export default UserView;
