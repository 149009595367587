import React, { useState, useEffect, useRef } from "react";
import "./Message.css";
import { useNavigate, useLocation } from "react-router-dom";
import {
  collection,
  serverTimestamp,
  addDoc,
  doc,
  setDoc,
  getDoc,
  getDocs,
  orderBy,
  query,
  onSnapshot,
  updateDoc,
  increment,
  Timestamp,
} from "firebase/firestore";
import { db, auth, storage } from "./Firebase.js";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  getStorage,
  uploadString,
} from "firebase/storage";
import { useParams } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import LowControl from "./LowControl";

function Message() {
  const { room } = useParams();
  const ref1 = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();

  const [message, setMessage] = useState("");
  const [bmessages, setBmessages] = useState([]);
  const [id, setId] = useState("");
  const [video, setVideo] = useState("");
  const [image, setImage] = useState("");
  const [second, setSecond] = useState(false);
  const [blockon, setBlockon] = useState(false);
  const [loadmessage, setLoadmessage] = useState(false);
  const [userinfo, setUserinfo] = useState("");
  const [viewinfo, setViewinfo] = useState("");
  const [scroll, setScroll] = useState("");
  const [focus, setFocus] = useState(false);
  const [tempphoto, setTempphoto] = useState("");
  const [tempvideo, setTempvideo] = useState("");
  const [realtempphoto, setRealtempphoto] = useState("");
  const [realtempvideo, setRealtempvideo] = useState("");

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      console.log(location.state);
      if (user) {
        const userBlockRef = doc(db, "users", room, "Blocked", user.uid);
        getDoc(userBlockRef).then((documentSnapshot) => {
          documentSnapshot.data().Blocked_id && setBlockon(true);
        });

        setId(user.uid);
        const userDocumentRef = doc(db, "users", user.uid);
        getDoc(userDocumentRef).then((documentSnapshot) => {
          setUserinfo(documentSnapshot.data());

          const seconduserDocumentRef = doc(db, "users", room);
          getDoc(seconduserDocumentRef).then((documentSnapshot) => {
            setViewinfo(documentSnapshot.data());
          });
        });
      }

      const userCollectionRef = collection(
        db,
        "users",
        user.uid,
        "Message",
        room,
        "Message_details"
      );

      getDocs(query(userCollectionRef, orderBy("Timestamp"))).then(
        (querySnapshot) => {
          setBmessages(
            querySnapshot.docs.map((doc) => ({
              ...doc.data(),
              id: doc.id,
            }))
          );
        }
      );
    });

    setTimeout(() => {
      setScroll(true);
    }, 200);
  }, []);

  useEffect(() => {
    if (scroll === true) {
      const element = ref1.current;
      console.log(element);
      element.scrollIntoView();
      setScroll(false);
    }
  }, [scroll]);

  useEffect(() => {
    if (loadmessage === true) {
      onSnapshot(
        collection(db, `users/${id}/Message/${room}/Message_details`),
        (snapshot) => {
          console.log("YO");
          const userCollectionRef = collection(
            db,
            "users",
            id,
            "Message",
            room,
            "Message_details"
          );

          getDocs(query(userCollectionRef, orderBy("Timestamp"))).then(
            (querySnapshot) => {
              setBmessages(
                querySnapshot.docs.map((doc) => ({
                  ...doc.data(),
                  id: doc.id,
                }))
              );
            }
          );
          setTimeout(() => {
            setScroll(true);
          }, 100);
        }
      );
    }
  }, [loadmessage]);

  const Confsend = async () => {
    const userBlockRef = doc(db, "users", room, "Blocked", id);
    getDoc(userBlockRef).then((documentSnapshot) => {
      if (documentSnapshot.exists()) {
        documentSnapshot.data().Blocked_id && setBlockon(true);
      } else {
        let finalfile = "";
        let attached =
          new Date().getTime() -
            bmessages[bmessages.length - 1]?.Timestamp.seconds * 1000 <
          60000
            ? true
            : false;
        new Promise((resolve, reject) => {
          if (tempphoto || tempvideo) {
            const storage = getStorage();
            let storagefile = tempphoto
              ? `images/${realtempphoto.name + new Date().getTime()}`
              : `videos/${realtempvideo.name + new Date().getTime()}`;
            const storageRef = ref(
              storage,

              storagefile
            );

            let uploadfile = tempphoto ? realtempphoto : realtempvideo;

            uploadBytes(storageRef, uploadfile).then((uploadtask) => {
              getDownloadURL(storageRef).then((url) => {
                console.log(url);
                finalfile = url;
                resolve(finalfile);
              });
            });
          } else resolve();
        }).then(() => {
          console.log(finalfile);

          try {
            let messageinfo = {
              text: message,
              Timestamp: serverTimestamp(),
              image1: tempphoto ? finalfile : "",
              video: tempvideo ? finalfile : "",
              from: id,
              to: room,
              attached,
            };

            addDoc(
              collection(db, `users/${id}/Message/${room}/Message_details`),
              messageinfo
            ).then((docRef) => {
              let lit = docRef.id;
              console.log(lit);
            });

            addDoc(
              collection(db, `users/${room}/Message/${id}/Message_details`),
              messageinfo
            );
            setDoc(doc(db, `users/${room}/Message/${id}`), {
              ...messageinfo,
              MessageName: userinfo.User_Name,
              MessageRank: userinfo.Rank,
              MessagePic: userinfo.Profile_image,
              Opened: false,
            });
            setDoc(doc(db, `users/${id}/Message/${room}`), {
              ...messageinfo,
              MessageRank: viewinfo.Rank,
              MessagePic: viewinfo.Profile_image,
              RecieveName: viewinfo.User_Name,
            });
          } catch (error) {}
          updateDoc(doc(db, `users/${room}/`), {
            MessageNotification: increment(1),
          });
          setTimeout(() => {
            setMessage("");
            setTempphoto("");
            setTempvideo("");
            setRealtempphoto("");
            setRealtempvideo("");
            setLoadmessage(true);
          });
        });
      }
    });
  };

  const backurl = () => {
    if (location.state === "UserView") {
      navigate(`/UserView/${room}`);
    } else {
      navigate(`/${location.state[0]}`, { state: location.state[1] });
    }
  };

  const focusscroll = () => {
    setFocus(true);
    setScroll(true);
  };

  const fileedit = (e) => {
    console.log(tempphoto);
    console.log(e.target.value);
    console.log(e.target.files[0]);
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);

    reader.onload = () => {
      console.log(reader.result);
      if (e.target.files[0].type.includes("image")) {
        setTempphoto(reader.result);
        setRealtempphoto(e.target.files[0]);
        setTempvideo("");
      } else {
        setTempvideo(reader.result);
        setRealtempvideo(e.target.files[0]);
        setTempphoto("");
      }
      e.target.value = "";
      setScroll(true);
    };
  };

  const filedelete = (e) => {
    setTempphoto("");
    setTempvideo("");
  };

  return (
    <div>
      <div
        style={{
          width: "100%",
          height: 56,
          display: "block",
          background: "#005280",
          zIndex: 1,
          position: "fixed",
          top: 0,
        }}
      ></div>
      <img
        style={{ zIndex: 2, position: "fixed", top: 15, left: 4 }}
        src="/Arrow1.svg"
        onClick={backurl}
      />
      <div className="messageview">
        {bmessages.map((bmessage, index, array) => (
          <div key={bmessage.id}>
            <div
              style={{
                display: bmessage.from !== id && "flex",
                alignItems: bmessage.from !== id && "flex-end",
              }}
            >
              {bmessage.from !== id && !array[index + 1]?.attached && (
                <img
                  onClick={(e) => navigate(`/UserView/${bmessage.from}`)}
                  src={viewinfo.Profile_image}
                  style={{ width: 30, borderRadius: 20, marginLeft: 4 }}
                />
              )}

              <div
                style={{
                  display:
                    bmessage.from !== id &&
                    bmessage.text &&
                    (bmessage.video || bmessage.image1) &&
                    "flex",
                  flexDirection: "column",
                  width: "100%",
                  alignItems: "flex-start",
                }}
              >
                {bmessage.image1 && (
                  <div
                    style={{
                      background: "none",
                      marginTop:
                        bmessage.from !== id && bmessage?.attached
                          ? -6
                          : bmessage?.attached
                          ? 0
                          : 40,
                      marginBottom: bmessage.from !== id && -8,
                      marginLeft:
                        bmessage.from !== id && array[index + 1]?.attached
                          ? 30
                          : bmessage.from !== id
                          ? -6
                          : null,
                    }}
                    className={
                      bmessage.from === id ? "messagestyle" : "messagestyle1"
                    }
                  >
                    <img
                      style={{
                        width: 175,
                        borderRadius: 20,

                        border: `1px solid ${
                          bmessage.from === id ? "Chartreuse" : "BlueViolet"
                        }`,
                      }}
                      src={bmessage.image1}
                    />
                  </div>
                )}

                {bmessage.video && (
                  <div
                    style={{
                      background: "none",
                      marginTop:
                        bmessage.from !== id && bmessage?.attached
                          ? -6
                          : bmessage?.attached
                          ? 0
                          : 40,
                      marginBottom: bmessage.from !== id && -8,
                      marginLeft:
                        bmessage.from !== id && array[index + 1]?.attached
                          ? 30
                          : bmessage.from !== id
                          ? -6
                          : null,
                    }}
                    className={
                      bmessage.from === id ? "messagestyle" : "messagestyle1"
                    }
                  >
                    <video
                      controls
                      width="200"
                      height="140"
                      playsInline
                      autoPlay={
                        bmessages[bmessages.length - 1]?.video ===
                          bmessage.video && true
                      }
                      muted
                      loop
                      style={{
                        objectFit: "cover",
                        borderRadius: 15,
                        border: `1px solid ${
                          bmessage.from === id ? "Chartreuse" : "BlueViolet"
                        }`,
                      }}
                    >
                      <source type="video/mp4" src={bmessage.video} />
                    </video>
                  </div>
                )}

                {bmessage.text && (
                  <div
                    style={{
                      marginTop:
                        bmessage.from !== id &&
                        (bmessage.image1 || bmessage.video)
                          ? 0
                          : bmessage.image1 || bmessage.video
                          ? 3
                          : bmessage?.attached &&
                            bmessage.from !== id &&
                            (array[index - 1]?.image1 ||
                              array[index - 1]?.video)
                          ? -18
                          : bmessage?.attached
                          ? 0
                          : 40,

                      marginLeft:
                        bmessage.from !== id && !array[index + 1]?.attached
                          ? 6
                          : bmessage.from !== id && array[index + 1]?.attached
                          ? 39
                          : null,
                    }}
                    className={
                      bmessage.from === id ? "messagestyle" : "messagestyle1"
                    }
                  >
                    <div className={bmessage.from === id ? "innermessage" : ""}>
                      {bmessage.text}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              className={bmessage.from === id && "messagestyle"}
              style={{
                fontSize: 10,
                background: "none",
                marginLeft: bmessage.from !== id && 44,
                marginRight: bmessage.from === id && 18,
                marginTop: 2,
                color: bmessage.from !== id ? "BlueViolet" : "chartreuse",
              }}
            >
              {!array[index + 1]?.attached &&
                bmessage.Timestamp?.toDate().getHours() +
                  ":" +
                  ("0" + bmessage.Timestamp?.toDate().getMinutes()).slice(-2)}
            </div>
          </div>
        ))}

        {!blockon ? (
          <>
            <div style={{ display: "flex" }}>
              <div style={{ width: "100%" }}>
                {(tempphoto || tempvideo) && (
                  <>
                    <div
                      style={{
                        border: "1px solid purple",
                        borderRadius: 10,
                        background: "linear-gradient(#a9baf1, #d9eaf1)",
                        padding: 5,
                        marginTop: 30,
                      }}
                    >
                      {tempphoto && (
                        <img
                          style={{
                            width: 100,
                            borderRadius: 15,
                            height: 110,
                            objectFit: "cover",
                            left: 5,
                          }}
                          src={tempphoto}
                        />
                      )}
                      {tempvideo && (
                        <video
                          controls
                          width="200"
                          height="110"
                          style={{
                            objectFit: "cover",
                            borderRadius: 15,
                          }}
                          playsInline
                          autoPlay
                          muted
                          loop
                        >
                          <source type="video/mp4" src={tempvideo} />
                        </video>
                      )}

                      <img
                        style={{
                          position: "absolute",
                          left: tempphoto ? 85 : 180,
                          width: 15,
                          marginTop: 3,
                        }}
                        src="/Xout.svg"
                        onClick={filedelete}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <label>
                <img
                  src="/Photo.svg"
                  style={{
                    width: 30,
                    position: "absolute",
                    marginTop:
                      (tempphoto || tempvideo) && !focus
                        ? 4
                        : (tempphoto || tempvideo) && focus
                        ? 32
                        : focus
                        ? 71
                        : 44,
                    marginLeft: 5,
                  }}
                />

                <input onChange={fileedit} className="upload" type="file" />
              </label>

              <textarea
                className="Message"
                value={message}
                style={{
                  height: (message || focus) && 55,
                  borderTop: (tempphoto || tempvideo) && "none",
                  marginTop: (tempphoto || tempvideo) && 0,
                }}
                onFocus={focusscroll}
                onBlur={(e) => !message && setFocus(false)}
                onChange={(e) => setMessage(e.target.value)}
                ref={ref1}
                placeholder="メッセージを送りましょう..."
              ></textarea>

              {(message || tempphoto || tempvideo) && (
                <img
                  style={{
                    marginTop:
                      (tempphoto || tempvideo) && !focus
                        ? 4
                        : (tempphoto || tempvideo) && focus
                        ? 30
                        : focus
                        ? 71
                        : 44,
                    marginLeft: 5,
                  }}
                  className="messagedrop"
                  src="/messagedrop.svg"
                  onClick={Confsend}
                />
              )}
            </div>
          </>
        ) : (
          <div ref={ref1}> あなたはブロックされています</div>
        )}
        <LowControl />
      </div>
    </div>
  );
}

export default Message;
